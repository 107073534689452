const vehicleService = (api) => ({
  getVehicle: async (vehicleVin) => {
    return api.request({
      method: 'get',
      url: `/api/v1/vehicles/${vehicleVin}`
    });
  },
  searchVehicles: async ({
    model,
    variant,
    color,
    city,
    pickupDate,
    returnDate,
    pickupTime,
    returnTime
  }) => {
    return api.request({
      method: 'get',
      url: '/api/v1/vehicles',
      params: {
        model,
        variant,
        color,
        city,
        pickupDate,
        returnDate,
        pickupTime,
        returnTime
      }
    });
  }
});

export default vehicleService;
