<template>
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.99967 7.33333C9.47243 7.33333 10.6663 6.13943 10.6663 4.66667C10.6663 3.19391 9.47243 2 7.99967 2C6.52692 2 5.33301 3.19391 5.33301 4.66667C5.33301 6.13943 6.52692 7.33333 7.99967 7.33333ZM3.33301 12.6667C3.33301 11.1939 4.52691 10 5.99967 10H9.99968C11.4724 10 12.6663 11.1939 12.6663 12.6667C12.6663 13.403 12.0694 14 11.333 14H4.66634C3.92996 14 3.33301 13.403 3.33301 12.6667Z"
            fill="#D6D2CD" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.08301 4.66667C6.08301 3.60812 6.94113 2.75 7.99967 2.75C9.05822 2.75 9.91634 3.60812 9.91634 4.66667C9.91634 5.72521 9.05822 6.58333 7.99967 6.58333C6.94113 6.58333 6.08301 5.72521 6.08301 4.66667ZM7.99967 1.25C6.1127 1.25 4.58301 2.77969 4.58301 4.66667C4.58301 6.55364 6.1127 8.08333 7.99967 8.08333C9.88665 8.08333 11.4163 6.55364 11.4163 4.66667C11.4163 2.77969 9.88665 1.25 7.99967 1.25ZM4.08301 12.6667C4.08301 11.6081 4.94113 10.75 5.99967 10.75H9.99968C11.0582 10.75 11.9163 11.6081 11.9163 12.6667C11.9163 12.9888 11.6552 13.25 11.333 13.25H4.66634C4.34418 13.25 4.08301 12.9888 4.08301 12.6667ZM5.99967 9.25C4.1127 9.25 2.58301 10.7797 2.58301 12.6667C2.58301 13.8173 3.51575 14.75 4.66634 14.75H11.333C12.4836 14.75 13.4163 13.8173 13.4163 12.6667C13.4163 10.7797 11.8866 9.25 9.99968 9.25H5.99967Z"
            fill="#44403C" />
    </svg>
</template>

<script>
export default {
    name: "UserIcon",
};
</script>