<template>
  <div class="border border-primary-700 rounded-lg overflow-hidden flex flex-col">
    <CtaButton variant="secondary"
      class="w-full bg-white border-0 border-b border-primary-700 rounded-none !justify-start"
      @click="handleRentalAgreement">
      <ArrowUpRight slot="leading" class="size-6" />
      Rental Agreement
    </CtaButton>
    <CtaButton variant="secondary" class="w-full bg-white border-0 rounded-none !justify-start"
      :is-disabled="!reservationHasDamageWaiver" @click="handleCollisionProtection">
      <ArrowUpRight slot="leading" class="size-6" :fill="!reservationHasDamageWaiver ? '#9F9992' : '#44403C'" />
      Collision Protection
    </CtaButton>
  </div>
</template>

<script>
import api from '@/api';
import ArrowUpRight from '../core/icons/ArrowUpRight.vue';
import CtaButton from '../core/CtaButton.vue';

export default {
  name: 'AgreementsButtons',
  components: {
    ArrowUpRight,
    CtaButton
  },
  props: {
    reservation: {
      type: Object,
      Required: true,
    },
  },
  computed: {
    reservationHasDamageWaiver() {
      return this.reservation?.transactions.some(transaction => transaction.addon?.type === 'DAMAGE_WAIVER');
    },
  },
  methods: {
    async viewAgreement(agreementId, title = 'Rental Agreement') {
      const agreementSignedURLResponse = await api.agreements.getAgreementSignedURL(agreementId);
      const agreementSignedURL = agreementSignedURLResponse.signed_url;
      const newWindow = window.open();
      newWindow.document.write(`
    <html>
      <head>
        <title>${title} | Resla</title>
      </head>
      <body style="margin:0; padding:0;">
        <iframe src="${agreementSignedURL}" style="width:100%; height:100vh; border:none;">test</iframe>
      </body>
    </html>
  `);
    },
    handleRentalAgreement() {
      const rentalAgreementId = this.reservation.agreements.find(agreement => agreement.type === 'RENTAL_AGREEMENT').id;
      this.viewAgreement(rentalAgreementId, 'Rental Agreement');
    },
    handleCollisionProtection() {
      const collisionProtectionId = this.reservation.agreements.find(agreement => agreement.type === 'DAMAGE_WAIVER').id;
      this.viewAgreement(collisionProtectionId, 'Collision Protection');
    },
  },
};
</script>
