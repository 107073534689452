<template>
  <div></div>
</template>

<script>
const { createVeriffFrame, MESSAGES } = require('@veriff/incontext-sdk');
import api from '@/api';

export default {
  name: 'VeriffModal',
  data() {
    return {
      veriffInstance: null,
    };
  },
  async created() {
    const response = await api.verifcations.createLicenseVerification(this.$route.params.platform_id);
    const veriff_session_url = response.verification.url
    console.error('veriff_session_url', veriff_session_url)
    createVeriffFrame({
      url: veriff_session_url,
      onEvent: (msg) => {
        switch(msg) {
          case MESSAGES.CANCELED:
            this.$emit('close');
            break;
          case MESSAGES.STARTED:
            this.$emit('started');
            break;
          case MESSAGES.FINISHED:
            this.$emit('finished');
            break;
        }
      },
    })
  },
  beforeDestroy() {
    this.unmountVeriff();
  },
};
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
