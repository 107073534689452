<template>
    <div class="relative">
        <div v-if="$slots.button" @click="toggleDropdown">
            <slot name="button"></slot>
        </div>
        <CtaButton v-else variant="tertiary" @click="toggleDropdown">
            Models
        </CtaButton>
        <div v-show="isOpen" :class="mergedDropDownClass">
            <div
                class="grid grid-cols-2 lg:grid-cols-4 bg-primary-1000 gap-x-4 gap-y-5 lg:border border-resla-ebony-70 p-6 md:rounded-xl">
                <template v-if="limitedModels.length > 0">
                    <ModelLink v-for="model in limitedModels" :key="model.name" :imgSrc="model.public_image_link"
                        :name="model.name" @click="handleModelLink(model)" />
                </template>
                <template v-else>
                    <SkeletonLoader v-for="item in 4" :key="item" width="100%" height="203px" rounded></SkeletonLoader>
                </template>
            </div>
            <div  class="flex flex-col lg:hidden p-6 bg-primary-1000">
                <template v-if="authenticated && $route.name !== 'login'">
                    <div @click="handleTrips"
                    class="mt-6 flex py-3 px-4 text-base leading-6.5 font-urbanist font-normal text-primary-300 hover:bg-primary-900 cursor-pointer">
                    My Trips
                </div>
                <CtaButton  variant="secondary" trailing-icon class="w-full mt-6" @click="handleLogout">Log Out
                </CtaButton>
                </template>                
                <CtaButton v-if="!authenticated && $route.name !== 'login'" to="/login" variant="secondary" trailing-icon class="w-full mt-6">Sign In</CtaButton>
            </div>
           
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { twMerge } from "tailwind-merge";
import ModelLink from "@/components/core/ModelLink.vue";
import CtaButton from "@/components/core/CtaButton.vue"
import SkeletonLoader from '@/components/core/animations/SkeletonLoader.vue'

export default {
    name: "NavModels",
    components: {
        ModelLink,
        CtaButton,
        SkeletonLoader
    },
    props: {
        models: {
            type: Array,
            required: true,
        },
        dropdownClass: {
            type: String,
        },
    },
    data() {
        return {
            isOpen: false,
            blurTimeout: false,
        };
    },
    computed: {
        ...mapState("auth", ["authenticated"]),
        mergedDropDownClass() {
            return twMerge(
                "absolute z-50 left-1/2 transform -translate-x-1/2 top-[54px] md:top-[82px]",
                this.dropdownClass
            );
        },
        limitedModels() {
            if (this.models) {
                return this.models
                    .filter(vehicle => vehicle.name === 'Model 3' || vehicle.name === 'Model Y' || vehicle.name === 'Model S' || vehicle.name === 'Model X')
            }
            return null
        },
    },
    watch: {
        isOpen(newVal) {
            if (newVal) {
                document.addEventListener("click", this.onClickOutside);
            } else {
                document.removeEventListener("click", this.onClickOutside);
            }
        },
    },
    methods: {
        ...mapActions("auth", ["logout"]),
        ...mapActions("search", ["toggleFilter"]),
        toggleDropdown() {
            this.isOpen = !this.isOpen;
            setTimeout(() => {
                this.$store.commit(
                    this.isOpen ? "activateOverlay" : "deactivateOverlay"
                );
            }, 100);
        },
        closeDropdown() {
            this.isOpen = false;
            if (this.$store.state.isOverlayActive) {
                this.$store.commit("deactivateOverlay");
            }
        },
        handleModelLink(model) {
            this.closeDropdown()
            if (this.$route.name !== 'search') {
                this.$router.push(`/search?model=${model.name}`);
            } else {
                this.toggleFilter({ filter: 'model', value: model.name });
            }
        },
        onClickOutside(event) {
            if (this.$el && !this.$el.contains(event.target)) {
                event.stopPropagation();
                this.closeDropdown();
            }
        },
        handleLogout() {
            this.logout({ router: this.$router });
            this.closeDropdown()
        },
        handleTrips() {
            this.$router.push('/trips');
            this.closeDropdown()
        }
    },
    beforeDestroy() {
        document.removeEventListener("click", this.onClickOutside);
        clearTimeout(this.blurTimeout);
    },
};
</script>