<template>
  <div class="flex items-center p-3 bg-white border border-primary-800 rounded-lg">
    <div class="bg-gray-100 p-2 rounded-lg flex items-center justify-center">
      <ShieldCheckIcon class="w-6 h-6 text-gray-800" />
    </div>
    <div class="ml-3">
      <p class="text-gray-800 font-medium">Free Cancellation</p>
      <p class="text-gray-600 text-sm">
        Full refund if cancelled before {{ formatDateToLocale(cancellationDate) }} at {{ formatTimeToLocale(roundedCancellationDate, 'America/New_York') }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatDateToLocale, formatTimeToLocale } from '@/helpers/util';
import ShieldCheckIcon from '../core/icons/ShieldCheckIcon.vue';

export default {
  name: 'FreeCancellationCard',
  components: {
    ShieldCheckIcon,
  },
  props: {
    cancellationDate: {
      type: Date,
      required: true,
    },
  },
  computed: {
    roundedCancellationDate() {
      const cancellation = new Date(this.cancellationDate);
      cancellation.setMinutes(0, 0, 0);
      return cancellation;
    }
  },
  methods: {
    formatDateToLocale,
    formatTimeToLocale,
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
