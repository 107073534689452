<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5001 4.49999L6.56688 14.4331C5.78827 15.2117 5.39896 15.601 5.13106 16.0742C4.86316 16.5474 4.72963 17.0815 4.46257 18.1497L4 20L5.85029 19.5374C6.91852 19.2704 7.45264 19.1368 7.9258 18.8689C8.39895 18.601 8.78825 18.2117 9.56685 17.4331L9.56686 17.4331L19.5001 7.49994C19.5797 7.42031 19.6195 7.3805 19.6515 7.34525C20.3449 6.58244 20.345 5.41755 19.6516 4.65472C19.6195 4.61948 19.5797 4.57967 19.5001 4.50006L19.5001 4.50003C19.4205 4.4204 19.3807 4.38059 19.3454 4.34854C18.5826 3.6551 17.4177 3.65509 16.6548 4.34851C16.6196 4.38054 16.5798 4.42034 16.5002 4.49992L16.5001 4.49999Z"
      :fill="fill" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M17.1593 4.9034C17.6361 4.47002 18.3642 4.47003 18.8409 4.90343C18.8596 4.92042 18.884 4.94453 18.9698 5.03027C19.0555 5.116 19.0796 5.14041 19.0966 5.1591C19.53 5.63587 19.53 6.36393 19.0966 6.84069C19.0796 6.85939 19.0555 6.88379 18.9697 6.96953L18.1627 7.77659C17.0865 7.80085 16.1991 6.9135 16.2233 5.83736L17.0305 5.03024C17.1162 4.9445 17.1406 4.9204 17.1593 4.9034ZM14.9789 4.96052C14.9724 4.96675 14.966 4.97311 14.9598 4.97958L6.03657 13.9027L5.96302 13.9763C5.25466 14.6843 4.79718 15.1416 4.47843 15.7046C4.15967 16.2676 4.00291 16.8951 3.76019 17.8669L3.73498 17.9677L3.27241 19.818C3.20851 20.0736 3.2834 20.344 3.46969 20.5302C3.65597 20.7165 3.92634 20.7914 4.18192 20.7275L6.03221 20.265L6.1331 20.2397C7.1048 19.997 7.73236 19.8403 8.29534 19.5215C8.85831 19.2028 9.31561 18.7453 10.0237 18.0369L10.0972 17.9634L19.0241 9.03646C19.028 9.03263 19.0319 9.02876 19.0357 9.02485L20.0304 8.03019L20.043 8.01757C20.1103 7.95027 20.1622 7.89844 20.2065 7.84965C21.16 6.80079 21.16 5.19905 20.2066 4.15017C20.1622 4.10139 20.1104 4.04957 20.0431 3.98229L20.043 3.98222L20.0305 3.96963L20.0179 3.95707L20.0179 3.95703C19.9506 3.88971 19.8987 3.83785 19.8499 3.79349C18.801 2.84001 17.1993 2.83999 16.1504 3.79344C16.1016 3.83781 16.0497 3.88966 15.9824 3.95699L15.9698 3.96957L14.9789 4.96052ZM16.8917 9.04753L9.03654 16.9027C8.22978 17.7095 7.92038 18.0101 7.55629 18.2162C7.1922 18.4224 6.77527 18.533 5.6684 18.8097L5.03079 18.9691L5.19019 18.3316C5.46691 17.2247 5.57758 16.8077 5.78372 16.4436C5.98987 16.0796 6.29045 15.7701 7.09722 14.9634L14.9524 7.10826C15.2981 7.99358 16.0064 8.70189 16.8917 9.04753Z"
      :fill="stroke" />
  </svg>

</template>

<script>
export default {
  name: "EditIcon",
  props:{
    fill:{
      type:String,
      default:'#E5E3E0'
    },
    stroke:{
      type:String,
      default:'#44403C'
    }
  }
};
</script>