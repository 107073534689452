<template>
  <div
    :class="[
      'rounded-xl bg-resla-ebony-90 border-resla-ebony-70 border',
      orientation === 'horizontal' ? 'flex flex-col md:flex-row' : '',
    ]"
  >
    <img
      :src="getMoneyshotImage(reservation.vehicle.images)"
      alt="Car Image"
      :class="[
        orientation === 'horizontal' ? 'w-full md:w-2/5 h-auto rounded-l-lg' : 'w-full h-auto rounded-t-lg',
      ]"
    />
    <div
      :class="[
        'p-12',
        orientation === 'horizontal' ? 'w-full' : '',
      ]"
    >
      <div class="border-b border-resla-ebony-70 flex flex-wrap justify-between pb-6">
        <div>
          <h3 class="text-3xl font-medium">{{ reservation.vehicle.model.name}}</h3>
          <p class="font-urbanist text-xl">{{ pickupDate }} - {{ returnDate }}</p>
        </div>
        <div class="flex items-center gap-3 mt-2 ">
          <Badge>${{ reservation.payment_balance / 100 }} total</Badge>
          <div class="items-end text-primary-100">
            <span class="text-4xl font-medium">
              ${{ reservation.vehicle.rack_rate / 100 }}
            </span>
            /day
          </div>
        </div>
      </div>
      <p class="font-urbanist text-primary-100 text-xl font-normal mt-6"><strong>Pickup & Return Near: </strong>{{ reservation.pickup_location.name }}</p>
      <CtaButton class="mt-6" trailing-icon @click="viewTripDetails">Trip Details</CtaButton>
    </div>
  </div>
</template>

<script>
import CtaButton from '../core/CtaButton.vue';
import Badge from '../core/Badge.vue';

export default {
  name: 'UpcomingReservationCard',
  components: {
    CtaButton,
    Badge
  },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    orientation: {
      type: String,
      default: 'vertical',
      validator(value) {
        return ['vertical', 'horizontal'].includes(value);
      },
    },
  },
  computed: {
    pickupDate() {
      const timezone = this.reservation.timezone;
      return new Date(this.reservation.pickup_at).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: timezone,
      });
    },
    returnDate() {
      const timezone = this.reservation.timezone;
      return new Date(this.reservation.return_at).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: timezone,
      });
    },
  },
  methods: {
    viewTripDetails() {
      // Implement the logic to view trip details
      this.$emit('view-details', this.reservation);
    },
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')?.image_url;
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
