<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <router-link
      v-for="model in models"
      :key="model.name"
      class="flex items-center gap-5 border border-primary-700 hover:bg-primary-900 rounded-xl py-3 pl-3 pr-5 cursor-pointer"
      :to="`/search?model=${model.name}`"
    >
      <img :src="model.public_image_link" alt="Car Model" class="w-1/4 md:w-1/3 h-auto rounded-md" />
      <div class="flex-1">
        <h3 class="text-xl tracking-negative-2 text-primary-200 font-medium">{{ model.name }}</h3>
      </div>
      <RightArrowIcon class="size-6 text-black" />
    </router-link>
  </div>
</template>

<script>
import RightArrowIcon from '../core/icons/RightArrowIcon.vue';

export default {
  name: "ModelGrid",
  components: {
    RightArrowIcon,
  },
  props: {
    models: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Add any additional scoped styles if necessary */
</style>
