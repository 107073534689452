import api from '@/api';
import { formatTimeToHHMM, formatDateToYYYYMMDD } from '@/helpers/util';

const state = {
  filters: {
    model: null,
    variant: null,
    color: null,
    city: null,
    pickupDate: null,
    returnDate: null,
    pickupTime: null,
    returnTime: null,
  },
  vehicles: [],
  cities: [],
  loading: false,
};

const mutations = {
  setFilter(state, { filter, value }) {
    state.filters[filter] = value;
  },
  setVehicles(state, vehicles) {
    state.vehicles = vehicles;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setCities(state, cities) {
    state.cities = cities;
  }
};

const actions = {
  toggleFilter({ commit }, { filter, value }) {
    if (value === this.state.search.filters[filter]) {
      commit('setFilter', { filter, value: null });
    }
    else {
      commit('setFilter', { filter, value });
    }
  },
  setCity({ commit }, value) {
    commit('setFilter', { filter: 'city', value });
  },
  setPickupDate({ commit, state }, value) {
    console.log('setPickupDate', value);
    const oldPickupDate = state.filters.pickupDate ? new Date(state.filters.pickupDate) : null;
    const returnDate = state.filters.returnDate ? new Date(state.filters.returnDate) : null;
    const newPickupDate = new Date(value);

    if (oldPickupDate && returnDate) {
      const dayDifference = Math.floor((returnDate - oldPickupDate) / (1000 * 60 * 60 * 24));

      if (newPickupDate >= returnDate) {
        const newReturnDate = new Date(newPickupDate);
        newReturnDate.setDate(newPickupDate.getDate() + dayDifference);
        commit('setFilter', { filter: 'returnDate', value: newReturnDate });
      }
    }

    commit('setFilter', { filter: 'pickupDate', value: newPickupDate });
  },
  setReturnDate({ commit }, value) {
    console.log('setReturnDate', value);
    commit('setFilter', { filter: 'returnDate', value: new Date(value) });
  },
  setPickupTime({ commit }, value) {
    console.log('setPickupTime', value);
    commit('setFilter', { filter: 'pickupTime', value });
  },
  setReturnTime({ commit }, value) {
    console.log('setReturnTime', value);
    commit('setFilter', { filter: 'returnTime', value });
  },
  setDefaultPickupDate({ commit }) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    commit('setFilter', { filter: 'pickupDate', value: tomorrow });
  },
  setDefaultReturnDate({ state, commit }) {
    let referenceDate;

    // Use the pickupDate as the reference if it exists, otherwise use today's date
    if (state.filters.pickupDate) {
      referenceDate = new Date(state.filters.pickupDate);
    } else {
      referenceDate = new Date(); // Today's date
    }

    // Set the return date to be one week after the reference date
    const nextWeek = new Date(referenceDate);
    nextWeek.setDate(referenceDate.getDate() + 7);
    nextWeek.setHours(0, 0, 0, 0);

    commit('setFilter', { filter: 'returnDate', value: nextWeek });
  },
  setDefaultPickupTime({ commit }) {
    const now = new Date();

    // Round up to the next full hour
    now.setMinutes(0, 0, 0);
    now.setHours(now.getHours() + 2);
    const pickupTime = formatTimeToHHMM(now);
    commit('setFilter', { filter: 'pickupTime', value: pickupTime });
  },

  setDefaultReturnTime({ state, commit }) {
    if (state.filters.pickupTime) {
      // If a pickupTime exists, set the returnTime to the same as pickupTime
      commit('setFilter', { filter: 'returnTime', value: state.filters.pickupTime });
    } else {
      // Default return time is 10:00 AM if no pickupTime exists
      const returnTime = "10:00";

      // Commit the default return time as a string
      commit('setFilter', { filter: 'returnTime', value: returnTime });
    }
  },
  setDefaultDateFilters({ dispatch }) {
    dispatch('setDefaultPickupDate');
    dispatch('setDefaultReturnDate');
    dispatch('setDefaultPickupTime');
    dispatch('setDefaultReturnTime');
  },
  async setDefaultCityFilter({ state, commit, dispatch }, { latitude = null, longitude = null } = {}) {
    // Ensure cities are loaded
    if (!state.cities || state.cities.length === 0) {
      await dispatch('getCities');
    }

    if (latitude && longitude && state.cities.length > 0) {
      // Find the closest city by calculating the distance
      let closestCity = null;
      let smallestDistance = Infinity;

      console.error(state.cities);

      state.cities.forEach((city) => {
        const distance = Math.sqrt(
          Math.pow(city.latitude - latitude, 2) + Math.pow(city.longitude - longitude, 2)
        );

        if (distance < smallestDistance) {
          smallestDistance = distance;
          closestCity = city;
        }
      });

      if (closestCity) {
        commit('setFilter', { filter: 'city', value: closestCity });
      } else {
        console.warn('No closest city found.');
      }
    } else {
      commit('setFilter', { filter: 'city', value: state.cities[0] });
    }
  },
  async searchVehicles({ state, commit, getters }) {
    commit('setLoading', true);
    commit('setVehicles', []);

    const vehicles = await api.vehicle.searchVehicles({
      model: state.filters.model,
      variant: state.filters.variant,
      color: state.filters.color,
      city: `${state.filters.city.name}, ${state.filters.city.state}`,
      pickupDate: getters.pickupDateString,
      returnDate: getters.returnDateString,
      pickupTime: state.filters.pickupTime,
      returnTime: state.filters.returnTime,
    });
    commit('setVehicles', vehicles.items);
    commit('setLoading', false);
  },
  async getCities({ commit }) {
    try {
      const cities = await api.location.getClosestCities();
      commit("setCities", cities);
    } catch (error) {
      console.error('Error getting cities:', error);
    }
  }
};

const getters = {
  getFilters: (state) => state.filters,
  pickupDateString: (state) => {
    if (state.filters.pickupDate) {
      return formatDateToYYYYMMDD(state.filters.pickupDate);
    }
    return null;
  },
  returnDateString: (state) => {
    if (state.filters.returnDate) {
      return formatDateToYYYYMMDD(state.filters.returnDate);
    }
    return null;
  },
  numRentedDays: (state) => {
    if (state.filters.pickupDate && state.filters.returnDate) {
      const pickupDate = new Date(state.filters.pickupDate);
      const returnDate = new Date(state.filters.returnDate);
      const timeDiff = returnDate - pickupDate;
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      return daysDiff;
    }
    return 0;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
