<template>
  <div class="relative w-full flex justify-center">
    <date-picker ref="reslaRangePicker" :input-attr="{ id: $props.id }" type="datetime" format="HH:mm a"
      :popup-class="mergedPickerClass" class="resla-range-picker-inline" range :clearable="false"
      :disabled-date="disabledDate" v-model="selectedDateRange" :show-time-panel="timeToggle" inline @pick="handlePick">
      <template #header>
        <div class="hidden md:flex justify-between w-full gap-x-5 ">
          <p class="w-full text-center font-urbanist text-xs font-normal text-primary-100">Pickup</p>
          <p class="w-full text-center font-urbanist text-xs font-normal text-primary-100">Return</p>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-between w-full gap-x-5 ">
          <div class="relative w-full">
            <TimeCircle class="text-primary-700 !size-5 absolute  end-3 top-2" />
            <input type="text" :id="`time-${id}`"
              class="hidden md:flex font-urbanist bg-primary-900 border border-primary-700 focus:ring-primary-700 leading-none text-primary-100 text-base rounded-lg w-full pt-2 px-4"
              readonly :value="displayTime(tempStartDateTime)" @click="showTimePanel" />
            <input type="text" :id="`time-${id}`"
              class="flex md:hidden font-urbanist bg-primary-900 border border-primary-700 focus:ring-primary-700 leading-none text-primary-100 text-base rounded-lg w-full pt-2 px-4"
              readonly :value="`${displayTime(tempStartDateTime)}   -   ${displayTime(tempEndDateTime)}`"
              @click="showTimePanel" />
          </div>
          <div class="relative w-full hidden md:block">
            <TimeCircle class="text-primary-700 !size-5 absolute end-3 top-2" />
            <input type="text" :id="`time-${id}`"
              class="font-urbanist bg-primary-900 border border-primary-700 focus:ring-primary-700 leading-none text-primary-100 text-base rounded-lg w-full pt-2 px-4"
              readonly :value="displayTime(tempEndDateTime)" @click="showTimePanel" />
          </div>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import TimeCircle from "@/components/core/icons/TimeCircleIcon.vue";

export default {
  name: "RangePickerNew",
  components: {
    DatePicker,
    TimeCircle,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    pickerClass: {
      type: String,
    },
    dateValue: {
      type: Object,
      default: () => ({
        startDate: new Date(),
        endDate: null,
      }),
    },
    timeValue: {
      type: Object,
      default: () => ({
        startTime: '00:00',
        endDate: null,
      }),
    },
    toggleTime: {
      type: Boolean,
      default: false,
    },
    // disabled dates array in date format: "YYYY-MM-DD
    disabledDates: {
      type: Array,
      default: (() => [])
    },
    minDate: {
      type: Date,
      default: () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
      }
    },
    maxDate: {
      type: Date,
      default: null
    },
    modalVisible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      timeToggle: false,
      tempStartDateTime: null,
      tempEndDateTime: null,
      isOpen: false,
      selectedPickupDate: null,
    };
  },
  computed: {
    getDateTimeValue() {
      const { startDate, endDate } = this.dateValue;
      const { startTime, endTime } = this.timeValue;

      const startDateTime = this.combineDateTime(
        startDate || this.getDefaultStartDate(),
        startTime || '00:00'
      );
      const endDateTime = this.combineDateTime(
        endDate || this.getDefaultEndDate(),
        endTime || '00:00'
      );

      return [startDateTime, endDateTime];
    },
    selectedDateRange: {
      get() {
        return this.getDateTimeValue;
      },
      // setter to update dateValue and timeValue when datepicker changes
      set(value) {
        // don't emit the changes yet keep local.
        const [start, end] = value;
        this.tempStartDateTime = start;
        this.tempEndDateTime = end;
        this.$emit('update:date', {
          startDate: this.tempStartDateTime,
          endDate: this.tempEndDateTime,
        });
        this.$emit('update:time', {
          startTime: this.getTimeFromDate(this.tempStartDateTime),
          endTime: this.getTimeFromDate(this.tempEndDateTime),
        });
      },
    },
    mergedPickerClass() {
      return [
        "resla-range-picker",
        this.pickerClass || ""
      ].join(" ").trim();
    }
  },
  methods: {
    showTimePanel() {
      this.timeToggle = !this.timeToggle
    },
    getDefaultStartDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      return tomorrow
    },
    getDefaultEndDate() {
      const nextWeek = new Date(this.getDefaultStartDate());
      nextWeek.setDate(nextWeek.getDate() + 7);
      nextWeek.setHours(0, 0, 0, 0);
      return nextWeek
    },
    disabledDate(date) {
      const checkDate = new Date(date);
      checkDate.setHours(0, 0, 0, 0);


      // Convert disabled dates from "YYYY-MM-DD" to a local Date without UTC shift.
      const isDateDisabled = this.disabledDates.some(disabledDateStr => {
        const [year, month, day] = disabledDateStr.split('-').map(Number);
        const dateToCheck = new Date(year, month - 1, day);
        dateToCheck.setHours(0, 0, 0, 0);

        return checkDate.getTime() === dateToCheck.getTime();
      });

      const pickupDate = this.selectedPickupDate ? new Date(this.selectedPickupDate) : null;
      if (pickupDate) {
        pickupDate.setHours(0, 0, 0, 0);

        const maxAllowedDate = new Date(pickupDate);
        maxAllowedDate.setDate(maxAllowedDate.getDate() + 28);

        const minAllowedDate = new Date(pickupDate);
        minAllowedDate.setDate(minAllowedDate.getDate() - 28);

        if (checkDate > maxAllowedDate || checkDate < minAllowedDate) {
          return true;
        }
      }

      // If no disabled dates are given, disable past dates.
      return isDateDisabled || (checkDate < this.minDate) || (this.maxDate && checkDate > this.maxDate);
    },
    handlePick(date) {
      if (this.selectedPickupDate) {
        this.selectedPickupDate = null;
      } else {
        this.selectedPickupDate = date;
      }
    },
    combineDateTime(date, time) {
      const [hours, minutes] = time.split(':').map(Number);
      const localDate = new Date(date);
      localDate.setHours(hours, minutes, 0, 0);
      return localDate;
    },
    getTimeFromDate(date) {
      if (!date) return '10:00';
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    displayTime(date) {
      if (!date) return '10:00 AM';
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12; // Convert to 12-hour format, making 0 (midnight) to 12
      return `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
    }
  },
  mounted() {
    this.timeToggle = false
    this.tempStartDateTime = this.selectedDateRange[0]
    this.tempEndDateTime = this.selectedDateRange[1]
  },
  beforeDestroy() {
    clearTimeout(this.blurTimeout);
  },
  watch: {
    dateValue: {
      handler(newValue) {
        if (newValue) {
          this.tempStartDateTime = this.combineDateTime(
            newValue.startDate,
            this.timeValue.startTime || '00:00'
          );
          this.tempEndDateTime = this.combineDateTime(
            newValue.endDate,
            this.timeValue.endTime || '00:00'
          );
        }
      },
      deep: true,
    },
    modalVisible(newValue){
      if(newValue){
        this.tempStartDateTime = this.combineDateTime(
            this.dateValue.startDate,
            this.timeValue.startTime || '00:00'
          );
          this.tempEndDateTime = this.combineDateTime(
            this.dateValue.endDate,
            this.timeValue.endTime || '00:00'
          );
          this.selectedPickupDate = null;
      }
    }
  },
};
</script>

<style lang="scss">
.resla-range-picker-inline {

  .mx-btn-icon-right,
  .mx-btn-icon-left {
    position: relative;
    text-indent: -9999px;
    /* Hide original content */
  }

  .mx-btn-icon-right::after,
  .mx-btn-icon-left::after {
    content: '';
    position: absolute;

    width: 24px;
    /* Set icon width */
    height: 24px;
    /* Set icon height */
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M12.303%206.46967C12.5959%206.17678%2013.0707%206.17678%2013.3636%206.46967L18.3636%2011.4697C18.6565%2011.7626%2018.6565%2012.2374%2018.3636%2012.5303L13.3636%2017.5303C13.0707%2017.8232%2012.5959%2017.8232%2012.303%2017.5303C12.0101%2017.2374%2012.0101%2016.7626%2012.303%2016.4697L16.0226%2012.75H6.16663C5.75241%2012.75%205.41663%2012.4142%205.41663%2012C5.41663%2011.5858%205.75241%2011.25%206.16663%2011.25H16.0226L12.303%207.53033C12.0101%207.23744%2012.0101%206.76256%2012.303%206.46967Z%22%20fill%3D%22%2344403C%22/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .mx-btn-icon-left::after {
    rotate: 180deg;
    right: -18px;
    top: 18%;
    /* Adjust as needed */
  }

  .mx-btn-icon-right::after {
    right: -0px;
    /* Adjust as needed */
    top: 18%;
  }

  .mx-btn-icon-double-right,
  .mx-btn-icon-double-left {
    display: none;
  }

  & {
    z-index: 50;
    border-radius: 8px !important;
    font-family: 'VisbyCF', 'Urbanist';
  }

  .cell {
    color: black !important;
    font-family: 'Urbanist';
  }

  .cell:hover {
    background-color: #E5E3E0 !important;
  }

  .not-current-month {
    color: #ccc !important;
    pointer-events: none;
  }

  .active {
    background-color: black !important;
    border-radius: 8px;
    color: white !important;
  }

  .disabled {
    color: #9F9992 !important;
    background-color: transparent !important;
    position: relative;
  }

  .disabled::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 25%;
    width: 50%;
    height: 1px;
    background-color: #9f999299;
    transform: translateY(-50%);
  }

  .active:hover {
    background-color: black !important;
    color: white !important;
  }

  .in-range {
    background-color: #E5E3E0 !important;
  }

  .hover-in-range {
    background-color: #E5E3E0 !important;
  }


  .mx-btn:hover {
    color: black;
  }

  .mx-time-item:hover {
    background-color: #E5E3E0 !important;
  }

  .mx-calendar-header-label {
    font-size: 16px;
    font-weight: 500;

    .mx-btn-current-month,
    .mx-btn-current-year {
      color: #110F0C;
    }
  }

  .mx-table-date {
    thead {
      tr>th {
        font-size: 12px;
        font-weight: 400;
        font-family: "Urbanist";
        color: #2B2825;
      }
    }
  }



  .mx-datepicker-footer {
    border-top: 0;
    padding: 0;
    padding-top: 8px;
  }

  .mx-datepicker-header {
    border-bottom: 0;
  }

  .mx-datepicker-main {
    border: 0;
  }

  .mx-calendar {
    width: 300px;
  }

  @media (max-width: 540px) {
    .mx-calendar {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .mx-datepicker-header {
      display: none;
    }

    .mx-range-wrapper {
      flex-direction: row !important;
    }

    .mx-range-wrapper .mx-calendar.mx-calendar-panel-date:nth-child(2) {
      display: none;
    }
  }

}
</style>
