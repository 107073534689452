import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import search from "./modules/search";
import auth from "./modules/auth";
import reservations from "./modules/reservations";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    root_url: process.env.VUE_APP_ROOT_URL,
    pusher_pk: process.env.VUE_APP_PUSHER_PK,
    isOverlayActive: false,
  },
  mutations: {
    activateOverlay(state) {
      state.isOverlayActive = true;
    },
    deactivateOverlay(state) {
      state.isOverlayActive = false;
    },
  },
  modules: {
    app,
    auth,
    search,
    reservations,
  },
});

export default store;
