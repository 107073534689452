<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="2" width="16" height="20" rx="8" fill="#2B2825" />
      <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.71859 1.88655C9.73291 1.47596 10.841 1.25024 12 1.25024C16.8325 1.25024 20.75 5.16774 20.75 10.0002C20.75 10.4144 20.4142 10.7502 20 10.7502C19.5858 10.7502 19.25 10.4144 19.25 10.0002C19.25 5.99617 16.0041 2.75024 12 2.75024C11.0372 2.75024 10.12 2.93752 9.28141 3.27696C8.89746 3.43238 8.46022 3.24712 8.3048 2.86316C8.14938 2.47921 8.33464 2.04197 8.71859 1.88655ZM6.22373 4.41505C6.54692 4.67413 6.59889 5.14615 6.33981 5.46934C5.34472 6.71068 4.75 8.28498 4.75 10.0002V14.0002C4.75 17.2119 6.83888 19.9377 9.73419 20.8894C10.1277 21.0187 10.3418 21.4425 10.2125 21.836C10.0832 22.2295 9.65932 22.4437 9.26581 22.3144C5.77326 21.1664 3.25 17.879 3.25 14.0002V10.0002C3.25 7.93171 3.96872 6.02899 5.16944 4.53113C5.42852 4.20794 5.90054 4.15597 6.22373 4.41505ZM20 13.2502C20.4142 13.2502 20.75 13.586 20.75 14.0002C20.75 17.879 18.2267 21.1664 14.7342 22.3144C14.3407 22.4437 13.9168 22.2295 13.7875 21.836C13.6582 21.4425 13.8723 21.0187 14.2658 20.8894C17.1611 19.9377 19.25 17.2119 19.25 14.0002C19.25 13.586 19.5858 13.2502 20 13.2502ZM12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V10C11.25 9.58579 11.5858 9.25 12 9.25ZM12 5.25C9.37665 5.25 7.25 7.37665 7.25 10V14C7.25 14.4142 7.58579 14.75 8 14.75C8.41421 14.75 8.75 14.4142 8.75 14V10C8.75 8.20508 10.2051 6.75 12 6.75C12.4142 6.75 12.75 6.41422 12.75 6C12.75 5.58579 12.4142 5.25 12 5.25ZM16.1141 7.62446C15.9067 7.26591 15.4479 7.14339 15.0893 7.3508C14.7308 7.5582 14.6083 8.017 14.8157 8.37555C15.0918 8.85281 15.25 9.40679 15.25 10V14C15.25 15.7949 13.7949 17.25 12 17.25C11.4068 17.25 10.8528 17.0918 10.3755 16.8157C10.017 16.6083 9.5582 16.7308 9.3508 17.0893C9.14339 17.4479 9.26591 17.9067 9.62446 18.1141C10.3239 18.5187 11.1361 18.75 12 18.75C14.6234 18.75 16.75 16.6234 16.75 14V10C16.75 9.13607 16.5187 8.32388 16.1141 7.62446Z"
          fill="#F5F5F7" />
  </svg>

</template>
<script>
export default {
  name: "FingerprintIcon",
};
</script>