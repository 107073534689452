<template>
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.66675 8.40745L5.9488 11.6667L13.3334 4.33337" stroke="#2D966A" stroke-width="1.6"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
<script>
export default {
    name: "GreenTickIcon",
}
</script>