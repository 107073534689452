<template>
  <div>
    <div v-if="licenseRequired || insuranceRequired" class="rounded-lg overflow-hidden">
      <div class="border-b border-primary-400 bg-primary-300 text-primary-1000 py-5 px-6">
        <p class="text-2xl tracking-negative-2 font-medium">Verification Required</p>
        <p class="font-urbanist text-base leading-6.5 font-normal">We need to collect some verification before you can
          access your digital key.</p>
      </div>
      <div class="flex bg-primary-100">
        <CtaButton variant="primary" class="w-full border-r border-primary-400 rounded-none !justify-start"
          @click="verifyInsurance">
          <template v-if="insuranceRequired">
            <NoteIcon slot="leading" class="size-6"></NoteIcon>
            Verify Insurance
          </template>
          <template v-else>
            <GreenTickCircle slot="leading" class="size-5"></GreenTickCircle>
            <span class="text-verde-50">Insurance Verified</span>
          </template>
        </CtaButton>
        <CtaButton variant="primary" class="w-full border-r border-primary-400 rounded-none !justify-start"
          @click="verifyIdentity">
          <template v-if="licenseRequired">
            <FingerprintIcon slot="leading" class="size-6"></FingerprintIcon>
            Verify Identity
          </template>
          <template v-else>
            <GreenTickCircle slot="leading" class="size-5"></GreenTickCircle>
            <span class="text-verde-50">Identity Verified</span>
          </template>
        </CtaButton>
      </div>
    </div>
    <div v-else
      class="flex items-center gap-x-6 bg-verde-50 bg-opacity-10 border border-verde-50 py-5 px-6 rounded-xl text-primary-200 text-xl tracking-negative-2 font-medium">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#38BA84" />
        <path d="M10.667 16.4074L13.949 19.6667L21.3337 12.3333" stroke="#F5F5F7" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      Your digital key is now active
    </div>
    <!-- Veriff Modal -->
    <VeriffModal v-if="isLicenseModalVisible" @close="isLicenseModalVisible = false" />
    <AxleModal v-if="isInsuranceModalVisible" @close="isInsuranceModalVisible = false" />
  </div>
</template>



<script>
import AxleModal from './AxleModal.vue';
import VeriffModal from './VeriffModal.vue';
import CtaButton from '../core/CtaButton.vue';
import NoteIcon from '../core/icons/NoteIcon.vue';
import FingerprintIcon from '../core/icons/FingerprintIcon.vue';
import GreenTickCircle from '../core/icons/GreenTickCircle.vue';


export default {
  name: 'VerificationRequiredCard',
  components: {
    VeriffModal,
    AxleModal,
    CtaButton,
    NoteIcon,
    FingerprintIcon,
    GreenTickCircle
  },
  props: {
    licenseRequired: {
      type: Boolean,
      required: true,
    },
    insuranceRequired: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLicenseModalVisible: false,
      isInsuranceModalVisible: false,
    };
  },
  methods: {
    verifyInsurance() {
      if (this.insuranceRequired) {
        this.isInsuranceModalVisible = true;
      }
    },
    verifyIdentity() {
      if (this.licenseRequired) {
        this.isLicenseModalVisible = true;
      }
    },
  },
};
</script>


<style scoped></style>
