import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    console.log(process.env)
    stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PK); // Replace with your Stripe publishable key
  }
  return stripePromise;
};

export default getStripe;
