<template>
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6663 8.45682C16.6663 -0.596679 3.33301 -0.596745 3.33301 8.45682C3.33301 11.1072 4.74393 13.4093 6.26763 15.1226C7.55865 16.5742 8.20416 17.3 9.99986 17.3C11.7956 17.3 12.441 16.5741 13.732 15.1224C15.2556 13.4092 16.6663 11.1072 16.6663 8.45682ZM9.99967 10.8334C11.3804 10.8334 12.4997 9.71408 12.4997 8.33336C12.4997 6.95265 11.3804 5.83336 9.99967 5.83336C8.61896 5.83336 7.49967 6.95265 7.49967 8.33336C7.49967 9.71408 8.61896 10.8334 9.99967 10.8334Z" fill="#E5E3E0"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.08301 8.45679C4.08301 6.39216 4.8362 4.90112 5.91971 3.92016C7.01599 2.92764 8.49657 2.41664 9.99967 2.41664C11.5028 2.41665 12.9834 2.92765 14.0796 3.92018C15.1632 4.90114 15.9163 6.39218 15.9163 8.45679C15.9163 10.8404 14.6425 12.9699 13.1715 14.624C12.5049 15.3737 12.0894 15.8329 11.6407 16.1305C11.2388 16.3971 10.7797 16.55 9.99985 16.55C9.22004 16.55 8.76093 16.3972 8.35901 16.1306C7.91027 15.833 7.49473 15.3737 6.82806 14.6241C5.35694 12.97 4.08301 10.8405 4.08301 8.45679ZM17.4163 8.45679C17.4163 5.99466 16.5029 4.09062 15.0864 2.8082C13.6827 1.53734 11.8299 0.916646 9.99968 0.916641C8.16944 0.916637 6.3167 1.53732 4.91298 2.80818C3.49649 4.0906 2.58301 5.99464 2.58301 8.45679C2.58301 11.3739 4.13092 13.8485 5.70719 15.6209L5.75482 15.6745C6.35979 16.3549 6.90074 16.9634 7.52997 17.3807C8.22249 17.84 8.98398 18.05 9.99988 18.05C11.0158 18.05 11.7773 17.8399 12.4698 17.3806C13.099 16.9633 13.6399 16.3548 14.2448 15.6744L14.2924 15.6208C15.8686 13.8484 17.4163 11.3738 17.4163 8.45679ZM8.24967 8.33333C8.24967 7.36684 9.03318 6.58333 9.99967 6.58333C10.9662 6.58333 11.7497 7.36684 11.7497 8.33333C11.7497 9.29983 10.9662 10.0833 9.99967 10.0833C9.03318 10.0833 8.24967 9.29983 8.24967 8.33333ZM9.99967 5.08333C8.20475 5.08333 6.74967 6.53841 6.74967 8.33333C6.74967 10.1283 8.20475 11.5833 9.99967 11.5833C11.7946 11.5833 13.2497 10.1283 13.2497 8.33333C13.2497 6.53841 11.7946 5.08333 9.99967 5.08333Z" fill="#44403C"/>
  </svg>
  
  </template>
  
  <script>
  export default {
    name: "MapPinIcon",
  };
  </script>