<template>
  <div></div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "LogoutView",
  mounted: function () {
    this.$store.commit("logout");
    this.$router.push("/login");
  },
};
</script>
