<template>
  <div class="flex flex-col gap-5 max-w-screen-lg mx-auto py-10 px-6 relative">
    <template>
      <SkeletonLoader width="100%" height="69px"></SkeletonLoader>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-2 rounded-xl overflow-hidden">
        <div class="md:col-span-2">
          <SkeletonLoader width="100%" height="430px" rounded></SkeletonLoader>
        </div>
        <div class="flex flex-row md:flex-col w-full space-y-0 md:space-y-2 space-x-2 md:space-x-0">
          <SkeletonLoader width="100%" height="100%" rounded></SkeletonLoader>
          <SkeletonLoader width="100%" height="100%" rounded></SkeletonLoader>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div class="flex flex-col gap-6">
          <div class="grid grid-cols-3 text-center w-full gap-2">
            <SkeletonLoader width="100%" height="103px"></SkeletonLoader>
            <SkeletonLoader width="100%" height="100%"></SkeletonLoader>
            <SkeletonLoader width="100%" height="100%"></SkeletonLoader>
          </div>
          <SkeletonLoader width="100%" height="300px" rounded></SkeletonLoader>
          <SkeletonLoader width="100%" height="400px" rounded></SkeletonLoader>
        </div>
        <SkeletonLoader width="100%" height="600px" rounded></SkeletonLoader>
      </div>
    </template>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/core/animations/SkeletonLoader.vue';
import { mapActions } from 'vuex';

export default {
  name: "TripLoadingCreateBookingView",
  components: {
    SkeletonLoader,
  },
  methods: {
    ...mapActions('reservations', ['createReservation']),
    async loadCreateReservation() {
      const reservation = {
        vehicleVin: this.$route.query.vehicleVin,
        pickupAt: this.$route.query.pickupAt,
        returnAt: this.$route.query.returnAt,
        addonIds: JSON.parse(this.$route.query.addonIds),
        vehicleTimezone: this.$route.query.vehicleTimezone,
      };
      const redirectToDamageWaiver = this.$route.query.redirectToDamageWaiver === 'true';
      console.log({ reservation, redirectToDamageWaiver });
      try {
        const response = await this.createReservation({
          vehicleVin: reservation.vehicleVin,
          pickupAt: new Date(reservation.pickupAt),
          returnAt: new Date(reservation.returnAt),
          addonIds: reservation.addonIds,
          vehicleTimezone: reservation.vehicleTimezone,
        });
        if (redirectToDamageWaiver) {
          this.$router.push({
            name: 'damage-waiver', params: {
              platform_id: response.reservation.platform_id
            }
          });
        } else {
          this.$router.push({
            name: 'signature', params: {
              platform_id: response.reservation.platform_id
            }
          });
        }
      } catch (err) {
        if (err.response && err.response.status === 409) {
          this.$router.push({
            path: `vehicle/${this.$route.query.vehicle_vin}`,
            query: {
              vehicleUnavailableModal: true,
            },
          });
        } else {
          console.error(err);
        }
      }
    },
  },
  mounted() {
    this.loadCreateReservation();
  },
};
</script>
