<template>
  <div class="flex flex-col gap-5 max-w-screen-lg mx-auto py-10 px-6 relative">
    <!--loading-->
    <template v-if="loading">
      <SkeletonLoader width="100%" height="69px"></SkeletonLoader>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-2 rounded-xl overflow-hidden">
        <div class="md:col-span-2">
          <SkeletonLoader width="100%" height="430px" rounded></SkeletonLoader>
        </div>
        <div class="flex flex-row md:flex-col w-full space-y-0 md:space-y-2 space-x-2 md:space-x-0">
          <SkeletonLoader width="100%" height="100%" rounded></SkeletonLoader>
          <SkeletonLoader width="100%" height="100%" rounded></SkeletonLoader>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div class="flex flex-col gap-6">
          <div class="grid grid-cols-3 text-center w-full gap-2">
            <SkeletonLoader width="100%" height="103px"></SkeletonLoader>
            <SkeletonLoader width="100%" height="100%"></SkeletonLoader>
            <SkeletonLoader width="100%" height="100%"></SkeletonLoader>
          </div>
          <SkeletonLoader width="100%" height="300px" rounded></SkeletonLoader>
          <SkeletonLoader width="100%" height="400px" rounded></SkeletonLoader>
        </div>
        <SkeletonLoader width="100%" height="600px" rounded></SkeletonLoader>
      </div>
    </template>
    <template v-if="!loading && vehicle">
      <!-- header -->
      <div>
        <CtaButton variant="tertiary" leading-icon @click="() => $router.push('/search')">Search</CtaButton>
        <div class="flex items-center justify-between mt-1">
          <div>
            <h2 class="text-heading font-medium text-primary-100 tracking-negative-3">{{ vehicle.model.name }}</h2>
            <Badge v-if="isLongRange">{{ vehicle.battery_type }}</Badge>
          </div>
          <CtaButton variant="tertiary" @click="copyPageUrl" title="Copy Page Link">
            <ShareIcon class="size-5"></ShareIcon>
          </CtaButton>
        </div>
      </div>
      <!-- Images -->
      <div v-if="vehicle.images" class="grid grid-cols-1 md:grid-cols-3 gap-2 rounded-xl overflow-hidden relative">
        <div class="md:col-span-2">
          <img :src="getMoneyshotImage(vehicle.images)" alt="Car Main" class="w-full h-full object-cover " />
        </div>
        <div class="flex flex-row md:flex-col w-full space-y-0 md:space-y-2 space-x-2 md:space-x-0">
          <img :src="getSideImage(vehicle.images)" alt="Car Side"
            class="w-full max-h-40 md:max-h-full md:h-full object-cover " />
          <img :src="getInteriorImage(vehicle.images)" alt="Car Interior"
            class="w-full max-h-40 md:max-h-full md:h-full object-cover " />
        </div>
        <button variant="tertiary"
          class="bg-white !py-[7px] !px-[15px] border border-[#222222] !rounded-lg  flex items-center !gap-2 !text-sm !font-medium absolute bottom-5 right-5 cursor-pointer"
          size="large" @click="handleShowPhotos">
          <ShowAllIcon slot="leading"></ShowAllIcon>
          <div>Show all photos</div>
        </button>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <!-- Stats Section -->
        <div class="flex flex-col gap-6">
          <div class="grid grid-cols-3 text-center w-full border-y border-primary-800">
            <div class="py-6 px-3 border-r border-primary-800">
              <p class="text-2xl font-medium text-primary-100 tracking-negative-2">{{
                vehicle.model.zero_to_sixty_mph_seconds.toFixed(1) }}</p>
              <p class="mt-1 text-sm font-urbanist font-normal text-primary-400"> 0-60mph</p>
            </div>
            <div class="py-6 px-3 border-r border-primary-800">
              <p class="text-2xl font-medium text-primary-100 tracking-negative-2">{{ vehicle.model.top_speed_mph }}</p>
              <p class="mt-1 text-sm font-urbanist font-normal text-primary-400"> Top Speed (mph)</p>
            </div>
            <div class="py-6 px-3">
              <p class="text-2xl font-medium text-primary-100 tracking-negative-2">{{ vehicle.model.mile_range }}</p>
              <p class="mt-1 text-sm font-urbanist font-normal text-primary-400" v-tooltip="{
                content: `The Environmental Protection Agency (EPA) rates electric vehicle range based on 55% highway and 45% city driving.`,
                maxWidth: 200,
                placement: 'top',
              }"> mi Range (EPA est.) </p>
            </div>
          </div>
          <!-- Features Section -->
          <VehicleFeatures :vehicle="vehicle" />
          <div class="w-full h-px bg-primary-800"></div>
          <!-- Map Section -->
          <div class="flex flex-col gap-4">
            <div>
              <p class="text-2xl leading-9 tracking-negative-2 text-primary-100 font-medium">Pickup and Return at</p>
              <p class="text-sm font-medium">Location Name <span class="ml-2 font-urbanist font-normal">{{
                vehicleLocation }}</span></p>
            </div>
            <div>
              <div class="relative">
                <VehicleMap class="w-full h-96" :latitude="vehicle.location.latitude"
                  :longitude="vehicle.location.longitude" :markers="[{
                    latitude: this.vehicle.location.latitude,
                    longitude: this.vehicle.location.longitude,
                    title: this.vehicle.model.name,
                  }]" :show-popup="false" :map-icon="true" />
              </div>
              <a :href="googleMapsLink" tag="button" target="_blank" rel="noopener noreferrer">
                <CtaButton variant="tertiary" trailingIcon class="mt-2 font-semibold">Directions</CtaButton>
              </a>
            </div>
          </div>
        </div>
        <!-- Booking Section -->
        <BookingCard :vehicle="vehicle" :isBookingLoading="isBookingLoading" @book="bookNow" />
      </div>
    </template>
    <Modal v-if="vehicle" v-model="imageModalToggle" :title="vehicle.model.name" show-title full-screen
      :show-header="true" :show-footer="false" :show-close-button="true">
      <div v-if="vehicle && vehicle.images" class="w-full py-10">
        <img :src="getMoneyshotImage(vehicle.images)" alt="Car Main"
          class="w-full h-full max-h-[600px] object-cover " />
        <div class="flex flex-col md:flex-row w-full items-center gap-2 mt-2">
          <img :src="getSideImage(vehicle.images)" alt="Car Side" class="w-full max-h-80  h-full object-cover " />
          <img :src="getInteriorImage(vehicle.images)" alt="Car Interior"
            class="w-full max-h-80 h-full object-cover " />
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-2 mt-2">
          <img v-for="image in getSecondaryImages(vehicle.images)" :key="image.id" :src="image.image_url" alt="Car Side"
            class="w-full h-full object-cover " />
        </div>
      </div>
    </Modal>
    <VehicleUnavailableModal modal-class="'w-max'" :value="vehicleUnavailableModalToggle"
      @input="handleVehicleUnavailble" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import api from '@/api';
import VehicleFeatures from "@/components/vehicle/VehicleFeatures.vue";
import VehicleMap from "@/components/maps/VehicleMap.vue";
import BookingCard from "@/components/vehicle/BookingCard.vue";
import CtaButton from "@/components/core/CtaButton.vue";
import Badge from "@/components/core/Badge.vue";
import SkeletonLoader from "@/components/core/animations/SkeletonLoader.vue";
import ShareIcon from "@/components/core/icons/ShareIcon.vue";
import Modal from '@/components/core/Modal.vue';
import ShowAllIcon from "@/components/core/icons/ShowAllIcon.vue";
import VehicleUnavailableModal from "@/components/vehicle/VehicleUnavailableModal.vue";

export default {
  name: "VehicleView",
  components: {
    VehicleMap,
    BookingCard,
    VehicleFeatures,
    CtaButton,
    Badge,
    SkeletonLoader,
    ShareIcon,
    ShowAllIcon,
    Modal,
    VehicleUnavailableModal,
  },
  data() {
    return {
      loading: true,
      vehicle: null,
      isBookingLoading: false,
      imageModalToggle: false,
      vehicleUnavailableModalToggle: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    isLongRange() {
      return this.vehicle.model.name.endsWith('Long Range');
    },
    googleMapsLink() {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.vehicleLocation)}`;
    },
    vehicleLocation() {
      const { street = '', city = '', state = '', zipcode = '' } = this.vehicle?.location || {};
      return `${street}, ${city}, ${state}, ${zipcode}`;
    },
  },
  methods: {
    ...mapActions('reservations', ['createReservation']),
    async fetchVehicleDetails() {
      const vehicleVin = this.$route.params.vin;
      try {
        this.vehicle = await api.vehicle.getVehicle(vehicleVin);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }

      console.log(this.vehicle);
    },
    getMoneyshotImage(vehicleImages) {
      return vehicleImages.find((image) => image.view === 'MONEYSHOT')?.image_url;
    },
    getSideImage(vehicleImages) {
      return vehicleImages.find((image) => image.view === 'L_SIDE')?.image_url;
    },
    getInteriorImage(vehicleImages) {
      return vehicleImages.find((image) => image.view === 'RF_INTERIOR')?.image_url;
    },
    getSecondaryImages(vehicleImages) {
      const orderedImages = [];
      vehicleImages.forEach((image) => {
        if (image.view !== 'MONEYSHOT' && image.view !== 'L_SIDE' && image.view !== 'RF_INTERIOR') {
          orderedImages.push(image);
        }
      });
      return orderedImages;
    },
    async bookNow(bookingDetails) {
      console.log(bookingDetails)
      this.isBookingLoading = true;
      const reservation = {
        vehicle_vin: this.vehicle.vin,
        pickupAt: bookingDetails.pickupAt,
        returnAt: bookingDetails.returnAt,
        addon_ids: bookingDetails.selectedAddons.map(addon => addon.id),
      };

      if (this.user === null) {
        // Redirect to login with `bookingDetails` in the query parameters
        this.$router.push({
          name: 'login',
          query: {
            redirect: 'create-booking',
            vehicleVin: reservation.vehicle_vin,
            pickupAt: reservation.pickupAt.toISOString(),
            returnAt: reservation.returnAt.toISOString(),
            addonIds: JSON.stringify(reservation.addon_ids),
            vehicleTimezone: this.vehicle.location.timezone,
            redirectToDamageWaiver: bookingDetails.redirectToDamageWaiver,
          },
        });
        return;
      }
      try {
        const response = await this.createReservation({
          vehicleVin: reservation.vehicle_vin,
          pickupAt: reservation.pickupAt,
          returnAt: reservation.returnAt,
          addonIds: reservation.addon_ids,
          vehicleTimezone: this.vehicle.location.timezone,
        });
        if (bookingDetails.redirectToDamageWaiver) {
          this.$router.push({
            name: 'damage-waiver', params: {
              platform_id: response.reservation.platform_id
            }
          });
        } else {
          this.$router.push({
            name: 'signature', params: {
              platform_id: response.reservation.platform_id
            }
          });
        }
      } catch (err) {
        if (err.response && err.response.status === 409) {
          this.vehicleUnavailableModalToggle = true;
        } else {
          console.error(err);
        }
      } finally {
        this.isBookingLoading = false;
      }
    },
    copyPageUrl() {
      const url = window.location.href;
      navigator.clipboard.writeText(url).then(() => {
        alert('Page URL copied!');
      }).catch(err => {
        console.error('Failed to copy URL: ', err);
      });
    },
    handleShowPhotos() {
      this.imageModalToggle = true
    },
    handleVehicleUnavailble(goBack = false) {
      this.vehicleUnavailableModalToggle = false
      if (goBack) {
        this.$router.push('/search')
      }
    }
  },
  created() {
    this.fetchVehicleDetails();
    if (this.$route.query.vehicleUnavailableModal === 'true') {
      this.vehicleUnavailableModalToggle = true;
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>