<template>
  <div>
    <div
      v-if="reservation"
      class="flex flex-col gap-12 py-20 px-6 md:px-20 max-w-3xl mx-auto"
    >
      <!--header-->
      <div
        class="flex flex-col md:flex-row md:items-center gap-5 justify-between"
      >
        <h2
          class="text-start text-[56px] leading-[56px] tracking-negative-3 font-medium text-resla-ebony-1000"
        >
          {{ reservation.vehicle.model.name }}
        </h2>
        <CtaButton
          variant="primary"
          @click="handleDigitalKeyClick"
          :is-disabled="
            userLicenseVerificationRequired || userInsuranceVerificationRequired || reservation.status === 'Started'
          "
        >
          Digital Key
        </CtaButton>
        <CtaButton
          v-if="reservation.status === 'Returned'"
          variant="primary"
          @click="handleBookAgain"
        >
          Book Again
        </CtaButton>
      </div>
      <!--verification-->
      <VerificationRequiredCard
        :license-required="userLicenseVerificationRequired"
        :insurance-required="userInsuranceVerificationRequired"
      />
      <!-- Pickup & Return Details -->
      <div class="flex flex-col gap-6">
        <h3
          class="text-heading tracking-negative-3 font-medium text-resla-ebony-1000"
        >
          Pickup & Return Details
        </h3>
        <div
          class="bg-primary-900 border border-primary-800 p-6 gap-10 rounded-lg flex items-center"
        >
          <div
            class="min-w-32 text-start text-primary-100 text-base leading-6.5 font-medium"
          >
            <p class="font-urbanist font-semibold text-xl leading-8">Pickup</p>
            <p>{{ localPickupDate }}</p>
            <p>{{ localPickupTime }}</p>
          </div>
          <div class="w-full h-0.5 bg-primary-700"></div>
          <div
            class="min-w-32 text-start text-primary-100 text-base leading-6.5 font-medium"
          >
            <p class="font-urbanist font-semibold text-xl leading-8">Return</p>
            <p>{{ localReturnDate }}</p>
            <p>{{ localReturnTime }}</p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="text-primary-100 text-xl leading-8 font-urbanist">
            <p class="font-semibold">
              Pickup & Return Near {{ locationName }}
            </p>
            <!-- <p class="font-normal">{{ reservation.address }}</p> -->
          </div>
          <div
            class="border border-primary-700 flex w-full rounded-lg overflow-hidden"
          >
            <CtaButton
              variant="secondary"
              class="w-full bg-white border-0 border-r border-primary-700 rounded-none"
              @click="copyAddress"
            >
              <CopyIcon slot="leading" class="size-6" />
              Copy Address
            </CtaButton>
            <CtaButton
              variant="secondary"
              class="w-full bg-white border-0 rounded-none"
              :url="googleMapsLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MapPinIcon slot="leading" class="size-6" />
              Get Directions
            </CtaButton>
          </div>
        </div>
        <div class="relative border border-primary-700 rounded-lg">
          <VehicleMap
            class="w-8 h-96"
            :latitude="reservation.pickup_location.latitude"
            :longitude="reservation.pickup_location.longitude"
            :markers="[
              {
                latitude: reservation.pickup_location.latitude,
                longitude: reservation.pickup_location.longitude,
                title: reservation.vehicle.model.name,
                description: reservation.pickup_location.name
              }
            ]"
          />
        </div>
      </div>
      <!-- Vehicle Detail -->
      <div class="flex flex-col gap-5">
        <h3
          class="text-heading tracking-negative-3 font-medium text-resla-ebony-1000"
        >
          Your Vehicle
        </h3>
        <VehicleMetrics class="" :vehicle="reservation.vehicle" />
      </div>
      <!-- Driver Detail -->
      <div class="flex flex-col gap-6">
        <h3
          class="text-heading tracking-negative-3 font-medium text-resla-ebony-1000"
        >
          Driver Details
        </h3>
        <div>
          <p
            class="text-resla-ebony-1000 text-2xl tracking-negative-2 font-medium"
          >
            {{ fullName }}
          </p>
          <div
            class="mt-5 bg-primary-900 border border-primary-700 rounded-lg overflow-hidden"
          >
            <TextInput
              id="phone"
              class="border-b border-primary-700"
              label="Phone Number"
              icon
              readonly
              :value="phoneNumber"
            >
              <PhoneIcon slot="icon" class="size-5"></PhoneIcon>
            </TextInput>
            <TextInput
              id="license"
              :class="{ 'border-b border-primary-700': userEmail }"
              label="Driver’s License"
              icon
              readonly
              :value="licenseNumber"
            >
              <CreditCardIcon slot="icon" class="size-5"></CreditCardIcon>
            </TextInput>
            <TextInput
              v-show="userEmail"
              id="email"
              label="Email"
              icon
              readonly
              :value="userEmail"
            >
              <UserIcon slot="icon" class="size-5"></UserIcon>
            </TextInput>
          </div>
        </div>
      </div>
      <!-- Payment Detail -->
      <div class="flex flex-col gap-6">
        <h3
          class="text-heading tracking-negative-3 font-medium text-resla-ebony-1000"
        >
          Payment Details
        </h3>
        <VehiclePaymentCard :reservation="reservation" />
        <TripLineItems
          :reservation="reservation"
          :key="lineItemsKey"
        />
      </div>
      <!-- Actions & Cancellation Policy -->
      <div class="flex flex-col gap-4">
        <div>
          <h4
            class="text-2xl tracking-negative-2 font-medium text-resla-ebony-1000"
          >
            Cancellation Policy
          </h4>
          <p class="font-urbanist text-base leading-6.5 text-primary-200">
            Free Cancellation if cancelled before
            {{ formatDateToLocale(oneDayBeforeStartDate) }}
          </p>
        </div>
        <ModifyReservation
          :show-cancel="!isAfterCancellationCutoff"
          :show-edit="false"
          @view-receipt="handleViewReceipt"
        />
      </div>
      <!--agreements-->
      <div class="flex flex-col gap-4">
        <h4
          class="text-2xl tracking-negative-2 font-medium text-resla-ebony-1000"
        >
          Agreements
        </h4>
        <Agreements :reservation="reservation" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatDateToLocale, formatTimeToLocale } from '@/helpers/util';

import CopyIcon from '@/components/core/icons/CopyIcon.vue';
import MapPinIcon from '@/components/core/icons/MapPinIcon.vue';
import ModifyReservation from '@/components/trip-detail/ModifyReservation.vue';
import VehiclePaymentCard from '@/components/trip-detail/VehiclePaymentCard.vue';
import VehicleMap from '@/components/maps/VehicleMap.vue';
import TripLineItems from '@/components/cards/TripLineItems.vue';
import VehicleMetrics from '@/components/trip-detail/VehicleMetrics.vue';
import Agreements from '@/components/trip-detail/Agreements.vue';
import VerificationRequiredCard from '@/components/trip-detail/VerificationRequiredCard.vue';
import CtaButton from '@/components/core/CtaButton.vue';
import TextInput from '@/components/core/TextInput.vue';
import PhoneIcon from '@/components/core/icons/PhoneIcon.vue';
import CreditCardIcon from '@/components/core/icons/CreditCardIcon.vue';
import UserIcon from '@/components/core/icons/UserIcon.vue';

export default {
  name: 'TripView',
  components: {
    CopyIcon,
    MapPinIcon,
    ModifyReservation,
    VehiclePaymentCard,
    VehicleMap,
    TripLineItems,
    VehicleMetrics,
    Agreements,
    VerificationRequiredCard,
    CtaButton,
    TextInput,
    PhoneIcon,
    CreditCardIcon,
    UserIcon
  },
  data() {
    return {
      lineItemsKey: 0,
    };
  },
  computed: {
    ...mapGetters('reservations', ['reservations']),
    ...mapState('auth', ['user']),
    reservation() {
      return this.reservations.find(
        (reservation) => reservation.id === this.$route.params.platform_id
      );
    },
    googleMapsLink() {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        this.fullAddress
      )}`;
    },
    fullName() {
      if (!this.user || !this.user.first_name || !this.user.last_name)
        return '';
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    locationName(){
      return this.reservation?.pickup_location?.name;
    },
    fullAddress(){
      const { street = '', city = '', state = '', zip_code = '' } = this.reservation?.pickup_location || {};
      return `${street}, ${city}, ${state}, ${zip_code}`;
    },
    phoneNumber() {
      if (!this.user) return '';
      return this.user.phone;
    },
    licenseNumber() {
      if (!this.user) return '';
      return this.user.license_number;
    },
    userEmail() {
      if (!this.user) return null;
      return this.user.email;
    },
    localPickupDate() {
      if (!this.reservation || !this.reservation.pickup_at) return '';
      return formatDateToLocale(
        this.reservation.pickup_at,
        this.reservation.pickup_location.timezone
      );
    },

    localReturnDate() {
      if (!this.reservation || !this.reservation.return_at) return '';
      return formatDateToLocale(
        this.reservation.return_at,
        this.reservation.dropoff_location.timezone
      );
    },

    localPickupTime() {
      if (!this.reservation || !this.reservation.pickup_at) return '';
      return formatTimeToLocale(
        this.reservation.pickup_at,
        this.reservation.pickup_location.timezone
      );
    },

    localReturnTime() {
      if (!this.reservation || !this.reservation.return_at) return '';
      return formatTimeToLocale(
        this.reservation.return_at,
        this.reservation.dropoff_location.timezone
      );
    },
    oneDayBeforeStartDate() {
      const date = new Date(this.reservation.pickup_at);
      date.setDate(date.getDate() - 1);
      return date;
    },
    isAfterCancellationCutoff() {
      return new Date() > new Date(this.oneDayBeforeStartDate);
    },
    userLicenseVerificationRequired() {
      return (
        !this.user.license_number || this.user.license_expiration < new Date()
      );
    },
    userInsuranceVerificationRequired() {
      return this.reservation.insurance_verifications.some(
        (verification) => verification.status !== 'ACTIVE'
      );
    }
  },
  methods: {
    ...mapActions('reservations', ['getReservations']),
    copyAddress() {
      navigator.clipboard
        .writeText(this.fullAddress)
        .then(() => {
          alert(`
            Address copied to clipboard!
            ${this.fullAddress}
          `);
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    },
    handleDigitalKeyClick() {
      window.open(
        `https://key.resla.com#token=${this.reservation.key.token}`,
        '_blank'
      );
    },
    handleViewReceipt() {
      this.$router.push(`/trip/${this.reservation.id}/receipt`);
    },
    handleBookAgain() {
      this.$router.push(`/search?model=${this.reservation.vehicle.model.name}`);
    },
    refreshLineItems() {
      this.lineItemsKey += 1
    },
    formatDateToLocale,
    formatTimeToLocale
  },
  async created() {
    await this.getReservations();

    setTimeout(async () => {
      await this.getReservations();
      this.refreshLineItems();
    }, 5000);
  }
};
</script>

<style scoped></style>
