<template>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.5001 4.16675C12.5001 2.78604 13.6194 1.66675 15.0001 1.66675C16.3808 1.66675 17.5001 2.78604 17.5001 4.16675C17.5001 5.54746 16.3808 6.66675 15.0001 6.66675C13.6194 6.66675 12.5001 5.54746 12.5001 4.16675ZM1.66675 10.0001C1.66675 8.61937 2.78604 7.50008 4.16675 7.50008C5.54746 7.50008 6.66675 8.61937 6.66675 10.0001C6.66675 11.3808 5.54746 12.5001 4.16675 12.5001C2.78604 12.5001 1.66675 11.3808 1.66675 10.0001ZM15.0001 13.3334C13.6194 13.3334 12.5001 14.4527 12.5001 15.8334C12.5001 17.2141 13.6194 18.3334 15.0001 18.3334C16.3808 18.3334 17.5001 17.2141 17.5001 15.8334C17.5001 14.4527 16.3808 13.3334 15.0001 13.3334Z"
            fill="#E5E3E0" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.837 3.41675C12.1756 1.9834 13.4633 0.916748 15.0001 0.916748C16.795 0.916748 18.2501 2.37182 18.2501 4.16675C18.2501 5.96167 16.795 7.41675 15.0001 7.41675C13.4633 7.41675 12.1756 6.3501 11.837 4.91675L7.50008 4.91675C6.29529 4.91675 5.28313 5.74149 4.99738 6.85719C6.38992 7.22425 7.41675 8.49225 7.41675 10.0001C7.41675 11.5079 6.38992 12.7759 4.99738 13.143C5.28313 14.2587 6.29529 15.0834 7.50008 15.0834L11.837 15.0834C12.1756 13.6501 13.4633 12.5834 15.0001 12.5834C16.795 12.5834 18.2501 14.0385 18.2501 15.8334C18.2501 17.6283 16.795 19.0834 15.0001 19.0834C13.4633 19.0834 12.1756 18.0168 11.837 16.5834L7.50008 16.5834C5.47508 16.5834 3.79436 15.1094 3.47239 13.1757C2.01109 12.8577 0.916748 11.5567 0.916748 10.0001C0.916748 8.44347 2.01109 7.14246 3.47239 6.82442C3.79437 4.89079 5.47508 3.41675 7.50008 3.41675L11.837 3.41675ZM4.1646 8.25008C3.19909 8.25124 2.41675 9.0343 2.41675 10.0001C2.41675 10.9666 3.20025 11.7501 4.16675 11.7501C5.13325 11.7501 5.91675 10.9666 5.91675 10.0001C5.91675 9.03358 5.13325 8.25008 4.16675 8.25008L4.1646 8.25008ZM15.0001 2.41675C14.0336 2.41675 13.2501 3.20025 13.2501 4.16675C13.2501 5.13325 14.0336 5.91675 15.0001 5.91675C15.9666 5.91675 16.7501 5.13325 16.7501 4.16675C16.7501 3.20025 15.9666 2.41675 15.0001 2.41675ZM13.2501 15.8334C13.2501 14.8669 14.0336 14.0834 15.0001 14.0834C15.9666 14.0834 16.7501 14.8669 16.7501 15.8334C16.7501 16.7999 15.9666 17.5834 15.0001 17.5834C14.0336 17.5834 13.2501 16.7999 13.2501 15.8334Z"
            fill="#54504C" />
    </svg>

</template>

<script>
export default {
    name: "ShareIcon",
};
</script>