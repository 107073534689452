<template>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_67_113)">
            <path
                d="M10 1.66675C5.39752 1.66675 1.66669 5.39758 1.66669 10.0001C1.66669 14.1592 4.71419 17.6067 8.69835 18.2326V12.4084H6.58169V10.0001H8.69835V8.16425C8.69835 6.07591 9.94169 4.92258 11.8459 4.92258C12.7575 4.92258 13.7109 5.08508 13.7109 5.08508V7.13508H12.6609C11.625 7.13508 11.3025 7.77758 11.3025 8.43675V10.0001H13.6134L13.2442 12.4084H11.3025V18.2326C15.2859 17.6076 18.3334 14.1584 18.3334 10.0001C18.3334 5.39758 14.6025 1.66675 10 1.66675Z"
                fill="#44403C" />
        </g>
        <defs>
            <clipPath id="clip0_67_113">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "FacebookIcon",
};
</script>