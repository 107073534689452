<template>
    <svg width="100%" height="100%" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.305306 9.89183C0.426095 8.55603 1.48176 7.54751 2.80907 7.35456C5.29179 6.99364 9.33283 6.32382 12.298 5.42596C15.6697 4.40504 20.4324 2.2469 22.7133 1.17793C23.5495 0.786061 24.513 0.786062 25.3492 1.17793C27.6301 2.2469 32.3928 4.40504 35.7645 5.42596C38.7297 6.32382 42.7707 6.99364 45.2534 7.35456C46.5807 7.54751 47.6364 8.55602 47.7572 9.89183C48.4881 17.975 49.1245 45.6064 24.8711 53.1955C24.334 53.3635 23.7285 53.3635 23.1914 53.1955C-1.06201 45.6064 -0.425603 17.975 0.305306 9.89183Z"
              fill="#AADEC8" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M20.8791 45.6676C19.8332 45.194 18.8626 44.6608 17.962 44.077L26.6761 8.43851C27.576 8.85563 28.6187 9.32282 29.6603 9.75441L20.8791 45.6676ZM23.5862 7.5542L15.1817 41.9269C13.041 39.9654 11.4225 37.6694 10.2046 35.2402L16.2317 10.5907C18.3982 9.83464 21.0837 8.59416 22.6716 7.83189C22.9638 7.69161 23.2724 7.59905 23.5862 7.5542Z"
              fill="#E0F3EB" />
            <path
              d="M6.80499 14.7343C6.9041 13.3953 7.95744 12.3793 9.28355 12.1692C11.0848 11.8837 13.6198 11.4191 15.535 10.8185C17.7916 10.1108 20.9087 8.67451 22.6721 7.82799C23.5312 7.41559 24.5318 7.41559 25.3909 7.82799C27.1543 8.67451 30.2713 10.1108 32.5279 10.8185C34.4432 11.4191 36.9782 11.8837 38.7794 12.1692C40.1055 12.3793 41.1589 13.3953 41.258 14.7343C41.7507 21.3915 41.6915 40.9232 24.894 46.5617C24.3421 46.747 23.7208 46.747 23.169 46.5617C6.37151 40.9232 6.31227 21.3915 6.80499 14.7343Z"
              stroke="#2D966A" stroke-width="2" />
    </svg>
  </template>
  
  <script>
  export default {
    name: "ShieldGreenIcon",
  };
  </script>