<template>
  <div class="p-10">
    <!-- Welcome Header -->
    <h2 class="text-[56px] leading-[56px] tracking-negative-3 font-medium text-primary-100 mb-10">Welcome back<span
        v-if="this.firstName">{{ this.firstName }}</span></h2>
    <div class="flex flex-col gap-16">
      <!-- Search Bar Component -->
      <div class="flex flex-col gap-5">
        <p class="text-2xl leading-9 tracking-neagtive-2 text-primary-200 font-medium">Where to next?</p>
        <SearchBar @search="handleSearch" @update:city="handleCityChange" @update:pickupDate="handlePickupDateChange"
          @update:returnDate="handleReturnDateChange" @update:pickupTime="handlePickupTimeChange"
          @update:returnTime="handleReturnTimeChange" />
      </div>
      <!-- upcoming Reservation & search options -->
      <div class="grid grid-cols-1 gap-8" :class="{
        'md:grid-cols-2': upcomingReservations.length > 0,
      }">
        <!-- Upcoming Reservations -->
        <div v-if="upcomingReservations.length > 0">
          <h4 class="text-2xl leading-9 tracking-negative-2 font-medium text-primary-200 mb-6">Upcoming Reservations
          </h4>
          <UpcomingReservationCard :reservation="upcomingReservations[0]" @view-details="handleViewDetails" />
        </div>

        <!-- Search Options -->
        <div>
          <h4 class="text-2xl leading-9 tracking-negative-2 font-medium text-primary-200 mb-6">Search by model</h4>
          <ModelGrid v-if="!isLoading && models.length > 0" :models="models" />
          <div v-if="isLoading" class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <SkeletonLoader v-for="n in 7" :key="n" width="100%" height="133px" rounded></SkeletonLoader>
          </div>
          <h2 class="text-2xl leading-9 tracking-negative-2 font-medium text-primary-200 mt-10 mb-6">Search by location
          </h2>
          <CityGrid v-if="!isLoading && cities.length > 0" :locations="locations" />
          <div v-if="isLoading"
            class="grid grid-cols-1 md:grid-cols-2 divide-y divide-x divide-primary-700 border border-primary-700 rounded-xl">
            <SkeletonLoader v-for="n in 6" :key="n" width="100%" height="133px"></SkeletonLoader>
          </div>
        </div>

      </div>
      <!-- Past Reservation -->
      <div v-if="pastReservations.length > 0" class="flex flex-col gap-8">
        <div class="flex justify-between items-center">
          <h4 class="text-[32px] leading-8 tracking-negative-3 text-primary-200 font-medium">Rent Again</h4>
          <CtaButton variant="tertiary" trailing-icon to="/trips">All Past Trips</CtaButton>
        </div>

        <PastReservationsRow :pastReservations="pastReservations" />
      </div>
      <!-- Resources Section -->
      <ResourcesSection></ResourcesSection>
    </div>
  </div>
</template>

<script>
import SearchBar from '@/components/home/SearchBar.vue';
import UpcomingReservationCard from '@/components/cards/UpcomingReservationCard.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/api';
import ModelGrid from '@/components/home/ModelGrid.vue';
import CityGrid from '@/components/home/CityGrid.vue';
import PastReservationsRow from '@/components/home/PastReservationsRow.vue';
import CtaButton from '@/components/core/CtaButton.vue';
import ResourcesSection from '@/components/home/ResourcesSection.vue';
import SkeletonLoader from '@/components/core/animations/SkeletonLoader.vue';


export default {
  name: "HomeView",
  components: {
    SearchBar,
    UpcomingReservationCard,
    ModelGrid,
    CityGrid,
    PastReservationsRow,
    CtaButton,
    ResourcesSection,
    SkeletonLoader
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('app', ['userLocationAllowed', 'userLocation']),
    ...mapState('search', ['filters', 'cities']),
    ...mapGetters('reservations', ['upcomingReservations', 'pastReservations', 'currentReservation']),
    ...mapGetters('search', ['pickupDateString', 'returnDateString']),
    firstName() {
      if (!this.user) return '';
      return this.user.first_name ? `, ${this.user.first_name}` : '';
    },
  },
  data() {
    return {
      models: [],
      locations: [
        { name: 'Nashville', state: 'TN' },
        { name: 'St Louis', state: 'MO' },
        { name: 'Dallas', state: 'TX' },
        { name: 'Phoenix', state: 'AZ' },
        { name: 'Las Vegas', state: 'NV' },
        { name: 'Austin', state: 'TX' }
      ],
      isLoading: true
    };
  },
  watch: {
    userLocationAllowed() {
      console.log('User location allowed:', this.userLocationAllowed);
      if (this.userLocationAllowed) {
        this.setDefaultCityFilter(this.userLocation);
      }
    },
  },
  methods: {
    ...mapActions('reservations', ['getReservations']),
    ...mapActions('search', ['setDefaultCityFilter', 'setDefaultDateFilters', 'setCity', 'setPickupDate', 'setReturnDate', 'setPickupTime', 'setReturnTime','getCities']),
    handleSearch() {
      const { city, pickupDate, returnDate } = this.filters;

      if (city && pickupDate && returnDate) {
        this.$router.push({
          path: '/search',
          query: {
            city: `${city.name}, ${city.state}`,
            pickupDate: this.pickupDateString,
            returnDate: this.returnDateString,
            pickupTime: this.filters.pickupTime,
            returnTime: this.filters.returnTime,
          }
        });
      } else {
        console.error('Missing required filters for search.');
      }
    },
    handleViewDetails(reservation) {
      this.$router.push({
        name: 'trip',
        params: { platform_id: reservation.id },
      });
    },
    handleCityChange(city) {
      this.setCity(city);
    },
    handlePickupDateChange(date) {
      this.setPickupDate(date);
    },
    handleReturnDateChange(date) {
      this.setReturnDate(date);
    },
    handlePickupTimeChange(time) {
      this.setPickupTime(time);
    },
    handleReturnTimeChange(time) {
      this.setReturnTime(time);
    },
    async fetchCities() {
      await api.vehicleModels.getVehicleModels()
        .then((models) => {
          this.models = models;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async fetchVehicleModels() {
      Promise.resolve(this.getCities()).then(() => {
        this.isLoading = false;
      });

    }
  },
  async created() {
    this.getReservations();
    this.setDefaultDateFilters();
    this.setDefaultCityFilter();
    this.fetchCities()
    this.fetchVehicleModels()
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
