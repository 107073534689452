<template>
  <component :is="url ? 'a' : (to ? 'router-link' : 'button')" :href="url ? url : undefined" :to="to ? to : undefined"
    :class="buttonClass" :type="type" :disabled="isDisabled" @click="handleClick">
    <template v-if="isLoading">
      <LoadingAnimation class="!size-6" />
    </template>
    <template v-else>
      <!-- Render back arrow icon if leadingIcon prop is passed -->
      <ForwadArrowIcon v-if="leadingIcon" class="block size-6  rotate-180" />
      <slot name="leading"></slot>
      <div v-if="$slots.default" class="mt-0.5">
        <slot></slot>
      </div>
      <slot name="trailing"></slot>
      <!-- Render forward arrow icon if trailingIcon prop is passed -->
      <ForwadArrowIcon v-if="trailingIcon" class="block size-6" />
    </template>

  </component>
</template>

<script>
import { twMerge } from "tailwind-merge";
import LoadingAnimation from '@/components/core/animations/LoadingAnimation.vue';
import ForwadArrowIcon from "./icons/ForwardArrowIcon.vue";

export default {
  name: "CtaButton",
  components: {
    LoadingAnimation,
    ForwadArrowIcon
  },
  props: {
    type: {
      type: String,
      default: "button",
    },
    variant: {
      type: String,
      default: "primary", // primary, secondary, or tertiary
      validator(value) {
        return ["primary", "secondary", "tertiary"].includes(value);
      },
    },
    size: {
      type: String,
      default: "large", // large, small
      validator(value) {
        return ["large", "small"].includes(value);
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    leadingIcon: {
      type: Boolean,
      default: false,
    },
    trailingIcon: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
  },
  computed: {
    buttonClass() {
      const baseClasses =
        "rounded-lg font-semibold text-base transition duration-200 ease-in-out flex items-center justify-center gap-2.5 cursor-pointer";


      // Check if the button has no content (i.e., no default slot)
      const hasContent = !!this.$slots.default;

      const sizeClasses = {
        large:
          this.variant === "tertiary"
            ? "!gap-1 p-0"
            : this.variant === "secondary"
              ? hasContent
                ? "py-[15px] px-[23px]"
                : "p-4" // Adjusted padding for large when no content
              : hasContent
                ? "py-4 px-6"
                : "p-4",
        small:
          this.variant === "tertiary"
            ? "!gap-1 p-0"
            : this.variant === "secondary"
              ? hasContent
                ? "py-[9px] px-[19px]"
                : "-2.5"
              : hasContent
                ? "py-2.5 px-5"
                : "p-2.5",
      };
      const variantClasses = {
        primary:
          "text-white bg-primary-100 hover:bg-primary-300 active:bg-primary-400",
        secondary:
          "text-primary-200 bg-primary-900 border border-primary-700  hover:bg-primary-800 active:bg-primary-700",
        tertiary:
          "text-primary-400 hover:text-primary-200 active:text-primary-100",
      };

      const disabledClasses = {
        primary: "bg-primary-800 text-white",
        secondary: "bg-primary-900 text-primary-600",
        tertiary: "text-primary-600",
      };

      const loadingClass = this.isLoading ? "cursor-not-allowed pointer-events-none" : "";

      const finalVariantClasses = this.isDisabled
        ? twMerge(
          disabledClasses[this.variant],
          "cursor-not-allowed" // Common disabled styles
        )
        : variantClasses[this.variant];

      return twMerge(baseClasses, sizeClasses[this.size], loadingClass, finalVariantClasses);
    },
  },
  methods: {
    handleClick(event) {
      if (!this.isDisabled) {
        this.$emit('click', event);
      }
    },
  }
};
</script>
