<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="4" fill="#EDECEB" />
        <path d="M4.5 6H19.5" stroke="#44403C" stroke-width="1.5" stroke-linecap="round" />
        <path d="M4.5 12H19.5" stroke="#44403C" stroke-width="1.5" stroke-linecap="round" />
        <path d="M4.5 18H19.5" stroke="#44403C" stroke-width="1.5" stroke-linecap="round" />
    </svg>
</template>

<script>
export default {
    name: "HamburgerIcon",
};
</script>