<template>
  <div>
    <div v-for="addon in filteredAddons" :key="addon.id"
      class="flex justify-between items-center mb-4 p-4 rounded-lg"
      :class="{'border border-verde-50 bg-[#38BA841A] ':addon.type === 'DAMAGE_WAIVER'}"
      @click="toggleAddon(addon)">
      <div class="flex items-center gap-5">
        <ShieldGreenIcon v-if="addon.type === 'DAMAGE_WAIVER'" class="w-[29px] h-9"></ShieldGreenIcon>
        <div class="text-base leading-6.5 font-semibold  text-primary-100">{{ formatAddonType(addon.type) }}</div>
      </div>
      <div>
        <span class="ml-auto text-primary-300 font-medium text-base">${{ addon.amount / 100 }}/day</span>
        <input type="checkbox" :checked="selectedAddons.includes(addon.id)"
          class="form-checkbox h-5 w-5 text-primary-100 ml-2 rounded focus:ring-0" />
      </div>
    </div>
  </div>
</template>

<script>
import ShieldGreenIcon from '../core/icons/ShieldGreenIcon.vue';

export default {
  name: "AvailableAddons",
  components: {
    ShieldGreenIcon
  },
  props: {
    availableAddons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedAddons: [],
    };
  },
  computed: {
    filteredAddons() {
      // move DAMAGE_WAIVER addon to the end
      return [
        ...this.availableAddons.filter(addon => addon.type !== 'DAMAGE_WAIVER'),
        ...this.availableAddons.filter(addon => addon.type === 'DAMAGE_WAIVER'),
      ];
    },
  },
  methods: {
    formatInterval(interval) {
      // capitalize first letter and convert rest to lowercase
      return interval.charAt(0).toUpperCase() + interval.slice(1).toLowerCase();
    },
    toggleAddon(addon) {
      const index = this.selectedAddons.indexOf(addon.id);
      if (index === -1) {
        this.selectedAddons.push(addon.id);
      } else {
        this.selectedAddons.splice(index, 1);
      }
      this.$emit('update:addons', this.selectedAddons);
    },
    formatAddonType(type) {
      switch (type) {
        case 'DAMAGE_WAIVER':
          return 'Collision Protection';
        case 'UNLIMITED_MILES':
          return 'Unlimited Miles';
        default:
          return type;
      }
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
