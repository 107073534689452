const addonService = (api) => ({
  getAvailableAddons: async ({ vehicleVin, pickupAt, returnAt }) => {
    return api.request({
      method: 'post',
      url: '/api/v1/addons/related',
      data: { vehicleVin, pickupAt, returnAt }
    });
  },
});

export default addonService;
