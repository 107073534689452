<template>
    <div @click="handleClick">
        <component :is="url ? 'router-link' : 'div'" :to="url ? url : undefined" class="flex flex-col gap-3 lg:gap-4 cursor-pointer group overflow-hidden">
            <img :src="imgSrc" :alt="name" class="size-full max-h-52 h-28 md:h-52 object-cover rounded-xl">
            <div
                class="flex items-center justify-between text-resla-ebony-20 font-medium text-2xl leading-9 tracking-negative-2 pr-8 group-hover:pr-0 transition-all duration-200 ease-in-out">
                {{ name }}
                <ForwadArrowIcon class="size-6 opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out"></ForwadArrowIcon>
            </div>
        </component>
    </div>

</template>

<script>
import ForwadArrowIcon from './icons/ForwardArrowIcon.vue';

export default {
    name: "ModelLink",
    components: {
        ForwadArrowIcon
    },
    props: {
        imgSrc: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        url: {
            type: String,
        },
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    }
};
</script>