<template>
  <div id="stytch-container">
    <div id="stytch"></div>
    <div class="max-w-[400px] px-8 text-sm text-justify font-urbanist text-primary-400">
      *By providing your phone number, you expressly consent to receive marketing and promotional text messages from <a
        href="https://www.resla.com/" class="underline"> Resla.com</a>, including messages sent by an automatic
      telephone dialing system. Message and data rates may apply. Reply STOP to opt-out at any time. For more
      information, see our <a :href="`${landingBaseURL}/terms`" class="underline"> Terms</a> and <a
        :href="`${landingBaseURL}/policies`" class="underline">Privacy Policy</a>. The frequency of messages may vary.</div>
  </div>
</template>

<script>
import { StytchUIClient } from "@stytch/vanilla-js";
import { mapState } from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      landingBaseURL: process.env.VUE_APP_LANDING_ROOT_URL,
    };
  },
  computed: {
    ...mapState("auth", ["stytch_pk"]),
  },
  mounted: function () {
    const stytch = new StytchUIClient(this.stytch_pk);

    // Render prebuilt UI
    stytch.mountLogin({
      elementId: "#stytch",
      config: {
        products: ["otp"],
        otpOptions: {
          methods: ["sms"],
          expirationMinutes: 10,
        },
        sessionOptions: {
          sessionDurationMinutes: 60,
        },
      },
      styles: {
        fontFamily: "VisbyCF",
        container: {
          borderColor: "white",
        },
        colors: {
          primary: "#000",
        },
        inputs: {
          borderColor: "#fff",
        },
        buttons: {
          primary: {
            backgroundColor: "black",
            textColor: "white",
            borderColor: "black",
          },
        },
      },
    });
    // Hide the Stytch logo by directly accessing the element instead of relying on classes.
    const stytchContainer = document.getElementById("stytch-container");
    if (stytchContainer) {
      const stytchUiElement = stytchContainer.querySelector("stytch-ui");

      if (stytchUiElement && stytchUiElement.shadowRoot) {
        // access shadow DOM
        const stytchLogo = stytchUiElement.shadowRoot.querySelector('img[alt="Powered by Stytch"]');
        const style = document.createElement('style');
        style.textContent = `
    #phone-input {
      font-size: 18px !important;
      -webkit-text-size-adjust: 100%; /* Prevent Safari from adjusting text size */
      touch-action: manipulation; /* Disable zooming on input focus */
    }
  `;
        stytchUiElement.shadowRoot.appendChild(style);
        if (stytchLogo) {
          stytchLogo.style.display = "none";
        }
      }
    }
  },
};
</script>
<style>
#stytch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  /* Full viewport height */
}

#stytch {
  background-color: lightgray;
  /* Example background color */
}

.dTIkNW {
  border: 0 solid !important;
}
</style>
