const verificationService = (api) => ({
  createLicenseVerification: async (platformId) => {
    return api.request({
      method: 'post',
      url: '/api/v1/verifications/license',
      data: {
        platform_id: platformId,
      },
    });
  },
  createInsuranceVerification: async (platformId) => {
    return api.request({
      method: 'post',
      url: '/api/v1/verifications/insurance',
      data: {
        platform_id: platformId,
      },
    });
  },
});

export default verificationService;
