
<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
     <rect x="2.5" y="2.5" width="15" height="15" rx="2" fill="#636F7E" fill-opacity="0.16"/>
     <path fill-rule="evenodd" clip-rule="evenodd" d="M6.448 1.75L6.5 1.75H13.5L13.552 1.75C14.4505 1.74997 15.1997 1.74995 15.7945 1.82991C16.4223 1.91432 16.9891 2.09999 17.4445 2.55546C17.9 3.01093 18.0857 3.57773 18.1701 4.20552C18.2501 4.80029 18.25 5.54949 18.25 6.44795V6.44798V6.44801L18.25 6.5V7.5V12.5V13.5L18.25 13.552V13.552V13.5521C18.25 14.4505 18.2501 15.1997 18.1701 15.7945C18.0857 16.4223 17.9 16.9891 17.4445 17.4445C16.9891 17.9 16.4223 18.0857 15.7945 18.1701C15.1997 18.2501 14.4505 18.25 13.5521 18.25H13.552H13.552L13.5 18.25H6.5L6.44801 18.25H6.44798H6.44794C5.54949 18.25 4.80029 18.2501 4.20552 18.1701C3.57773 18.0857 3.01093 17.9 2.55546 17.4445C2.09999 16.9891 1.91432 16.4223 1.82991 15.7945C1.74995 15.1997 1.74997 14.4505 1.75 13.552L1.75 13.5V12.501C1.75 12.5007 1.75 12.5004 1.75 12.5C1.75 12.4997 1.75 12.4993 1.75 12.499V7.50105L1.75 7.5L1.75 7.49895V6.5L1.75 6.448C1.74997 5.54952 1.74995 4.8003 1.82991 4.20552C1.91432 3.57773 2.09999 3.01093 2.55546 2.55546C3.01093 2.09999 3.57773 1.91432 4.20552 1.82991C4.8003 1.74995 5.54952 1.74997 6.448 1.75ZM3.25 13.25V13.5C3.25 14.464 3.2516 15.1116 3.31654 15.5946C3.37858 16.0561 3.4858 16.2536 3.61612 16.3839C3.74644 16.5142 3.94393 16.6214 4.40539 16.6835C4.88843 16.7484 5.53599 16.75 6.5 16.75H13.5C14.464 16.75 15.1116 16.7484 15.5946 16.6835C16.0561 16.6214 16.2536 16.5142 16.3839 16.3839C16.5142 16.2536 16.6214 16.0561 16.6835 15.5946C16.7484 15.1116 16.75 14.464 16.75 13.5V13.25H3.25ZM16.75 11.75H3.25V8.25H16.75V11.75ZM16.75 6.5V6.75H3.25V6.5C3.25 5.53599 3.2516 4.88843 3.31654 4.40539C3.37858 3.94393 3.4858 3.74644 3.61612 3.61612C3.74644 3.4858 3.94393 3.37858 4.40539 3.31654C4.88843 3.2516 5.53599 3.25 6.5 3.25H13.5C14.464 3.25 15.1116 3.2516 15.5946 3.31654C16.0561 3.37858 16.2536 3.4858 16.3839 3.61612C16.5142 3.74644 16.6214 3.94393 16.6835 4.40539C16.7484 4.88843 16.75 5.53599 16.75 6.5Z" fill="currentColor"/>
     </svg>
 </template>
 
 <script>
 export default {
   name: "ListViewIcon",
 };
 </script>    