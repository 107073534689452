import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

// Main Views
import HomeView from "../views/main/HomeView.vue";
import ProfileView from "../views/main/ProfileView.vue";
import TripsView from "../views/main/TripsView.vue";

// Public Views
import SearchView from "../views/public/SearchView.vue";
import VehicleView from "../views/public/VehicleView.vue";

// Trip Views
import TripView from "../views/trip/TripView.vue";
import TripDamageWaiverView from "../views/trip/TripDamageWaiverView.vue";
import TripSignatureView from "../views/trip/TripSignatureView.vue";
import TripConfirmationView from "../views/trip/TripConfirmationView.vue";
import TripPaymentView from "../views/trip/TripPaymentView.vue";
import TripReceiptView from "../views/trip/TripReceiptView.vue";
import TripExtensionView from "../views/trip/TripExtensionView.vue";
import TripExtensionConfirmationView from "../views/trip/TripExtensionConfirmationView.vue";
import TripReturnVehicleView from "../views/trip/TripReturnVehicleView.vue";

// Onboarding Views
import OnboardingView from "../views/onboarding/OnboardingView.vue";
import GetStartedView from "../views/onboarding/GetStartedView.vue";
import InsuranceVerificationView from "../views/onboarding/InsuranceVerificationView.vue";
import LicenseVerificationView from "../views/onboarding/LicenseVerificationView.vue";

// Authentication Views
import LoginView from "../views/auth/LoginView.vue";
import LogoutView from "../views/auth/LogoutView.vue";

// Error Views
import PageNotFoundView from "../views/errors/PageNotFoundView.vue";
import TripLoadingCreateBookingView from "@/views/trip/TripLoadingCreateBookingView.vue";
import ReceiptView from "@/views/public/ReceiptView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      authenticated: true,
      navbar: "Default", // Default, Booking, Search
      footer: "Application", // Default, Booking, Search, Application, None
      body: "Default", // Default, Fullscreen
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      title: "Profile",
      authenticated: true,
      navbar: "Default",
      footer: "Default",
      body: "Default",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Get Started",
      authenticated: false,
      navbar: "Default",
      footer: "Default", // Default, Booking, Search, Application, None
      body: "Default",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
    meta: {
      title: "Logging out...",
      authenticated: false,
      navbar: "Default",
      footer: "Application",
      body: "Default",
    },
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
    meta: {
      title: "Search Vehicles",
      authenticated: false,
      navbar: "Search",
      footer: "None",
      body: "Fullscreen",
    },
  },
  {
    path: "/vehicle/:vin",
    name: "vehicle",
    component: VehicleView,
    meta: {
      title: "View Vehicle",
      authenticated: false,
      navbar: "Search",
      footer: "Search",
      body: "Default",
    },
  },
  {
    path: "/receipt",
    name: "admin-receipt",
    component: ReceiptView,
    meta: {
      title: "Receipt",
      body: "Default",
    },
  },
  {
    path: "/onboarding",
    component: OnboardingView,
    meta: {
      authenticated: true,
      navbar: "Booking",
      footer: "Booking",
      body: "Default",
    },
    children: [
      {
        path: "",
        name: "get-started",
        component: GetStartedView,
        meta: {
          title: "Get Started",
          authenticated: true,
          navbar: "Booking",
          footer: "Booking",
          body: "Default",
        },
      },
      {
        path: "insurance/verification",
        name: "insurance-verification",
        component: InsuranceVerificationView,
        meta: {
          title: "Insurance Verification",
          authenticated: true,
          navbar: "Booking",
          footer: "Booking",
          body: "Default",
        },
      },
      {
        path: "license/verification",
        name: "license-verification",
        component: LicenseVerificationView,
        meta: {
          title: "License Verification",
          authenticated: true,
          navbar: "Booking",
          footer: "Booking",
          body: "Default",
        },
      },
    ],
  },
  {
    path: "/trips",
    name: "trips",
    component: TripsView,
    meta: {
      title: "My Trips",
      authenticated: true,
      navbar: "Search",
      footer: "Application",
      body: "Default",
    },
  },
  {
    path: "/create-booking",
    name: "loading-create-booking",
    component: TripLoadingCreateBookingView,
    meta: {
      title: "Create Booking",
      authenticated: true,
      navbar: "Search",
      footer: "Booking",
      body: "Default",
    },
  },
  {
    path: "/trip/:platform_id",
    component: {
      template: "<router-view></router-view>",
    },
    name: "trip-root",
    children: [
      {
        path: "",
        name: "trip",
        component: TripView,
        meta: {
          title: "My Trip",
          authenticated: true,
          navbar: "Search",
          footer: "Booking",
          body: "Default",
        },
      },
      {
        path: "damage-waiver",
        name: "damage-waiver",
        component: TripDamageWaiverView,
        meta: {
          title: "Damage Waiver",
          authenticated: true,
          navbar: "Booking",
          footer: "Booking",
          body: "Default",
        },
      },
      {
        path: "signature",
        name: "signature",
        component: TripSignatureView,
        meta: {
          title: "Signature",
          authenticated: true,
          navbar: "Booking",
          footer: "Booking",
          body: "Default",
        },
      },
      {
        path: "pay",
        name: "payment",
        component: TripPaymentView,
        meta: {
          title: "Pay Now",
          authenticated: true,
          navbar: "Booking",
          footer: "Booking",
          body: "Default",
        },
      },
      {
        path: "confirmation",
        name: "confirmation",
        component: TripConfirmationView,
        meta: {
          title: "Trip Confirmed",
          authenticated: true,
          navbar: "Booking",
          footer: "Booking",
          body: "Default",
        },
      },
      {
        path: "receipt",
        name: "payment-receipt",
        component: TripReceiptView,
        meta: {
          title: "Receipt",
          authenticated: true,
          navbar: "Default",
          footer: "Application",
          body: "Default",
        },
      },
      {
        path: "/extension",
        name: "extension",
        component: TripExtensionView,
        meta: {
          title: "Request Extension",
          authenticated: true,
          navbar: "Default",
          footer: "Application",
          body: "Default",
        },
      },
      {
        path: "/extension/confirmed",
        name: "extension-confirmed",
        component: TripExtensionConfirmationView,
        meta: {
          title: "Extension Confirmed",
          authenticated: true,
          navbar: "Default",
          body: "Default",
        },
      },
      {
        path: "/return",
        name: "return-vehicle",
        component: TripReturnVehicleView,
        meta: {
          title: "Return Vehicle",
          authenticated: true,
          navbar: "Default",
          footer: "Application",
          body: "Default",
        },
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: PageNotFoundView,
    meta: {
      title: "Page Not Found",
      authenticated: false,
      navbar: "Default",
      footer: "Application",
      body: "Default",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(from);
  console.log(to);
  if (to.meta.authenticated == true) {
    const authenticated = store.getters["auth/authenticated"];
    if (authenticated == false) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    //Set Default Title
    var title = to.meta.title + " | Resla";

    //Set Vehicle Title
    if (to.name == "vehicle") {
      title = to.meta.title + " | " + to.params.vin + " | Resla";
    }

    //Set Title for Trip Pages
    if (to.matched.some((record) => record.name === "trip-root")) {
      if (to.params.platform_id) {
        title = to.meta.title + " | " + to.params.platform_id + " | Resla";
      }
    }
    //Set Document Title
    document.title = title;
  });
});

export default router;
