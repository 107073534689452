<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9 2.25H8.94513C7.57754 2.24998 6.47522 2.24996 5.60825 2.36652C4.70814 2.48754 3.95027 2.74643 3.34835 3.34835C2.74643 3.95027 2.48754 4.70814 2.36652 5.60825C2.24996 6.47522 2.24998 7.57754 2.25 8.94513V9V15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6517C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H9H15H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6517C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15V14C21.75 13.5858 21.4142 13.25 21 13.25C20.5858 13.25 20.25 13.5858 20.25 14V15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56458 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15V9C3.75 7.56458 3.75159 6.56347 3.85315 5.80812C3.9518 5.07435 4.13225 4.68577 4.40901 4.40901C4.68577 4.13225 5.07435 3.9518 5.80812 3.85315C6.56347 3.75159 7.56458 3.75 9 3.75H10C10.4142 3.75 10.75 3.41421 10.75 3C10.75 2.58579 10.4142 2.25 10 2.25H9ZM14.25 3C14.25 3.41421 14.5858 3.75 15 3.75H19.1893L11.4697 11.4697C11.1768 11.7626 11.1768 12.2374 11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303L20.25 4.81066V9C20.25 9.41421 20.5858 9.75 21 9.75C21.4142 9.75 21.75 9.41421 21.75 9V3C21.75 2.58579 21.4142 2.25 21 2.25H15C14.5858 2.25 14.25 2.58579 14.25 3Z"
      :fill="fill" />
  </svg>

</template>

<script>
export default {
  name: "ArrowUpRightIcon",
  props:{
    fill:{
      type:String,
      default:'#44403C'
    }
  }
};
</script>