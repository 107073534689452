<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.25 7C14.25 6.58579 14.5858 6.25 15 6.25H21C21.4142 6.25 21.75 6.58579 21.75 7V13C21.75 13.4142 21.4142 13.75 21 13.75C20.5858 13.75 20.25 13.4142 20.25 13V8.81066L15.5303 13.5303C15.5013 13.5593 15.4727 13.588 15.4445 13.6162C14.9844 14.0771 14.6282 14.4338 14.221 14.6356C13.4516 15.0169 12.5484 15.0169 11.779 14.6356C11.3718 14.4338 11.0156 14.0771 10.5555 13.6162C10.5273 13.588 10.4987 13.5593 10.4697 13.5303C9.88157 12.9422 9.71099 12.7857 9.55498 12.7084C9.20526 12.5351 8.79467 12.5351 8.44496 12.7084C8.28895 12.7857 8.11837 12.9422 7.53028 13.5303L3.53033 17.5303C3.23744 17.8232 2.76256 17.8232 2.46967 17.5303C2.17678 17.2374 2.17678 16.7625 2.46967 16.4696L6.46962 12.4697C6.49865 12.4406 6.52726 12.412 6.55547 12.3837C7.01556 11.9229 7.37172 11.5661 7.77895 11.3643C8.54832 10.9831 9.45162 10.9831 10.221 11.3643C10.6282 11.5661 10.9844 11.9229 11.4445 12.3837C11.4727 12.412 11.5013 12.4406 11.5303 12.4697C12.1184 13.0577 12.289 13.2143 12.445 13.2916C12.7947 13.4649 13.2053 13.4649 13.555 13.2916C13.711 13.2143 13.8816 13.0577 14.4697 12.4697L19.1893 7.75H15C14.5858 7.75 14.25 7.41421 14.25 7Z"
            fill="#44403C" />
    </svg>

</template>

<script>
export default {
    name: "TrendUpIcon",
};
</script>