const vehicleModelsService = (api) => ({
  getVehicleModels: async () => {
    return api.request({
      method: 'get',
      url: '/api/v1/vehicle_models'
    });
  },
});

export default vehicleModelsService;
