const userService = (api) => ({
  getUser: async () => {
    return api.request({
      method: 'get',
      url: '/api/v1/users'
    });
  },
});

export default userService;
