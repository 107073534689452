const reservationService = (api) => ({
  createReservation: async (reservation) => {
    return api.request({
      method: 'post',
      url: '/api/v1/reservations',
      data: reservation
    });
  },
  getReservations: async () => {
    return api.request({
      method: 'get',
      url: '/api/v1/reservations'
    });
  },
  applyAddon: async (platformId, addonIds) => {
    return api.request({
      method: 'patch',
      url: '/api/v1/reservations/addons',
      data: {
        platform_id: platformId,
        addon_ids: addonIds
      }
    });
  },
  getReservationWithToken: async (token) => {
    console.log(token);
    return api.request({
      method: 'get',
      url: `/api/admin/v1/transactions/receipts?token=${token}`
    });
  },
  checkConflicts: async ({
    vehicleVin,
    pickupAt,
    returnAt
  }) => {
    console.error({ vehicleVin, pickupAt, returnAt });
    return api.request({
      method: 'post',
      url: '/api/v1/reservations/check-conflicts',
      data: {
        vehicle_vin: vehicleVin,
        pickup_at: pickupAt,
        return_at: returnAt
      }
    });
  }
});

export default reservationService;
