<template>
  <div class="flex flex-col gap-5">
    <div class="flex items-center justify-between">
      <div>
        <p class="text-resla-ebony-1000 text-2xl tracking-negative-2 font-medium">{{ vehicle.model.name }}</p>
        <p class="text-sm">{{ vehicle.vin }}</p>
      </div>
      <div class="flex gap-2 items-center">
        <p class="font-urbanist font-semibold text-base text-primary-300">
          {{ vehicle.color }}
        </p>
        <div class="inline-block size-5 rounded-full" :class="gradientClass"></div>
      </div>

    </div>
    <img :src="getMoneyshotImage(vehicle.images)" alt="Vehicle Image" class="w-full h-auto rounded-lg" />    
    <div class="grid grid-cols-2 gap-4 text-start border-y border-primary-700 py-4 px-8">
      <div>
        <p class="text-xl font-medium">{{ vehicle.model.mile_range }}</p>
        <p class="text-lg text-primary-300" v-tooltip="{
            content: `The Environmental Protection Agency (EPA) rates electric vehicle range based on 55% highway and 45% city driving.`,
            maxWidth: 200,
            placement: 'top',
          }"> mi Range (EPA est.)</p>
      </div>
      <div>
        <p class="text-xl font-medium">{{ vehicle.model.drivetrain }}</p>
        <p class="text-lg text-primary-300"> Drivetrain</p>
      </div>
      <div>
        <p class="text-xl font-medium">{{ vehicle.model.zero_to_sixty_mph_seconds }}</p>
        <p class="text-lg text-primary-300"> 0-60mph</p>
      </div>
      <div>
        <p class="text-xl font-medium">{{ vehicle.model.top_speed_mph }}</p>
        <p class="text-lg text-primary-300"> Top Speed (mph)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VehicleMetrics',
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gradientClass() {
      return {
        red: 'bg-paint-red',
        blue: 'bg-paint-blue',
        black: 'bg-paint-black',
        white: 'bg-paint-pearl',
        grey: 'bg-paint-silver',
      }[this.vehicle.color.toLowerCase()] || 'bg-paint-red'; // Default to red if color is not provided
    }
  },
  methods:{
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')?.image_url;
    }
  }
};
</script>
