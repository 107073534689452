<template>
  <Modal
    id="vehicle-unavailable-modal"
    title="Vehicle Unavailable"
    class="!items-end md:!items-center" 
    :value="value"
    :content-class="'max-w-[560px]'"
    :show-header="false"
    :show-footer="false"
  >
    <div class="flex flex-col gap-y-5">
      <div class="flex items-center justify-between">
        <p class="text-primary-100 text-3xl leading-8 tracking-negative-3">Vehicle Unavailable</p>
        <button
            type="button"
            class="bg-transparent hover:bg-gray-200 text-gray-400 hover:text-gray-900 rounded-md text-sm size-6 ms-auto inline-flex justify-center items-center"
            @click="closeModal(false)"
            >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
              />
            </svg>
          </button>
      </div>
      <p class="font-urbanist font-normal text-lg leading-8 text-primary-100">We're sorry, but the vehicle you've selected is no longer available for your requested dates. Please modify your trip dates or search for other vehicles.</p>
       <div class="flex items-center justify-between gap-6">
            <CtaButton variant="secondary" class="w-full whitespace-nowrap" @click="closeModal(true)">Search Vehicles</CtaButton>
            <CtaButton variant="primary" class="w-full" @click="closeModal(false)">Modify Dates</CtaButton>
       </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../core/Modal.vue';
import CtaButton from '../core/CtaButton.vue';

export default {
  components: {
    Modal,
    CtaButton
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch:{
    value(newValue){
      if(newValue){
        this.$store.commit("activateOverlay");
      }
    }
  },
  methods:{
    closeModal(goBack = false){
      this.$emit("input", goBack);
      this.$store.commit("deactivateOverlay");
    },
  },
  mounted(){
    if(this.value){
      this.$store.commit("activateOverlay");
    }
  }
};
</script>

<style scoped>
/* Custom styling for the modal, if necessary */
</style>
