import { getCookie } from "@/utils";
import Pusher from "pusher-js";

const state = {
    userLocation: {
        latitude: null,
        longitude: null
    },
    userLocationAllowed: null,
    pusher: null
};

const mutations = {
    setUserLocation(state, { latitude, longitude }) {
        state.userLocation = { latitude, longitude };
    },
    setUserLocationAllowed(state, value) {
        state.userLocationAllowed = value;
    },
    setPusher(state, pusher) {
        state.pusher = pusher;
    }
};

const actions = {
    getUserLocation({ commit }) {
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition((position) => {
                commit("setUserLocation", {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
                commit("setUserLocationAllowed", true);
                resolve();
            }, () => {
                commit("setUserLocation", {
                    latitude: null,
                    longitude: null
                });
                commit("setUserLocationAllowed", false);
                resolve();
            });
        });
    },
    connectPusher({ commit }, {
        pusherPk,
        authRootUrl,
    }) {
        const pusher = new Pusher(pusherPk, {
            cluster: "us2",
            authEndpoint: authRootUrl + "/api/v1/users/pusher/auth",
            transport: "ajax",
            auth: {
                headers: {
                    stytch_auth: getCookie("stytch_session"),
                },
            },
        });
        pusher.connection.bind("connected", () => {
            console.log("Connected.");
        });
        pusher.connection.bind("connecting", () => {
            console.log("Connecting...");
        });
        pusher.connection.bind("disconnected", () => {
            console.log("Disconnected");
        });
        pusher.connection.bind("unavailable", () => {
            console.log("Unavailable");
        });
        commit("setPusher", pusher);
    },
    subscribeToPusherChannel({ state }, channel) {
        return state.pusher.subscribe(channel)
    },
    unsubscribeFromPusherChannel({ state }, channel) {
        state.pusher.unsubscribe(channel);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
