<template>
  <div class="border border-primary-700 rounded-lg overflow-hidden flex flex-col">
     <CtaButton  variant="secondary" class="w-full bg-white border-0 border-b border-primary-700 rounded-none !justify-start"  :is-disabled="!showEdit" @click="editReservation">
      <EditIcon slot="leading" class="size-6" :stroke="!showEdit ? '#9F9992': '#44403C'"/>
      Extend Reservation
     </CtaButton>
     <CtaButton  variant="secondary" class="w-full bg-white border-0 border-b border-primary-700 rounded-none !justify-start" :is-disabled="!showCancel"  @click="cancelReservation">
      <template #leading>
        <span class="block size-6 p-1 rounded-full bg-primary-800">
          <CloseIcon></CloseIcon>
        </span>
      </template>
      Cancel Reservation
     </CtaButton>
     <CtaButton variant="secondary" class="w-full bg-white border-0 rounded-none !justify-start"  @click="viewReceipt">
      <ArrowUpRight slot="leading" class="size-6" />
      View Receipt
     </CtaButton>
  </div>
</template>

<script>
import CtaButton from '../core/CtaButton.vue';
import ArrowUpRight from '../core/icons/ArrowUpRight.vue';
import CloseIcon from '../core/icons/CloseIcon.vue';
import EditIcon from '../core/icons/EditIcon.vue';

export default {
  name: 'ModifyReservation',
  components: {
    EditIcon,
    ArrowUpRight,
    CtaButton,
    CloseIcon
  },
  props: {
    showCancel: {
      type: Boolean,
      default: true,
    },
    showEdit:{
      type: Boolean,
      default: true,
    }
  },
  methods: {
    editReservation() {
      console.log("Edit Reservation clicked");
      // Placeholder function for editing the reservation
    },
    cancelReservation() {
      console.log("Cancel Reservation clicked");
      // Placeholder function for canceling the reservation
    },
    viewReceipt() {
      this.$emit('view-receipt');
    },
  },
};
</script>
