<template>
  <div class="space-y-4">
    <div v-for="(transactions, key) in transactionsGrouped" :key="key" class="font-urbanist text-base leading-6.5 flex flex-col gap-3">
      <template v-if="showGroupDate">
      <p>{{ formatDateToLocaleWithTimezone(key) }}:</p>

      <div class="w-full h-px bg-primary-800 my-2"></div>
      </template>

      <!-- Rack Transaction -->
      <div v-for="rack in rackTransactions(transactions)" :key="rack.id">
        <div class="flex justify-between">
          <span>{{ rack.description }}</span>
          <span>${{ formatPrice(rack.cost / 100) }}</span>
        </div>
      </div>

      <!-- Additional Transactions -->
      <div
        v-for="additional in additionalTransactions(transactions)"
        :key="additional.id"
      >
        <div class="flex justify-between">
          <span>{{ additional.description }}</span>
          <span>${{ formatPrice(additional.cost / 100) }}</span>
        </div>
      </div>

      <!-- Addon Transactions -->
      <div v-for="addon in addonTransactions(transactions)" :key="addon.id">
        <div class="flex justify-between">
          <span>{{ addon.description }}</span>
          <span>${{ formatPrice(addon.cost / 100) }}</span>
        </div>
      </div>

      <!-- Tax Transactions -->
      <div v-for="tax in taxTransactions(transactions)" :key="tax.id">
        <div class="flex justify-between">
          <span>{{ tax.description }}</span>
          <span>${{ formatPrice(tax.cost / 100) }}</span>
        </div>
      </div>

      <div v-if="groupDiscount(transactions) > 0" class="flex justify-between">
        <Badge size="large" class="flex gap-1 items-center">Total Discount <TagIcon class="size-4"></TagIcon>
        </Badge>
        <span class="font-urbanist font-semibold text-base leading-6.5 text-verde-40">
          - ${{ formatPrice(groupDiscount(transactions) / 100) }}
        </span>
      </div>

      <!-- Divider -->
      <div class="w-full h-px bg-primary-800 my-4"></div>

      <!-- Total Price -->
      <div class="flex justify-between font-semibold">
        <span>Total</span>
        <span>${{ formatPrice(totalPrice(transactions) / 100) }}</span>
      </div>

      <div class="flex justify-between">
        <span>Security Deposit</span>
        <span>$175.00</span>
      </div>
      <!-- Divider -->
      <div class="w-full h-px bg-primary-800 my-4"></div>
    </div>
  </div>
</template>

<script>
import { formatDateToLocale } from '@/helpers/util';
import Badge from '@/components/core/Badge';
import TagIcon from '@/components/core/icons/TagIcon.vue';

export default {
  name: 'ReservationLineItems',
  components: {
    Badge,
    TagIcon
  },
  props: {
    reservation: {
      type: Object,
      required: true
    },
    statuses: {
      type: Array,
      required: true,
      default: () => []
    },
    showGroupDate:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      transactionsGrouped: {
        ...this.reservation.transactions.reduce((acc, item) => {
          const key = item.initiated_at;
          if (!this.statuses.includes(item.status)) {
            return acc;
          }
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {})
      }
    };
  },
  methods: {
    rackTransactions(transactions) {
      return transactions.filter(
        (transaction) =>
          transaction.category === 'Rack' &&
          this.statuses.includes(transaction.status)
      );
    },
    addonTransactions(transactions) {
      return transactions.filter(
        (transaction) =>
          transaction.category === 'Addon' &&
          this.statuses.includes(transaction.status)
      );
    },
    additionalTransactions(transactions) {
      return transactions.filter(
        (transaction) =>
          transaction.category === 'Additional' &&
          this.statuses.includes(transaction.status)
      );
    },
    taxTransactions(transactions) {
      return transactions.filter(
        (transaction) =>
          transaction.category === 'Tax' &&
          transaction.captured_amount > 0 &&
          this.statuses.includes(transaction.status)
      );
    },
    groupDiscount(transactions) {
      const totalDiscount = transactions.reduce((sum, transaction) => {
        return sum + (transaction.discount_amount || 0);
      }, 0);
      return totalDiscount;
    },
    totalPrice(transactions) {
      return transactions.reduce(
        (acc, transaction) =>
          transaction.category === 'Deposit'
            ? acc
            : acc + transaction.captured_amount,
        0
      );
    },
    totalPriceLabel(transactions) {
      const pendingTransactions = transactions.every(
        (transaction) => transaction.status === 'Pending'
      );
      return pendingTransactions ? 'Total (Pending)' : 'Total';
    },
    formatPrice(price) {
      return price % 1 === 0 ? price : price.toFixed(2);
    },
    formatDateToLocaleWithTimezone(date) {
      return formatDateToLocale(date, this.reservation.timezone);
    }
  }
};
</script>
