<template>
  <div class="flex flex-col w-full mx-auto max-w-content md:px-0 px-6 py-16 md:py-24 gap-y-8 md:gap-y-6">
    <!-- Heading -->
    <div class="text-left md:text-center">
      <h1 class="text-heading font-medium tracking-negative-3 text-primary-100">Your Signature</h1>
      <p class="mt-2 text-xl leading-8 text-primary-500 font-urbanist">Sign the box(s) below to finalize your booking
      </p>
    </div>
    <!-- Collision Protection Signature Text Field -->
    <div v-if="!pageIsLoading" class="flex flex-col w-full gap-y-8 md:gap-y-6">
      <div v-if="reservationNeedsDamageWaiverSignature">
        <div class="flex justify-between items-center mb-1">
          <h4 class="text-2xl leading-9 tracking-negative-2 text-primary-100 font-medium">Collision Protection</h4>
          <CtaButton variant="tertiary" trailing-icon @click="handleViewDamageWaiver" >View</CtaButton>
        </div>
        <TextInput id="damageWaiver" label="Sign Here" is-rounded is-border
          v-model="damageWaiverSignature" :is-disabled="firstAndLastNameKnown"></TextInput>
      </div>
      <!-- Rental Agreement Signature Text Field -->
      <div>
        <div class="flex justify-between items-center mb-1">
          <h4 class="text-2xl leading-9 tracking-negative-2 text-primary-100 font-medium">Rental Agreement</h4>
          <CtaButton variant="tertiary" trailing-icon @click="handleViewRentalAgreement" >View</CtaButton>
        </div>
        <TextInput id="rentalAgreement" label="Sign Here" is-rounded is-border
          v-model="rentalAgreementSignature" :is-disabled="firstAndLastNameKnown"></TextInput>
      </div>
      <!-- Agreement Checkboxes -->
      <div class="flex flex-col gap-3">
        <div class="flex items-start gap-3">
          <input type="checkbox" v-model="agree1"
            class="form-checkbox rounded h-5 w-5 text-primary-100 mt-1 focus:ring-0">
          <p class="font-urbanist text-xs leading-[19.2px] text-primary-300">
            I confirm that I understand the owner does not extend any of its motor vehicle financial responsibility or
            provide public liability insurance coverage to the renter, authorized drivers, or any other drivers.
          </p>
        </div>
        <div class="flex items-start gap-3">
          <input type="checkbox" v-model="agree2"
            class="form-checkbox rounded h-5 w-5 text-primary-100 mt-1 focus:ring-0">
          <p class="font-urbanist text-xs leading-[19.2px] text-primary-300">
            I confirm that I have read the rental agreement in its entirety and accept the
            <a :href="`${landingBaseURL}/privacy`" target="_blank" class="underline">Privacy Policy</a>,
            <a :href="`${landingBaseURL}/terms`" target="_blank" class="underline">Terms of Use</a>,
            <a :href="`${landingBaseURL}/disclaimers`" target="_blank" class="underline">Disclaimers</a>, and
            <a :href="`${landingBaseURL}/policies`" target="_blank" class="underline">General Policies</a> governing the use of your
            website and services.
            I understand that a lack of signature does not constitute evidence of non-acceptance of the Terms of Use. By
            using your website and services, I agree to be bound by the Terms of Use, whether or not I have signed.
            I agree that the above digital signature has the same legal force and effect as a handwritten signature.
          </p>
        </div>
      </div>
      <!-- Navigation Buttons -->
      <div class="flex justify-between items-start gap-x-4 w-full">
        <CtaButton class="w-full md:w-max" variant="secondary" leading-icon @click="goBack">Back</CtaButton>
        <CtaButton class="w-full md:w-max min-w-28" trailing-icon :is-disabled="!allFieldsFilled" :is-loading="confirmationIsLoading"   @click="goNext">
          Next
        </CtaButton>
      </div>
    </div>
    <div v-else>
      <div class="grid flex-1 gap-1">
        <SkeletonLoader width="100%" height="40px"></SkeletonLoader>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapState } from "vuex";


import CtaButton from "@/components/core/CtaButton.vue";
import TextInput from "@/components/core/TextInput.vue";
import { EventBus } from '@/utils/eventBus';
import SkeletonLoader from "@/components/core/animations/SkeletonLoader.vue";
import api from '@/api';


export default {
  name: "TripSignatureView",
  components: {
    CtaButton,
    TextInput,
    SkeletonLoader,
  },
  data() {
    return {
      damageWaiverSignature: "",
      rentalAgreementSignature: "",
      agree1: false,
      agree2: false,
      firstAndLastNameKnown: false,
      confirmationIsLoading: false,
      pageIsLoading: false,
      landingBaseURL:process.env.VUE_APP_LANDING_ROOT_URL,
      damageWaiverPricePerDay: null,
      damageWaiverTotalPrice: null,
    };
  },
  methods: {
    ...mapActions("reservations", ["getReservations", "createRentalAgreement", 'createDamageWaiverAgreement']),
    goBack() {
      // Navigate back
      this.$router.go(-1);
    },
    async goNext() {
      if(this.allFieldsFilled){
        try {
        this.confirmationIsLoading = true
        if (this.reservationNeedsDamageWaiverSignature) {
          await this.createDamageWaiverAgreement({
            platform_id: this.$route.params.platform_id,
            signature: this.damageWaiverSignature,
            date: new Date(),
          });


        }
        await this.createRentalAgreement({
          platform_id: this.$route.params.platform_id,
          signature: this.rentalAgreementSignature,
          date: new Date(),
        });
        this.$router.push({
          name: 'payment', params: {
            platform_id: this.$route.params.platform_id
          }
        });
      }
      catch (error) {
        console.error('error in create rental agreement:', error);
      }
      finally {
        this.confirmationIsLoading = false
      }
      } 
    },
    calculateRentalDays() {
      const pickup = new Date(this.reservation.pickup_at);
      const returnD = new Date(this.reservation.return_at);
      const diffTime = Math.abs(returnD - pickup);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    handleViewDamageWaiver() {
      const state = this.reservation.pickup_location.state;
      window.open(`${this.landingBaseURL}/waiver?state=${state}&price_per_day=${this.damageWaiverPricePerDay}&total_price=${this.damageWaiverTotalPrice}`);
    },
    handleViewRentalAgreement() {
      window.open(`${this.landingBaseURL}/rental-agreement`);
    },
  },
  computed: {
    ...mapGetters('reservations', ['reservations']),
    ...mapState('auth', ['user']),
    reservation() {
      return this.reservations.find(reservation => reservation.id === this.$route.params.platform_id);
    },
    reservationNeedsDamageWaiverSignature() {
      const reservationHasDamageWaiver = this.reservation?.transactions.some(transaction => transaction.addon?.type === 'DAMAGE_WAIVER');
      const reservationDamageWaiverSigned = this.reservation?.agreements.some(agreement => agreement.type === 'DAMAGE_WAIVER');
      return reservationHasDamageWaiver && !reservationDamageWaiverSigned;
    },
    reservationNeedsRentalAgreementSignature() {
      return !this.reservation?.agreements.some(agreement => agreement.type === 'RENTAL_AGREEMENT');
    },
    allFieldsFilled() {
      if (this.reservationNeedsDamageWaiverSignature) {
        return this.damageWaiverSignature && this.rentalAgreementSignature && this.agree1 && this.agree2 && this.agree1 === this.agree2;
      }
      return this.rentalAgreementSignature && this.agree1 && this.agree2 && this.agree1 === this.agree2;
    },
  },
  mounted(){
    EventBus.$on('checkSignature', this.goNext);
  },
  async created() {
    this.pageIsLoading = true;
    await this.getReservations();
    if (this.reservation?.status !== 'Started') {
      this.$router.push({ name: 'trip', params: { platform_id: this.$route.params.platform_id } });
    }
    if (!(this.reservationNeedsRentalAgreementSignature || this.reservationNeedsDamageWaiverSignature)) {
      this.$router.push({ name: 'payment', params: { platform_id: this.$route.params.platform_id } });
    }
    if (this.user.first_name && this.user.last_name) {
      this.rentalAgreementSignature = `${this.user?.first_name} ${this.user?.last_name}`;
      this.damageWaiverSignature = `${this.user?.first_name} ${this.user?.last_name}`;
      this.firstAndLastNameKnown = true;
    }
    const availableAddons = await api.addons.getAvailableAddons({
      vehicleVin: this.reservation.vehicle.vin,
      pickupAt: this.reservation.pickup_at,
      returnAt: this.reservation.return_at
    });
    const damage_waiver = availableAddons.filter(
      (addon) => addon.type === 'DAMAGE_WAIVER'
    );

    if (damage_waiver.length !== 1) {
      throw new Error('Expected exactly one damage waiver addon');
    }
    this.damageWaiverAddonId = damage_waiver[0].id;
    this.damageWaiverPricePerDay = damage_waiver[0].amount;
    this.damageWaiverTotalPrice = damage_waiver[0].amount * this.calculateRentalDays();
    this.pageIsLoading = false;
  },
  beforeDestroy() {
    EventBus.$off('checkSignature', this.goNext);
  },
}
</script>


<style scoped>
/* Additional styles if needed */
</style>

