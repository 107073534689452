<template>
  <div class="max-w-3xl mx-auto px-6 text-start">
    <CtaButton variant="tertiary" leading-icon @click="() => $router.push('/trip/' + $route.params.platform_id)">My Trip</CtaButton>
    <div class="flex justify-start mb-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-16 w-16 text-green-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
    <h2 class="text-3xl mb-4">You're all caught up!</h2>
    <p class="text-gray-600 mb-4">
      You have no outstanding balance on your account
    </p>
  </div>
</template>

<script>
import CtaButton from '../core/CtaButton.vue';

export default {
  name: "NoOutstandingBalance",
  components: {
    CtaButton,
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
