<template>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_67_123)">
            <path
                d="M17.9525 5.41492C18.3334 6.89992 18.3334 9.99992 18.3334 9.99992C18.3334 9.99992 18.3334 13.0999 17.9525 14.5849C17.7409 15.4058 17.1217 16.0516 16.3375 16.2699C14.9134 16.6666 10 16.6666 10 16.6666C10 16.6666 5.08919 16.6666 3.66252 16.2699C2.87502 16.0483 2.25669 15.4033 2.04752 14.5849C1.66669 13.0999 1.66669 9.99992 1.66669 9.99992C1.66669 9.99992 1.66669 6.89992 2.04752 5.41492C2.25919 4.59409 2.87835 3.94825 3.66252 3.72992C5.08919 3.33325 10 3.33325 10 3.33325C10 3.33325 14.9134 3.33325 16.3375 3.72992C17.125 3.95159 17.7434 4.59659 17.9525 5.41492ZM8.33335 12.9166L13.3334 9.99992L8.33335 7.08325V12.9166Z"
                fill="#44403C" />
        </g>
        <defs>
            <clipPath id="clip0_67_123">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "YoutubeIcon",
};
</script>