<template>
  <router-link :to="'/vehicle/' + vin" class="flex flex-col gap-3 group rounded-lg ">
    <img :src="image" :alt="model" class="w-full h-auto rounded-xl" />
    <div class="flex justify-between items-center">
      <h3 class="text-xl font-medium tracking-negative-2 text-primary-100">{{ model }}</h3>
      <div class="relative flex items-center">
        <div class="group-hover:-translate-x-6 transition-all duration-200 ease-in-out flex space-x-2">
          <p class="text-base font-medium text-primary-300">
            ${{ paymentBalance / 100 }}
          </p>
          <Badge size="small">${{ rackRate / 100 }}/day</Badge>
        </div>
        <!-- Animated arrow icon -->
        <div
          class="size-6 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-2 transition-all duration-200 ease-in-out absolute right-1">
          <ForwadArrowIcon></ForwadArrowIcon>
        </div>
      </div>

    </div>
  </router-link>
</template>


<script>
import Badge from '@/components/core/Badge.vue';
import ForwadArrowIcon from '../core/icons/ForwardArrowIcon.vue';

export default {
  name: 'VehicleCard',
  components: {
    Badge,
    ForwadArrowIcon
  },
  props: {
    vin: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    image: {
      type: String,
    },
    rackRate: {
      type: Number,
      required: true,
    },
    paymentBalance: {
      type: Number,
      required: true,
    },
  },
  computed: {
    reservation() {
      return this.vehicle.reservations[0];
    },
  },
};
</script>
