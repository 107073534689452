<template>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_67_128)">
            <path
                d="M5.78335 4.16677C5.78312 4.6088 5.60732 5.03263 5.2946 5.34504C4.98188 5.65744 4.55787 5.83282 4.11585 5.8326C3.67382 5.83238 3.24998 5.65657 2.93758 5.34386C2.62517 5.03114 2.44979 4.60713 2.45001 4.1651C2.45023 3.72307 2.62604 3.29924 2.93876 2.98683C3.25147 2.67443 3.67548 2.49905 4.11751 2.49927C4.55954 2.49949 4.98338 2.6753 5.29578 2.98801C5.60818 3.30073 5.78357 3.72474 5.78335 4.16677ZM5.83335 7.06677H2.50001V17.5001H5.83335V7.06677ZM11.1 7.06677H7.78335V17.5001H11.0667V12.0251C11.0667 8.9751 15.0417 8.69177 15.0417 12.0251V17.5001H18.3333V10.8918C18.3333 5.7501 12.45 5.94177 11.0667 8.46677L11.1 7.06677Z"
                fill="#44403C" />
        </g>
        <defs>
            <clipPath id="clip0_67_128">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "LinkedinIcon",
};
</script>