<template>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="7.5" fill="#E5E3E0" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM10.8333 13.3333C10.8333 13.7936 10.4602 14.1667 10 14.1667C9.53976 14.1667 9.16667 13.7936 9.16667 13.3333C9.16667 12.8731 9.53976 12.5 10 12.5C10.4602 12.5 10.8333 12.8731 10.8333 13.3333ZM8.95833 8.33333C8.95833 7.75804 9.4247 7.29167 10 7.29167C10.5753 7.29167 11.0417 7.75804 11.0417 8.33333V8.43443C11.0417 8.73751 10.9213 9.02818 10.707 9.24249L9.55806 10.3914C9.31398 10.6355 9.31398 11.0312 9.55806 11.2753C9.80214 11.5194 10.1979 11.5194 10.4419 11.2753L11.5908 10.1264C12.0396 9.67764 12.2917 9.06903 12.2917 8.43443V8.33333C12.2917 7.06768 11.2657 6.04167 10 6.04167C8.73435 6.04167 7.70833 7.06768 7.70833 8.33333V8.75C7.70833 9.09518 7.98815 9.375 8.33333 9.375C8.67851 9.375 8.95833 9.09518 8.95833 8.75V8.33333Z"
            fill="#54504C" />
    </svg>
</template>
<script>
export default {
    name: "InfoIcon",
}
</script>