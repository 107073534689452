<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
            fill="#E5E3E0" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM8.24993 9.74347C8.24993 8.3664 9.36627 7.25006 10.7433 7.25006H11.25V6.50006C11.25 6.08585 11.5858 5.75006 12 5.75006C12.4142 5.75006 12.75 6.08585 12.75 6.50006V7.25006H12.9999C14.5187 7.25006 15.7499 8.48127 15.7499 10.0001C15.7499 10.4143 15.4141 10.7501 14.9999 10.7501C14.5857 10.7501 14.2499 10.4143 14.2499 10.0001C14.2499 9.3097 13.6903 8.75006 12.9999 8.75006H12.75V11.4595L14.045 11.8912C15.0632 12.2306 15.7499 13.1834 15.7499 14.2566C15.7499 15.6337 14.6336 16.7501 13.2565 16.7501H12.75V17.5001C12.75 17.9143 12.4142 18.2501 12 18.2501C11.5858 18.2501 11.25 17.9143 11.25 17.5001V16.7501H10.9999C9.48115 16.7501 8.24993 15.5188 8.24993 14.0001C8.24993 13.5858 8.58572 13.2501 8.99993 13.2501C9.41415 13.2501 9.74993 13.5858 9.74993 14.0001C9.74993 14.6904 10.3096 15.2501 10.9999 15.2501H11.25V12.5406L9.95486 12.1089C8.93669 11.7695 8.24993 10.8167 8.24993 9.74347ZM11.25 10.9595V8.75006H10.7433C10.1947 8.75006 9.74993 9.19482 9.74993 9.74347C9.74993 10.1711 10.0235 10.5507 10.4292 10.6859L11.25 10.9595ZM12.75 13.0406V15.2501H13.2565C13.8052 15.2501 14.2499 14.8053 14.2499 14.2566C14.2499 13.829 13.9763 13.4494 13.5707 13.3142L12.75 13.0406Z"
            fill="#44403C" />
    </svg>

</template>

<script>
export default {
    name: "DollarCircleIcon",
};
</script>