<template>
  <div class="max-w-7xl mx-auto pb-20">
    <div class="bg-white p-6">
      <h1 class="text-6xl mb-10">My Trips</h1>
      <template v-if="upcomingReservations.length > 0">
        <h2 class="text-3xl mb-4 text-resla-ebony-20">Upcoming Reservations</h2>
        <div v-for="reservation in upcomingReservations" :key="reservation.id">
        <UpcomingReservationCard
          :orientation="'horizontal'"
          :reservation="reservation"
          @view-details="handleViewDetails"
          class="mb-4"
        />
      </div>
      </template>
      <template v-if="pastReservations.length > 0">
      <h2 class=" text-3xl mb-4 text-resla-ebony-20">Past Trips</h2>
      <PastReservationsList :pastReservations="pastReservations" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UpcomingReservationCard from '@/components/cards/UpcomingReservationCard.vue';
import PastReservationsList from '@/components/my-trips/PastReservationsList.vue';

export default {
  name: "TripsView",
  components: {
    UpcomingReservationCard,
    PastReservationsList,
  },
  computed: {
    ...mapGetters('reservations', ['upcomingReservations', 'pastReservations']),
  },
  methods: {
    ...mapActions('reservations', ['getReservations']),
    handleViewDetails(reservation) {
      this.$router.push({
        name: 'trip',
        params: { platform_id: reservation.id },
      });
    },
  },
  async created() {
    this.getReservations();
  },
};
</script>
