import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  bind(el, binding) {
    const defaultOptions = {
      allowHTML: true,
      theme: 'light',
      placement: 'top',
    };
    // merge default options with passed options
    const options = {
      ...defaultOptions,
      ...binding.value,
    };

    tippy(el, options);
  },
  unbind(el) {
    if (el._tippy) el._tippy.destroy();
  },
};
