<template>
  <Modal
    v-model="isVisible"
    :show-header="false"
    :show-footer="false"
    :show-close-button="false"
  >
    <iframe :src="ignitionUri" class="ignition" ref="axleIframe"></iframe>
  </Modal>
</template>

<script>
import api from '@/api';
import Modal from '../core/Modal.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AxleModal',
  components: {
    Modal,
  },
  data() {
    return {
      isVisible: true,
      ignitionUri: null,
    };
  },
  async created() {
    // Subscribe to Axle events
    const channelName = `private-axle-events-${this.user.stytch_user_id}`;
    const channel = await this.subscribeToPusherChannel(channelName);
    channel.bind('axle:ignition.completed', () => {
      this.getReservations();
      this.$emit('close');
    });
    channel.bind('axle:ignition.failed', () => this.$emit('close'));
    channel.bind('axle:ignition.error', () => this.$emit('close'));

    const response = await api.verifcations.createInsuranceVerification(
      this.$route.params.platform_id,
    );
    const origin = window.location.origin;
    this.ignitionUri = `${response.ignition_uri}&origin=${origin}`;
  },
  async beforeDestroy() {
    const channelName = `private-axle-events-${this.user.stytch_user_id}`;
    this.unsubscribeFromPusherChannel(channelName);
  },
  methods: {
    ...mapActions('app', [
      'subscribeToPusherChannel',
      'unsubscribeFromPusherChannel',
    ]),
    ...mapActions('reservations', ['getReservations']),
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    value(newVal) {
      this.isVisible = newVal;
    },
    isVisible(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>

<style scoped>
.ignition {
  z-index: 999;
  /* Bring modal to front of page */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: none;
}
</style>
