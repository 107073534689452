const paymentService = (api) => ({
  createPayment: async (payment) => {
    return api.request({
      method: 'post',
      url: '/api/v1/payments',
      data: payment
    });
  },
});

export default paymentService;
