const agreementService = (api) => ({
    createAgreement: async ({ type, platform_id, signature, date }) => {
      return api.request({
        method: 'post',
        url: '/api/v1/agreements',
        data: { type, platform_id, signature, date }
      });
    },
    getAgreementSignedURL: async (id) => {
      return api.request({
        method: 'get',
        url: `/api/v1/agreements/${id}/signed_url`
      });
    },
  });
  
  export default agreementService;
  