<template>
  <div class="space-y-4">
    <div v-for="rack in rackTransactions" :key="rack.id"
      class="flex justify-between">
      <span class="font-urbanist font-normal text-base leading-6.5 text-primary-100">{{
        rack.description.charAt(0).toUpperCase() +
        rack.description.slice(1)
        }}</span>
      <div class="flex items-center gap-16">
        <p v-if="rack.category === 'Rack' && rack.number_of_days"
          class="font-urbanist font-normal text-sm text-primary-100">
          ${{
            formatPrice(rack.cost / rack.number_of_days / 100)
          }}
          x {{ rack.number_of_days }} days
        </p>
        <span class="font-urbanist font-semibold text-base leading-6.5 text-primary-100">${{
          formatPrice(rack.cost / 100) }}</span>
      </div>
    </div>
    <div v-for="(addon, key) in groupedAddons" :key="key"
      class="flex justify-between">
      <span class="font-urbanist font-normal text-base leading-6.5 text-primary-100">{{
        addon.description.charAt(0).toUpperCase() +
        addon.description.slice(1)
        }}</span>
      <div class="flex items-center gap-16">
        <p v-if="addon.category === 'Addon' && addon.number_of_days"
          class="font-urbanist font-normal text-sm text-primary-100">
          ${{
            formatPrice(addon.cost / addon.number_of_days / 100)
          }}
          x {{ addon.number_of_days }} days
        </p>
        <span class="font-urbanist font-semibold text-base leading-6.5 text-primary-100">${{
          formatPrice(addon.cost / 100) }}</span>
      </div>
    </div>
    <div v-for="additional in additionalTransactions" :key="additional.id"
      class="flex justify-between">
      <span class="font-urbanist font-normal text-base leading-6.5 text-primary-100">{{
        additional.description.charAt(0).toUpperCase() +
        additional.description.slice(1)
        }}</span>
      <div class="flex items-center gap-16">
        <span class="font-urbanist font-semibold text-base leading-6.5 text-primary-100">${{
          formatPrice(additional.cost / 100) }}</span>
      </div>
    </div>
    <div v-for="(tax, key) in groupedTaxes" :key="key"
      class="flex justify-between">
      <span class="font-urbanist font-normal text-base leading-6.5 text-primary-100">Taxes</span>
      <div class="flex items-center gap-16">
        <span class="font-urbanist font-semibold text-base leading-6.5 text-primary-100">${{
          formatPrice(tax.cost / 100) }}</span>
      </div>
    </div>

    <!--total discount-->
    <div v-if="groupDiscount() > 0" class="flex justify-between">
      <Badge size="large" class="flex gap-1 items-center">Total Discount <TagIcon class="size-4"></TagIcon>
      </Badge>
      <span class="font-urbanist font-semibold text-base leading-6.5 text-verde-40">
        - ${{ formatPrice(groupDiscount() / 100) }}
      </span>
    </div>
    <!--total-->
    <div class="flex w-full justify-between pt-4 pb-0 mt-2 border-t border-primary-700">
      <p class="font-urbanist font-normal text-base leading-6.5 text-primary-100">
        Total
      </p>

      <p class="font-urbanist font-semibold text-base leading-6.5 text-primary-100">
        ${{ formatPrice(groupTotal() / 100) }}
      </p>
    </div>
    <!--security deposit-->
    <div v-if="securityDepositTransactions.length > 0" class="flex w-full justify-between pt-0 pb-4 mt-2 border-b border-primary-700">
      <p class="font-urbanist font-normal text-base leading-6.5 text-primary-100">
        Security Deposit
      </p>

      <p class="font-urbanist font-semibold text-base leading-6.5 text-primary-100">
        ${{ formatPrice(groupDeposit() / 100) }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatDateToLocale } from '@/helpers/util';
import Badge from '@/components/core/Badge';
import TagIcon from '@/components/core/icons/TagIcon.vue';
export default {
  name: 'TripLineItems',
  components: {
    Badge,
    TagIcon
  },
  props: {
    reservation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rackTransactions: this.reservation.transactions.filter(transaction => transaction.category === 'Rack'),
      addonTransactions: this.reservation.transactions.filter(transaction => transaction.category === 'Addon'),
      additionalTransactions: this.reservation.transactions.filter(transaction => transaction.category === 'Additional'),
      taxTransactions: this.reservation.transactions.filter(transaction => transaction.category === 'Tax'),
      securityDepositTransactions: this.reservation.transactions.filter(transaction => transaction.category === 'Deposit'),
    };
  },
  computed: {
    groupedAddons() {
      const addonsGrouped = this.addonTransactions.reduce((acc, item) => {
        const key = item.description.split(' ').join('-').toUpperCase();

        if (!acc[key]) {
          acc[key] = {...item};
        } else {
          acc[key] = {
            ...item,
            cost: acc[key].cost + item.cost,
            number_of_days: acc[key].number_of_days + item.number_of_days
          };
        }
        
        return acc;
      }, {});
      return addonsGrouped;
    },
    groupedTaxes() {
      const taxesGrouped = this.taxTransactions.reduce((acc, item) => {
        const key = 'Taxes';

        if (!acc[key]) {
          acc[key] = {...item};
        } else {
          acc[key] = {
            ...item,
            cost: acc[key].cost + item.cost,
            number_of_days: acc[key].number_of_days + item.number_of_days
          };
        }
        
        return acc;
      }, {});
      return taxesGrouped;
    },
  },
  methods: {
    groupTotal() {
      const totalCaptured = this.reservation.transactions.reduce(
        (acc, transaction) =>
          transaction.category === 'Deposit' ? acc : acc + transaction.captured_amount,
        0
      );
      return totalCaptured;
    },
    groupDiscount() {
      const totalDiscount = this.reservation.transactions.reduce((sum, transaction) => {
        return sum + (transaction.discount_amount || 0);
      }, 0);
      return totalDiscount;
    },
    groupDeposit() {
      const totalDeposit = this.securityDepositTransactions.reduce((sum, transaction) => {
        return sum + (transaction.captured_amount || 0);
      }, 0);
      return totalDeposit;
    },
    formatPrice(price) {
      return price % 1 === 0 ? price : price.toFixed(2);
    },
    formatDateToLocaleWithTimezone(date) {
      return formatDateToLocale(date, this.reservation.timezone);
    }
  }
};
</script>
