export const getCookie = (name) => {
  // Construct the name to be searched for in the cookie string
  const nameEQ = name + "=";
  // Split the cookie string by semicolons (each cookie is separated by a semicolon)
  const ca = document.cookie.split(";");

  // Loop through the array of cookies
  for (let i = 0; i < ca.length; i++) {
    // Get each cookie and remove leading spaces
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    // Check if the current cookie starts with the name we're looking for
    if (c.indexOf(nameEQ) == 0) {
      // Return the value of the cookie (excluding the name and the equals sign)
      return c.substring(nameEQ.length, c.length);
    }
  }
  // Return null if the cookie is not found
  return null;
};

export const convertToTimezone = (date, timezone) => {
  // Format the date in the given timezone
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });

  const parts = formatter.formatToParts(date);
  const formattedDate = `${parts[4].value}-${parts[0].value}-${parts[2].value}T${parts[6].value}:${parts[8].value}:${parts[10].value}`;

  return new Date(formattedDate + 'Z'); // Convert back to a Date object
};

export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatTimeToHHMM = (date) => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};