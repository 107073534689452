<template>
  <div class="flex md:grid grid-cols-1 md:grid-cols-2 gap-0 border border-primary-700 rounded-xl overflow-auto md:overflow-hidden custom-scroll">
    <router-link
      v-for="location in locations"
      :key="location.name"
      class="flex flex-col items-center gap-2 hover:bg-primary-900 min-w-[310px] md:min-w-full border-r md:border-b border-resla-ebony-70 p-10 cursor-pointer text-resla-ebony-20 "
      :to="`/search?city=${location.name}, ${location.state}`"
    >
      <MapPinIcon class="w-6 h-6 text-gray-500" />
      <h3 class="text-xl tracking-negative-2 text-primary-200 font-medium">{{ location.name }}</h3>
      <p class="font-urbanist text-sm text-primary-200 font-normal">{{ location.state }}</p>
    </router-link>
  </div>
</template>

<script>
import MapPinIcon from '../core/icons/MapPinIcon.vue';

export default {
  name: "LocationGrid",
  props: {
    locations: {
      type: Array,
      required: true,
    },
  },
  components: {
    MapPinIcon,
  },
};
</script>

<style scoped>
/* Hide scrollbar in WebKit browsers (Chrome, Safari) */
.custom-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar in Firefox */
.custom-scroll {
  scrollbar-width: none;
}

/* Keep overflow functionality */
.custom-scroll {
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
}
</style>
