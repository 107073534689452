<template>
  <div class="past-reservations-row">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 overflow-auto md:overflow-hidden">
      <div v-for="reservation in limitedReservations" :key="reservation.id" class="bg-white overflow-hidden group cursor-pointer" @click="handlePastReservation(reservation)">
        <img :src="getMoneyshotImage(reservation.vehicle.images)" alt="Car Image" class="w-full h-48 object-cover rounded-lg" />
        <div class="flex justify-between pt-3 items-center">
          <div>
            <h3 class="text-xl font-medium text-primary-100">{{ reservation.vehicle.model.name}}</h3>
            <p class="text-primary-300 text-base leading-6.5">{{ pickupLocation(reservation) }}</p>
          </div>
          <div class="flex items-center relative">
            <div class="flex items-center text-primary-300 group-hover:-translate-x-6 transition-all duration-200 ease-in-out">
              <span class="text-2xl font-medium">
                ${{ reservation.vehicle.rack_rate / 100 }}
              </span>
              <span class="font-urbanist text-base font-normal">/day</span>
          </div>
          <ForwardArrowIcon class="size-6 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-2 transition-all duration-200 ease-in-out absolute right-1"></ForwardArrowIcon>


          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ForwardArrowIcon from '../core/icons/ForwardArrowIcon.vue';
export default {
  name: 'PastReservationsRow',
  components:{
    ForwardArrowIcon
  },
  props: {
    pastReservations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    limitedReservations() {
      return this.pastReservations.slice(0, 3);
    },
  },
  methods:{
    pickupLocation(reservation){
      if(reservation.pickup_location?.city && reservation.pickup_location?.state)
      return ` ${reservation.pickup_location?.city}, ${reservation.pickup_location?.state} ` 
    },
    handlePastReservation(reservation){
      const modelName = reservation.vehicle.model.name;
      const city = reservation.pickup_location?.city;
      const state = reservation.pickup_location?.state;
      const route = `/search?city=${city}, ${state}&model=${modelName}`;
      this.$router.push(route)
    },
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')?.image_url;
    }    
  }
};
</script>


<style scoped>
/* Add additional scoped styles here if necessary */
</style>

