<template>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="7.5" fill="#E0F3EB" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C13.797 3.125 16.875 6.20304 16.875 10C16.875 13.797 13.797 16.875 10 16.875C6.20304 16.875 3.125 13.797 3.125 10ZM10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875ZM13.7753 7.94194C14.0194 7.69786 14.0194 7.30214 13.7753 7.05806C13.5312 6.81398 13.1355 6.81398 12.8914 7.05806L9.16667 10.7828L7.94194 9.55806C7.69786 9.31398 7.30214 9.31398 7.05806 9.55806C6.81398 9.80214 6.81398 10.1979 7.05806 10.4419L8.72472 12.1086C8.84194 12.2258 9.00091 12.2917 9.16667 12.2917C9.33243 12.2917 9.4914 12.2258 9.60861 12.1086L13.7753 7.94194Z"
            fill="#2D966A" />
    </svg>
</template>
<script>
export default {
    name: "GreenTickCircleIcon",
}
</script>