const discountService = (api) => ({
    applyDiscount: async ({ code, platformId }) => {
      return api.request({
        method: 'post',
        url: '/api/v1/discounts/apply',
        data: {
            code,
            platform_id: platformId
         }
      });
    },
  });
  
  export default discountService;
  