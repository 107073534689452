<template>
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11ZM3 19C3 16.7909 4.79086 15 7 15H13C15.2091 15 17 16.7909 17 19C17 20.1046 16.1046 21 15 21H5C3.89543 21 3 20.1046 3 19Z"
            fill="#E5E3E0" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.75 7C6.75 5.20507 8.20507 3.75 10 3.75C11.7949 3.75 13.25 5.20507 13.25 7C13.25 8.79493 11.7949 10.25 10 10.25C8.20507 10.25 6.75 8.79493 6.75 7ZM10 2.25C7.37665 2.25 5.25 4.37665 5.25 7C5.25 9.62335 7.37665 11.75 10 11.75C12.6234 11.75 14.75 9.62335 14.75 7C14.75 4.37665 12.6234 2.25 10 2.25ZM20.75 5C20.75 4.58579 20.4142 4.25 20 4.25C19.5858 4.25 19.25 4.58579 19.25 5V10C19.25 10.4142 19.5858 10.75 20 10.75C20.4142 10.75 20.75 10.4142 20.75 10V5ZM7 15.75C5.20507 15.75 3.75 17.2051 3.75 19C3.75 19.6904 4.30964 20.25 5 20.25H15C15.6904 20.25 16.25 19.6904 16.25 19C16.25 17.2051 14.7949 15.75 13 15.75H7ZM2.25 19C2.25 16.3766 4.37665 14.25 7 14.25H13C15.6234 14.25 17.75 16.3766 17.75 19C17.75 20.5188 16.5188 21.75 15 21.75H5C3.48122 21.75 2.25 20.5188 2.25 19ZM20 14C20.5523 14 21 13.5523 21 13C21 12.4477 20.5523 12 20 12C19.4477 12 19 12.4477 19 13C19 13.5523 19.4477 14 20 14Z"
            fill="#44403C" />
    </svg>
    
</template>

<script>
export default {
    name: "UserAlertIcon",
};
</script>