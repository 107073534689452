const locationService = (api) => ({
  getClosestCities: async ({
    latitude = null,
    longitude = null
  } = {}) => {
    return api.request({
      method: 'get',
      url: '/api/v1/locations/cities',
      params: { latitude, longitude }
    });
  },
});

export default locationService;