<template>
  <div id="app">
    <NavSection></NavSection>
    <div :class="{
      'container mx-auto border-r border-l border-primary-700':
        $route.meta.body == 'Default',
    }">
      <router-view></router-view>
    </div>
    <FooterSection></FooterSection>
  </div>
</template>
<script>
import Pusher from "pusher-js";

import { initFlowbite } from "flowbite";
import { StytchUIClient } from "@stytch/vanilla-js";

import NavSection from "./components/navbars/NavSection.vue";
import FooterSection from "./components/footers/FooterSection.vue";
import { mapState, mapActions } from "vuex";

Pusher.logToConsole = true;

export default {
  name: "App",
  components: {
    NavSection,
    FooterSection,
  },
  computed: {
    ...mapState("auth", ["user", "stytch_pk"]),
    ...mapState("app", ["pusher"]),
  },
  mounted: async function () {
    //Init Flowbite
    initFlowbite();

    const stytch = new StytchUIClient(this.stytch_pk);

    // Stytch Auth Logic
    const session = stytch.session.getSync();
    if (session !== null) {
      await this.getUser();
      this.connectAndSubscribeToGlobalPusherEvents();
    }

    stytch.session.onChange(async (session) => {
      console.log("Session found: ", session);

      if (session == null) {
        this.logout({ router: this.$router });
      } else {
        await this.setAuthenticated();
        this.redirectIfLoggedIn();
        await this.getUser();
        this.connectAndSubscribeToGlobalPusherEvents();
      }
    });

    stytch.user.onChange((user) => {
      console.log("User Change: ", user);
    });
  },
  methods: {
    ...mapActions("auth", ["logout", "setAuthenticated", "getUser"]),
    ...mapActions("app", ["connectPusher", "subscribeToPusherChannel"]),
    async connectAndSubscribeToGlobalPusherEvents() {
      // Set Pusher Connection Bindings
      this.connectPusher({
        pusherPk: this.$store.state.pusher_pk,
        authRootUrl: this.$store.state.root_url,
      });

      // Subscribe to Pusher Channel
      const channelName = `private-${this.user.stytch_user_id}`;

      const channel = await this.subscribeToPusherChannel(channelName);
      channel.bind("REFRESH", (data) => {
        console.log(data);
        location.reload(true);
      });
    },
    redirectIfLoggedIn: function () {
      const redirect = this.$route.query.redirect || null;
      console.log(redirect);

      if (redirect != null) {
        const queryParams = { ...this.$route.query };
        this.$router.push({
          path: redirect,
          query: queryParams,
        });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 100;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Thin.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 300;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Light.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 400;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Regular.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 500;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Medium.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 700;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Bold.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 600;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-DemiBold.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 800;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: normal;
  font-weight: 900;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 100;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-ThinOblique.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 300;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-LightOblique.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 400;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-RegularOblique.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 500;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-MediumOblique.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 700;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-BoldOblique.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 600;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-DemiBoldOblique.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 800;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-ExtraBoldOblique.otf") format("opentype");
}

@font-face {
  font-family: "VisbyCF";
  font-style: oblique;
  font-weight: 900;
  src: url("https://public-resla-assets.s3.amazonaws.com/VisbyCF-HeavyOblique.otf") format("opentype");
}

body {
  font-family: "VisbyCF", serif !important;
}

.tippy-box[data-theme~='light'] {
  border-radius: 8px;
  padding: 6px;
  background-color: #110F0C;
  color: white;
  font-family: 'Urbanist'
}

.tippy-box[data-theme~='light'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: #110F0C;
}

.tippy-box[data-theme~='light'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: #110F0C;
}

.tippy-box[data-theme~='light'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: #110F0C;
}

.tippy-box[data-theme~='light'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: #110F0C;
}
</style>
