<template>
  <div
    class="h-auto flex flex-col lg:flex-row items-center align-middle bg-resla-ebony-90 rounded-lg border border-resla-ebony-70 ">
    <!-- Location Input -->
    <div
      class="flex-1 w-full lg:w-auto border-b lg:border-b-0 lg:border-r border-resla-ebony-70 h-16 flex items-center">
      <Autocomplete class="w-full" rounded id="location" label="Location" :compact="false" :options="cityOptions"
        :value="filters.city" @input="setCity" value-key="name">
        <MapPinIcon slot="icon" />
      </Autocomplete>
    </div>
    <!-- Date Input -->
    <div
      class="flex-1 w-full lg:w-auto border-b lg:border-b-0 lg:border-r border-resla-ebony-70 h-16 flex items-center">
      <DatepickerModal id="search-rangepicker-input" label="Dates" :date-value="{
        startDate: filters.pickupDate,
        endDate: filters.returnDate,
      }" :time-value="{
          startTime: filters.pickupTime,
          endTime: filters.returnTime,
        }" @apply="setDateTime"/>
    </div>
    <!-- Search Button -->
    <div class=" py-2.5 lg:py-0 px-2.5 bg-resla-ebony-90 w-full lg:w-max rounded-b-lg lg:rounded-none">
      <CtaButton trailing-icon size="small" class="hidden lg:block"
        :is-disabled="!filters.pickupDate || !filters.returnDate || !filters.city" @click="searchTrips"></CtaButton>
      <CtaButton trailing-icon size="small" class="block lg:hidden w-full"
        :is-disabled="!filters.pickupDate || !filters.returnDate || !filters.city" @click="searchTrips">View
        Vehicles</CtaButton>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import Autocomplete from '../core/Autocomplete.vue';
import CtaButton from '../core/CtaButton.vue';
import MapPinIcon from '../core/icons/MapPinIcon.vue';
import DatepickerModal from '../core/DatepickerModal.vue';

export default {
  name: "SearchBar",
  components: {
    Autocomplete,
    MapPinIcon,
    DatepickerModal,
    CtaButton
  },
  computed: {
    ...mapState('search', ['filters', 'cities']),
    ...mapState('app', ['userLocation']),
    cityOptions() {
      return this.cities.map((city, index) => ({
        id: index,
        label: `${city.name}, ${city.state}`,
        value: city,
      }));
    },
    minReturnDate() {
      if (!this.filters.pickupDate) return new Date();
      const minDate = new Date(this.filters.pickupDate);
      minDate.setDate(minDate.getDate() + 1);
      return minDate;
    }
  },
  methods: {
    searchTrips() {
      this.$emit('search', this.search);
    },
    setCity(city) {
      this.$emit('update:city', city);
    },
    setDates({ startDate, endDate }) {
      this.$emit("update:pickupDate", startDate);
      this.$emit("update:returnDate", endDate);
    },
    setTimes({ startTime, endTime }) {
      this.$emit("update:pickupTime", startTime);
      this.$emit("update:returnTime", endTime);
    },
    setDateTime({ dateValue, timeValue }) {
      this.setDates(dateValue)
      this.setTimes(timeValue)
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
