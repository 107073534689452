<template>
    <div class="relative">
        <div class="flex items-center gap-2 cursor-pointer" @click="toggleDropdown">
            <div class="bg-primary-800 flex flex-1 items-center justify-center rounded-full size-7 p-1">
                <UserIcon></UserIcon>
            </div>
            <p v-if="userName" class="font-urbanist font-semibold text-base text-primary-400">{{ userName }}</p>
        </div>
        <div v-show="isOpen" :class="mergedDropDownClass">
            <div @click="handleTrips"
                class="flex py-3 px-4 text-base leading-6.5 font-urbanist font-normal text-primary-300 hover:bg-primary-900 cursor-pointer">
                My Trips
            </div>
            <!-- <div
                class="flex py-3 px-4 text-base leading-6.5 font-urbanist font-normal text-primary-300 hover:bg-primary-900 cursor-pointer">
                My Account
            </div> -->
            <div @click="handleLogout"
                class="flex py-3 px-4 text-base leading-6.5 font-urbanist font-normal text-primary-300 hover:bg-primary-900 cursor-pointer">
                Log Out
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { twMerge } from "tailwind-merge";
import UserIcon from "../core/icons/UserIcon.vue";

export default {
    name: "UserDropdown",
    components: {
        UserIcon
    },
    props: {
        dropdownClass: {
            type: String,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        userName() {
            if (!this.user) return '';
            return this.user.first_name ? this.user.first_name : '';
        },
        mergedDropDownClass() {
            return twMerge(
                "absolute z-50 w-max right-0 top-[62px] bg-primary-1000 rounded-xl border border-primary-700 p-2 gap-2 flex flex-col",
                this.dropdownClass
            );
        },
    },
    watch: {
        isOpen(newVal) {
            if (newVal) {
                document.addEventListener("click", this.onClickOutside);
            } else {
                document.removeEventListener("click", this.onClickOutside);
            }
        },
    },
    methods: {
        ...mapActions("auth", ["logout"]),
        toggleDropdown() {
            this.isOpen = !this.isOpen;
            setTimeout(() => {
                this.$store.commit(
                    this.isOpen ? "activateOverlay" : "deactivateOverlay"
                );
            }, 100);
        },
        closeDropdown() {
            this.isOpen = false;
            if (this.$store.state.isOverlayActive) {
                this.$store.commit("deactivateOverlay");
            }
        },
        onClickOutside(event) {
            if (this.$el && !this.$el.contains(event.target)) {
                event.stopPropagation();
                this.closeDropdown();
            }
        },
        handleLogout() {
            this.closeDropdown()
            this.logout({router:this.$router});
        },
        handleTrips() {
            this.$router.push('/trips');
            this.closeDropdown()
        }
    },
    beforeDestroy() {
        document.removeEventListener("click", this.onClickOutside);
    },
};
</script>