<template>
    <div class="flex flex-col gap-10 items-center justify-center w-full mt-20 mb-20">
        <!--Heading-->
        <div class="flex gap-6 items-center w-full justify-between">
            <div class="font-medium text-heading leading-10 text-resla-ebony-1000 tracking-negative-3">
                Resources</div>
            <div class="hidden lg:block border-l border-resla-ebony-80 pl-6">
                <CtaButton variant="tertiary" trailing-icon :url="`${landingBaseURL}/resources`" target="_blank">
                    More
                </CtaButton>
            </div>

        </div>
        <!--content-->
        <div class="flex w-full flex-col lg:flex-row  gap-6">
            <div class="flex flex-col gap-4 w-full cursor-pointer" @click="handleResourceClick">
                <img src="@/assets/img/models/model_3/1.png" class="w-full cursor-pointer rounded-xl object-cover" />

                <div class="flex gap-1 items-center">
                    <div class="w-full font-medium text-2xl leading-9 text-resla-ebony-1000 tracking-negative-2">
                        How do I find superchargers?</div>
                    <CtaButton variant="tertiary">
                        <ExternalLinkIcon class="size-6"></ExternalLinkIcon>
                    </CtaButton>
                </div>
            </div>
            <div class="flex flex-col gap-6 w-full">
                <div class="order-last lg:!order-first flex flex-col lg:flex-row w-full">
                    <img src="https://cdn.buttercms.com/hbzQ4Fd4RUSrWk4s5sU1" alt="blog thumbnail" class="max-h-52 rounded-xl object-cover">
                    <div class="flex flex-col justify-between py-4 px-0 lg:!px-10 h-full">
                        <div class="flex flex-col gap-1">
                            <p class="font-urbanist font-normal text-base leading-6 text-relsa-ebony-1000">Mon Apr 29
                                2024</p>
                            <p class="font-medium text-2xl leading-9 text-relsa-ebony-1000">Experience the Tesla
                                Reengineered Model 3</p>
                        </div>
                        <CtaButton variant="tertiary" trailing-icon :url="`${landingBaseURL}/blog/reengineered-model-3-tesla`" target="_blank" class="w-max">
                            Read
                        </CtaButton>
                    </div>

                </div>
                <div class="border border-resla-ebony-70 rounded-xl overflow-hidden">
                    <FaqCard v-for="(faq, index) in faqs" :key="index" :question="faq.question" :answer="faq.answer"
                        :url="`${landingBaseURL}/faqs`" target="_blank">
                    </FaqCard>
                </div>
            </div>
            <div class="block lg:hidden border-t border-resla-ebony-80 pt-6">
                <CtaButton variant="tertiary" trailing-icon :url="`${landingBaseURL}/resources`" target="_blank">
                    More
                </CtaButton>
            </div>
        </div>

    </div>
</template>
<script>
import CtaButton from '@/components/core/CtaButton.vue'
import FaqCard from '@/components/core/FaqCard.vue'
import ExternalLinkIcon from '../core/icons/ExternalLinkIcon.vue';

export default {
    name: "ResourcesSection",
    components: {
        CtaButton,
        FaqCard,
        ExternalLinkIcon
    },
    data() {
        return {
            landingBaseURL:process.env.VUE_APP_LANDING_ROOT_URL,
            faqs: [
                {
                    question: 'How old do you have to be to rent a Resla?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
                {
                    question: 'What fees does Resla charge?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
                {
                    question: 'Is auto insurance required to rent with Resla?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
                {
                    question: 'Does the damage waiver cover damage to other vehicles?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
                {
                    question: 'Do I need a physical key?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
                {
                    question: 'Is there a limit on the number of miles I drive?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
                {
                    question: 'Does the damage waiver cover damage to other vehicles?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }
            ]

        }
    },
    methods:{
        handleResourceClick(){
            window.open(`${this.landingBaseURL}/tutorials`)
        }
    }
};
</script>