import api from "@/api";
import { StytchUIClient } from "@stytch/vanilla-js";

const state = {
    authenticated: localStorage.getItem("authenticated") || false,
    loading: false,
    stytch_pk: process.env.VUE_APP_STYTCH_PK,
    user: null,
};

const mutations = {
    startLoading(state) {
        state.loading = true;
    },
    stopLoading(state) {
        state.loading = false;
    },
    setAuthenticated(state) {
        state.authenticated = true;
        localStorage.setItem("authenticated", true);
    },
    unsetAuthenticated(state) {
        state.authenticated = false;
        localStorage.removeItem("authenticated");
    },
    setUser(state, user) {
        state.user = user;
    },
    setStytch(state, stytch) {
        state.stytch = stytch;
    }
};


const actions = {
    setAuthenticated({ commit }) {
        commit("setAuthenticated");
    },
    async getUser({ commit }) {
        await api.user.getUser().then((response) => {
            console.log({response});
            commit("setUser", response);
        });
    },
    // this.router is undefined here so pass router
    logout({ commit, state },{router}) {
        const stytch = new StytchUIClient(state.stytch_pk);
        stytch.session.revoke();
        commit("unsetAuthenticated");
        commit("setUser", { id: "" });
        router.push("/search");
    }
};

const getters = {
    loading: (state) => state.loading,
    authenticated: (state) => state.authenticated,
    user: (state) => state.user,
    stytch: (state) => state.stytch
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
