<template>
  <div class="sticky top-0 z-50">
    <nav class="bg-white border-b border-primary-700 px-4 ">
      <div class="md:h-16 py-4 md:py-0 md:px-3 flex flex-row flex-wrap justify-between items-center mx-auto">
        <!-- Logo and Website Title -->
        <div class="flex items-center md:w-max md:justify-start justify-between" :class="{'w-full':$route.meta.navbar == 'Booking'}">
          <div class="flex gap-2 items-center">
            <CtaButton v-if="isBackArrow" variant="tertiary" class="flex md:!hidden" @click="goBack()">
              <template>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5303 5.46967C11.8232 5.76256 11.8232 6.23744 11.5303 6.53033L6.81066 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H6.81066L11.5303 17.4697C11.8232 17.7626 11.8232 18.2374 11.5303 18.5303C11.2374 18.8232 10.7626 18.8232 10.4697 18.5303L4.46967 12.5303C4.17678 12.2374 4.17678 11.7626 4.46967 11.4697L10.4697 5.46967C10.7626 5.17678 11.2374 5.17678 11.5303 5.46967Z" fill="#22282F"/>
                </svg>
              </template>
            </CtaButton>
            
              
            <router-link :to="isAuthenticated ? '/' : '/search'" tag="a"><img src="@/assets/img/resla-logo-black-rgb-600px@300ppi.jpg" class="h-4 mr-3"
                alt="Logo" />
            </router-link>
          </div>
          

           <!-- Selected Model mobile -->
        <div class="flex md:hidden items-center space-x-3 "
        v-if="$route.meta.navbar == 'Booking' && reservation">
        <img :src="getMoneyshotImage(reservation.vehicle.images)" alt="Model 3" class="w-12 h-12 object-cover rounded" />
        <div>
          <h2 class="text-base leading-6.5 font-medium text-primary-300">Book</h2>
          <p class="font-urbanist font-normal text-sm text-primary-300">{{ reservation.vehicle.model.name }}</p>
        </div>
      </div>

          <span class="md:block hidden text-xl font-semibold" v-if="$route.meta.navbar == 'Booking'">
            <span class="w-px h-full bg-primary-800 mr-2"></span>
            <span>Book</span>
          </span>
        </div>
        <!-- Search Bar -->
        <NavSearch v-if="$route.meta.navbar == 'Search'" class="flex order-last md:order-none mt-4 md:mt-0" @search="handleSearch"
          @update:city="handleCityChange" @update:pickupDate="handlePickupDateChange"
          @update:returnDate="handleReturnDateChange" @update:pickupTime="handlePickupTimeChange"
          @update:returnTime="handleReturnTimeChange" />





        <!-- Booking Steps -->
        <div class="flex space-x-6 items-center justify-center w-full md:w-auto order-last md:order-none mt-4 md:mt-0" v-if="$route.meta.navbar == 'Booking'">
          <p v-if="previousBookingStep" @click="handleRouteNavigation(previousBookingStep.name)" class="cursor-pointer font-urbanist font-normal text-base leading-6.5 bg-[linear-gradient(89.78deg,#0205080D_18%,#02050866_40%)] bg-clip-text text-transparent hover:text-primary-600 transition-all duration-300"
            >{{ previousBookingStep.label }}</p>
          <p v-if="currentBookingStep" class="cursor-pointer font-urbanist font-normal text-base leading-6.5 text-primary-100">{{ currentBookingStep }}</p>
          <p v-if="nextBookingStep" @click="handleRouteNavigation(nextBookingStep.name)" class="cursor-pointer font-urbanist font-normal text-base leading-6.5 bg-[linear-gradient(268deg,#0205080D_18%,#02050866_40%)] bg-clip-text text-transparent hover:text-primary-600 transition-all duration-300"
            >{{ nextBookingStep.label }}</p>
        </div>





        <!-- Authentication -->
        <div class="flex items-center space-x-6" v-if="
          $route.meta.navbar == 'Default' || $route.meta.navbar == 'Search'
        ">
        <!--Search-->
        <CtaButton v-if="!isAuthenticated && $route.name === 'login'" to="/search" variant="tertiary">Search</CtaButton>
        <CtaButton v-if="!isAuthenticated && $route.name !== 'login'" to="/login" variant="tertiary" class="flex md:hidden">Sign In</CtaButton>
          <!--Models-->
          <div class="flex items-center space-x-6 ">
            <NavModels class="!static" :dropdown-class="'w-full md:w-[96%]'" :models="models">
              <template #button>
                <CtaButton variant="tertiary" class="flex md:hidden">
                  <HamburgerIcon class="size-6"></HamburgerIcon>
                </CtaButton>
                <CtaButton variant="tertiary" class="hidden md:flex">Models</CtaButton>
              </template>
            </NavModels>
            <UserDropdown v-if="isAuthenticated" class="hidden md:flex " />
          </div>
          <CtaButton v-if="!isAuthenticated && $route.name !== 'login'" to="/login" variant="tertiary" class="hidden md:flex">Sign In</CtaButton>
        </div>




        <!-- Selected Model -->
        <div class="hidden md:flex items-center space-x-3"
          v-if="$route.meta.navbar == 'Booking' && reservation">
          <img :src="getMoneyshotImage(reservation.vehicle.images)" alt="Model 3" class="w-12 h-12 object-cover rounded" />
          <div>
            <h2 class="text-base leading-6.5 font-medium text-primary-300">{{ reservation.vehicle.model.name }}</h2>
            <div class="flex items-center space-x-2">
              <span class="font-urbanist font-normal text-sm text-primary-300">${{ totalPrice / 100 }}</span>
              <Badge size="small">${{ reservation.vehicle.rack_rate / 100 }}/day</Badge>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Background overlay -->
     <div v-if="isOverlayActive" class="absolute w-full bg-[#110F0C] bg-opacity-0 z-10 backdrop-blur-[6px]" style="height: calc(100vh - 84px);">
    </div>
  </div>
</template>




<script>
import { mapActions, mapState, mapGetters } from "vuex";
import api from '@/api';
import { EventBus } from '@/utils/eventBus';

import NavSearch from "./NavSearch.vue";
import UserDropdown from "./UserDropdown.vue";
import NavModels from "./NavModels.vue";
import CtaButton from '@/components/core/CtaButton.vue'
import Badge from '@/components/core/Badge.vue'
import HamburgerIcon from "../core/icons/HamburgerIcon.vue";




export default {
  name: "NavBookingSection",
  components: {
    NavSearch,
    UserDropdown,
    NavModels,
    CtaButton,
    Badge,
    HamburgerIcon
  },
  data() {
    return {
      models: [],
      bookingSteps: [
        {
          name: "damage-waiver",
          label: "Waivers",
        },
        {
          name: "signature",
          label: "Signature",
        },
        {
          name: "payment",
          label: "Review & Pay",
        },
      ]
    };
  },
  async created() {
    this.setDefaultDateFilters();
    this.setDefaultCityFilter();
    if (this.user) {
      this.getReservations();
    }
    api.vehicleModels.getVehicleModels().then((models) => {
      this.models = models;
    });
  },
  computed: {
    ...mapState(['isOverlayActive']),
    ...mapState("auth", ["authenticated", "user"]),
    ...mapState('search', ['filters', 'cities']),
    ...mapGetters('reservations', ['reservations']),



    isAuthenticated() {
      return this.authenticated;
    },
    userName() {
      if (!this.user) return '';
      return this.user.first_name ? this.user.first_name : '';
    },
    currentBookingStep() {
      return this.bookingSteps.find(step => step.name === this.$route.name)?.label || '';
    },
    previousBookingStep() {
      const currentIndex = this.bookingSteps.findIndex(step => step.name === this.$route.name);
      if (currentIndex > 0) {
        return this.bookingSteps[currentIndex - 1];
      }
      return null;
    },
    nextBookingStep() {
      const currentIndex = this.bookingSteps.findIndex(step => step.name === this.$route.name);
      if (currentIndex < this.bookingSteps.length - 1) {
        return this.bookingSteps[currentIndex + 1];
      }
      return null;
    },
    isBackArrow(){
         return this.$route.meta.navbar == 'Booking' && this.$route.name === 'signature' || this.$route.name === 'payment'
    },
    reservation() {
      return this.reservations.find(reservation => reservation.id === this.$route.params.platform_id);
    },
    rentalDays() {
      const pickup = new Date(this.reservation.pickup_at);
      const returnD = new Date(this.reservation.return_at);
      const diffTime = Math.abs(returnD - pickup);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    rentalPrice() {
      const rentalTransactions = this.reservation.transactions.filter(transaction => transaction.category === 'Rack');
      if (rentalTransactions.length === 0) return 0;
      return rentalTransactions.reduce((acc, transaction) => acc + transaction.captured_amount, 0);
    },
    taxPrice() {
      const taxTransactions = this.reservation.transactions.filter(transaction => transaction.category === 'Tax');
      if (taxTransactions.length === 0) return 0;
      return taxTransactions.reduce((acc, transaction) => acc + transaction.captured_amount, 0);
    },
    collisionProtectionPrice() {
      const damageWaiverTransactions = this.reservation.transactions.filter(transaction => transaction.category === 'Damage Waiver');
      if (damageWaiverTransactions.length === 0) return 0;
      return damageWaiverTransactions.reduce((acc, transaction) => acc + transaction.captured_amount, 0);
    },
    totalPrice() {
      return this.rentalPrice + this.collisionProtectionPrice + this.taxPrice;
    },
  },
  methods: {
    ...mapActions('search', [
      'setDefaultCityFilter',
      'setDefaultDateFilters',
      'setCity',
      'setPickupDate',
      'setReturnDate',
      'searchVehicles',
      'setPickupTime',
      'setReturnTime',
    ]),
    ...mapActions('reservations', ['getReservations']),

    goBack() {
      // Navigate back
      this.$router.go(-1);
    },

    handleSearch() {
      const { city, pickupDate, returnDate } = this.filters;




      // turn date into 2024-08-30 format
      const pickupDateString = pickupDate.toISOString().split('T')[0];
      const returnDateString = returnDate.toISOString().split('T')[0];




      if (city && pickupDate && returnDate) {
        if (this.$route.path === '/search') {
          this.searchVehicles();
        } else {
          this.$router.push({
            path: '/search',
            query: {
              city: `${city.name}, ${city.state}`,
              pickupDate: pickupDateString,
              returnDate: returnDateString,
            }
          });
        }
      } else {
        console.error('Missing required filters for search.');
      }
    },
    handleCityChange(city) {
      this.setCity(city);
    },
    handlePickupDateChange(date) {
      this.setPickupDate(date);
    },
    handleReturnDateChange(date) {
      this.setReturnDate(date);
    },
    handlePickupTimeChange(time) {
      this.setPickupTime(time);
    },
    handleReturnTimeChange(time) {
      this.setReturnTime(time);
    },
    handleRouteNavigation(name){
      if(name === 'payment'){
         EventBus.$emit('checkSignature');
         return
      }
      this.$router.push({
        name: name, params: {
          platform_id: this.$route.params.platform_id
        }
      });
    },
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')?.image_url;
    }
  },
};
</script>