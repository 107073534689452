<template>
  <div>
    <div>
      <div
        v-for="reservation in limitedReservations"
        :key="reservation.id"
        class="bg-white overflow-hidden flex flex-col md:flex-row items-center p-4"
      >
        <img
          :src="getMoneyshotImage(reservation.vehicle.images)"
          alt="Car Image"
          class="w-24 h-auto rounded-md mr-4"
        />
        <div class="flex-1">
          <h3 class="text-lg font-semibold">{{ reservation.vehicle_model }}</h3>
          <p class="text-gray-600">
            {{ formatDate(reservation.pickup_at) }} - {{ formatDate(reservation.return_at) }}
          </p>
        </div>
        <p class="text-gray-600 flex items-center">
          <MapPinIcon class="w-4 h-4 mr-1" />
          Near {{ reservation.pickup_location.city }}
        </p>
        <div class="w-px h-8 bg-primary-800 mx-4 md:block hidden"></div>
        <button
          class="text-grey-500 text-sm flex items-center"
          @click="viewTripDetails(reservation)"
        >
          Trip Details
          <RightArrowIcon class="w-4 h-4 ml-1" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RightArrowIcon from '@/components/core/icons/RightArrowIcon.vue';
import MapPinIcon from '../core/icons/MapPinIcon.vue';

export default {
  name: 'PastReservationsList',
  components: {
    RightArrowIcon,
    MapPinIcon,
  },
  props: {
    pastReservations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    limitedReservations() {
      return this.pastReservations.slice(0, 3);
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    viewTripDetails(reservation) {
      // Implement the logic to view trip details
      const route = `/trip/${reservation.id}`;
      this.$router.push(route)
      this.$emit('view-details', reservation);
    },
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')?.image_url;
    }
  },
};
</script>

<style scoped>
</style>
