<template>
  <div class="pb-20">
    <!-- Success Message -->
    <div class="max-w-3xl mx-auto px-6 pt-20 text-center">
      <div class="flex justify-center mb-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-16 w-16 text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <h2 class="text-3xl font-semibold mb-4">Your rental is booked!</h2>
      <p class="text-gray-600 mb-8">
        You'll receive a text confirmation shortly for your booking
        {{ $route.params.platform_id }}. View your trip for details on pickup
        and drop-off.
      </p>
      <router-link
        :to="'/trip/' + $route.params.platform_id"
        class="bg-black text-white py-2 px-4 rounded-lg"
        >View My Trip</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentSuccess",
};
</script>

<style scoped>
/* Add your styles here */
</style>
