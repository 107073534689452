import api from '@/api';
import { convertToTimezone } from '@/utils';

const state = {
  reservations: [],
  currentCheckoutStripeClientSecret:
    sessionStorage.getItem('checkoutStripeClientSecret') || null
};

const mutations = {
  setReservations(state, reservations) {
    state.reservations = reservations;
  },
  setCurrentCheckoutStripeClientSecret(state, secret) {
    state.currentCheckoutStripeClientSecret = secret;
    sessionStorage.setItem('checkoutStripeClientSecret', secret);
  },
  clearCurrentCheckoutStripeClientSecret(state) {
    state.currentCheckoutStripeClientSecret = null;
    sessionStorage.removeItem('checkoutStripeClientSecret');
  }
};

const actions = {
  async getReservations({ commit }) {
    await api.reservation.getReservations().then((response) => {
      commit('setReservations', response);
    });
  },
  async createPayment({ dispatch, commit }, { platformId }) {
    try {
      const response = await api.payment.createPayment({
        platform_id: platformId
      });
  
      commit('clearCurrentCheckoutStripeClientSecret');
      commit('setCurrentCheckoutStripeClientSecret', response.stripe);
  
      dispatch('getReservations');
      return response;
    } catch (error) {
      console.error('Failed to fetch payment data:', error);
    }
  },
  async createReservation({ dispatch }, {
    vehicleVin,
    pickupAt,
    returnAt,
    addonIds,
    vehicleTimezone
  }) {
    const pickupAtInVehicleTimezone = convertToTimezone(pickupAt, vehicleTimezone);
    const returnAtInVehicleTimezone = convertToTimezone(returnAt, vehicleTimezone);
    const response = await api.reservation.createReservation({
      vehicle_vin: vehicleVin,
      pickup_at: pickupAtInVehicleTimezone,
      return_at: returnAtInVehicleTimezone,
      addon_ids: addonIds,
    });

    dispatch('getReservations');
    return response;
  },
  async applyDiscount({ commit }, { code, platformId }) {
    const response = await api.discounts.applyDiscount({
      code,
      platformId
    });

    commit('clearCurrentCheckoutStripeClientSecret');
    commit('setCurrentCheckoutStripeClientSecret', response.stripe);
  },
  async addAddon({ commit }, { platformId, addonIds }) {
    const response = await api.reservation.applyAddon(platformId, addonIds);

    commit('clearCurrentCheckoutStripeClientSecret');
    commit('setCurrentCheckoutStripeClientSecret', response.stripe);
  },
  async confirmReservation({ commit }) {
    commit('clearCurrentCheckoutStripeClientSecret');
  },
  async createRentalAgreement({ dispatch }, agreement) {
    await api.agreements.createAgreement({
      type: 'RENTAL_AGREEMENT',
      platform_id: agreement.platform_id,
      signature: agreement.signature,
      date: agreement.date
    });
    dispatch('getReservations');
  },
  async createDamageWaiverAgreement({ dispatch }, agreement) {
    console.log(agreement);
    await api.agreements.createAgreement({
      type: 'DAMAGE_WAIVER',
      platform_id: agreement.platform_id,
      signature: agreement.signature,
      date: agreement.date
    });
    dispatch('getReservations');
  }
};

const getters = {
  upcomingReservations: (state) => {
    return state.reservations.filter((reservation) => {
      return new Date(reservation.pickup_at) >= new Date();
    });
  },
  pastReservations: (state) => {
    return state.reservations.filter((reservation) => {
      return new Date(reservation.return_at) < new Date();
    });
  },
  reservations: (state) => state.reservations,
  currentReservation: (state) => {
    return state.reservations.find((reservation) => {
      return (
        new Date(reservation.return_at) > new Date() &&
        new Date(reservation.pickup_at) < new Date()
      );
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
