<template>
  <div class="w-full">
    <div class="map-container" ref="mapContainer"></div>
    <!--visually hidden -->
    <div v-for="(marker, index) in markers" :key="index" :ref="'marker-' + index" class="marker hidden"></div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
  name: "VehicleMap",
  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
    markers: {
      type: Array,
      default: () => [],
    },
    showPopup: {
      type: Boolean,
      default: true
    },
    mapIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    geojson() {
      return {
        type: "FeatureCollection",
        features: this.markers.map((marker) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [marker.longitude, marker.latitude],
          },
          properties: {
            title: marker.title,
            description: marker.description || '',
          },
        })),
      };
    },
  },
  mounted() {
    this.initializeMap();
  },
  watch: {
    latitude() {
      this.reinitializeMap();
    },
    longitude() {
      this.reinitializeMap();
    },
    markers() {
      this.reinitializeMap();
    },
  },
  methods: {
    initializeMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOXGL_ACCESS_TOKEN;

      this.map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: "mapbox://styles/tyclarke/cm0z8o83q00dw01nq0kwxbqqx",
        center: [this.longitude, this.latitude],
        zoom: 9, // Default zoom
      });

      this.geojson.features.forEach((feature, index) => {
        const markerRef = this.$refs[`marker-${index}`][0];
        if (this.mapIcon) {
          markerRef.innerHTML = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6668 8.45684C16.6668 -0.596663 3.3335 -0.59673 3.3335 8.45684C3.3335 11.4316 5.11085 13.9675 6.83016 15.7246C8.00891 16.9294 8.59829 17.5317 10.0004 17.5317C11.4024 17.5317 11.9918 16.9293 13.1705 15.7245C14.8896 13.9674 16.6668 11.4315 16.6668 8.45684ZM10 10.8334C11.3807 10.8334 12.5 9.7141 12.5 8.33339C12.5 6.95268 11.3807 5.83339 10 5.83339C8.61929 5.83339 7.5 6.95268 7.5 8.33339C7.5 9.7141 8.61929 10.8334 10 10.8334Z" fill="#E5E3E0"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9585 8.45678C3.9585 6.35902 4.72504 4.83356 5.8363 3.82748C6.9582 2.81176 8.4698 2.29162 10.0002 2.29163C11.5305 2.29163 13.0421 2.81178 14.164 3.8275C15.2753 4.83358 16.0418 6.35904 16.0418 8.45678C16.0418 11.185 14.4041 13.5699 12.7237 15.2874C12.1175 15.907 11.7291 16.2991 11.3319 16.5536C10.9725 16.784 10.5941 16.9066 10.0003 16.9066C9.40658 16.9066 9.02818 16.784 8.66875 16.5537C8.27158 16.2991 7.88313 15.9071 7.27688 15.2875C5.59636 13.5699 3.9585 11.1851 3.9585 8.45678ZM17.2918 8.45678C17.2918 6.02777 16.3917 4.15815 15.003 2.90085C13.6249 1.65318 11.8031 1.04163 10.0002 1.04163C8.19719 1.04162 6.37546 1.65316 4.99736 2.90083C3.60862 4.15813 2.7085 6.02775 2.7085 8.45678C2.7085 11.6779 4.62533 14.3649 6.38343 16.1617L6.42259 16.2017C6.97864 16.7701 7.46397 17.2662 7.99428 17.6061C8.57474 17.9781 9.19206 18.1566 10.0004 18.1566C10.8087 18.1566 11.426 17.978 12.0064 17.606C12.5367 17.2661 13.022 16.77 13.5781 16.2016L13.6172 16.1616C15.3752 14.3647 17.2918 11.6779 17.2918 8.45678ZM8.12516 8.33332C8.12516 7.29778 8.96463 6.45832 10.0002 6.45832C11.0357 6.45832 11.8752 7.29778 11.8752 8.33332C11.8752 9.36885 11.0357 10.2083 10.0002 10.2083C8.96463 10.2083 8.12516 9.36885 8.12516 8.33332ZM10.0002 5.20832C8.27427 5.20832 6.87516 6.60743 6.87516 8.33332C6.87516 10.0592 8.27427 11.4583 10.0002 11.4583C11.7261 11.4583 13.1252 10.0592 13.1252 8.33332C13.1252 6.60743 11.7261 5.20832 10.0002 5.20832Z" fill="#54504C"/>
              </svg>`
        }
        else
          markerRef.textContent = feature.properties.title;
        markerRef.className = this.mapIcon ? 'size-10 p-2.5 bg-white rounded-full' : "bg-white rounded-full px-2 py-1 text-sm text-black shadow-sm active:bg-primary-900 hover:bg-primary-900";

        const marker = new mapboxgl.Marker(markerRef).setLngLat(feature.geometry.coordinates).addTo(this.map);

        const popup = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: true,
          offset: 10,

        }).setHTML(feature.properties.description)
        if (this.showPopup) {
          marker.setPopup(popup)
        }
        // popup.setLngLat(feature.geometry.coordinates).addTo(marker);
        // markerRef?.addEventListener('click', () => {
        //   // marker.setPopup to add to marker
        //   // popup.setLngLat(feature.geometry.coordinates).addTo(this.map);
        // });

        // // Hide the popup on mouseleave
        // markerRef?.addEventListener('mouseleave', () => {
        //   // popup.remove();
        // });

      });

      this.map.on('moveend', this.handleMapMovement);
    },
    handleMapMovement() {
      const bounds = this.map.getBounds();
      this.$emit('map-moved', bounds);
    },
    reinitializeMap() {
      if (this.map) this.map.remove(); // Clean up the existing map instance
      this.initializeMap(); // Reinitialize the map with new props
    }
  },
  beforeUnmount() {
    if (this.map) this.map.remove();
  },
};
</script>

<style>
.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

}

.mapboxgl-popup-content {
  border-radius: 12px !important;
}

.mapboxgl-popup-close-button {
  padding: 4px;
  right: 5px;
  color: black;
  font-size: 16px;
}
</style>
