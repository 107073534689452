<template>
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.69987 4.69987H4.70654M7.00785 2H5.2C4.07989 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.07989 2 5.2V7.00785C2 7.49703 2 7.74162 2.05526 7.9718C2.10425 8.17587 2.18506 8.37096 2.29472 8.5499C2.4184 8.75174 2.59135 8.92469 2.93726 9.27059L6.07059 12.4039C6.86262 13.196 7.25864 13.592 7.7153 13.7404C8.11699 13.8709 8.54968 13.8709 8.95137 13.7404C9.40802 13.592 9.80405 13.196 10.5961 12.4039L12.4039 10.5961C13.196 9.80405 13.592 9.40802 13.7404 8.95137C13.8709 8.54968 13.8709 8.11699 13.7404 7.7153C13.592 7.25864 13.196 6.86262 12.4039 6.07059L9.27059 2.93726C8.92469 2.59135 8.75174 2.4184 8.5499 2.29472C8.37096 2.18506 8.17587 2.10425 7.9718 2.05526C7.74162 2 7.49703 2 7.00785 2ZM5.0332 4.69987C5.0332 4.88396 4.88396 5.0332 4.69987 5.0332C4.51577 5.0332 4.36654 4.88396 4.36654 4.69987C4.36654 4.51577 4.51577 4.36654 4.69987 4.36654C4.88396 4.36654 5.0332 4.51577 5.0332 4.69987Z"
            stroke="#54504C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
<script>
export default {
    name: "TagIcon",
};
</script>