<template>
  <div :class="containerClass">
    <label v-if="label" :for="id" class="absolute transition-all duration-200" :class="labelClass">{{ label }}</label>
    <input :id="id" :class="mergedInputClass" :type="type" :placeholder="!label ? placeholder : ''"
      :disabled="isDisabled" :autocomplete="autocomplete ? 'on' : 'off'" :required="required" :readonly="readonly"
      v-model="formattedValue" @focus="handleFocus" @blur="handleBlur" @input="handleInput"
      @keydown.enter.prevent="handleKeydown" @click="$emit('click')"/>
    <!-- Render trailing icon if icon prop is passed default - Calendar Icon-->
    <div v-if="icon" class="block size-5">
      <slot name="icon">
        <CalendarIcon></CalendarIcon>
      </slot>
    </div>
  </div>
</template>

<script>
import { twMerge } from "tailwind-merge";
import CalendarIcon from "./icons/CalendarIcon.vue";

export default {
  name: "TextInput",
  components: {
    CalendarIcon
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "large", // large, small
      validator(value) {
        return ["large", "small"].includes(value);
      },
    },
    isRounded: {
      type: Boolean,
      default: false,
    },
    isBorder: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false, // Controls whether to show a back icon
    },
    inputClass: {
      type: String
    }
  },
  data() {
    return {
      isFocused: false,
      formattedValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.formattedValue = newValue;
    }
  },
  computed: {
    containerClass() {
      const baseClasses =
        "relative font-normal font-urbanist text-base leading-[25.6px] flex items-center gap-1 border-primary-700 bg-primary-900 hover:bg-primary-800 focus-within:bg-primary-800 text-primary-500 hover:text-primary-300 focus-within:text-primary-100";

      const sizeClasses = {
        large: "py-3 px-4 min-h-16",
        small: "py-2 px-4 min-h-[42px]",
      };

      const disabledClasses =
        "!bg-primary-900 cursor-not-allowed hover:!bg-primary-900 !text-primary-600";


      return twMerge(
        baseClasses,
        this.isDisabled ? disabledClasses : "",
        this.isRounded ? "rounded-lg" : "",
        this.isBorder ? "border" : "",
        sizeClasses[this.size],
      );
    },
    mergedInputClass() {
      const baseClasses =
        "border-0 focus:ring-0 p-0 m-0 w-full bg-inherit text-inherit";

      return twMerge(baseClasses,this.inputClass, this.isDisabled ? "cursor-not-allowed" : "",this.size === 'large' ? 'mt-2.5' : '');
    },
    labelClass() {
      const baseClasses =
        "transform pointer-events-none";
      const floatingClasses =
        "text-xs leading-[10.2px] -translate-y-4 text-inherit";

      return twMerge(
        baseClasses,
        this.isFocused || this.formattedValue
          ? this.size === "small"
            ? "hidden"
            : floatingClasses
          : ""
      );
    },
  },
  methods: {
    handleFocus(event) {
      this.isFocused = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.isFocused = false;
      this.$emit('blur', event);
    },
    handleInput(event) {
      const value = event.target.value;
      this.$emit('input', value);
      this.formattedValue = value;
    },
    handleKeydown(event) {
      this.$emit('keydown', event);
    },
  },
};
</script>
