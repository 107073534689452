<template>
  <div class="flex items-center gap-2.5">
    <TextInput
      id="couponCode"
      class="!bg-transparent w-full focus-within:border-blue-500"
      is-border
      is-rounded
      label="Coupon Code"
      v-model="couponCode"
    />
    <CtaButton @click="handleApplyDiscount" :is-loading="isLoading" :is-disabled="isDisabled" variant="secondary">Apply</CtaButton>
  </div>
</template>

<script>
import CtaButton from '../core/CtaButton.vue';
import TextInput from '../core/TextInput.vue';
import { mapActions } from 'vuex';


export default {
  name: "CouponField",
  components: {
    TextInput,
    CtaButton,
  },
  props: {
    platformId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      couponCode: '',
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading || !this.couponCode
    },
  },
  methods: {
    ...mapActions('reservations', ['applyDiscount']),
    async handleApplyDiscount(code) {
      this.isLoading = true;
      try {
        await this.applyDiscount({
          code: this.couponCode,
          platformId: this.platformId,
        });
        this.$emit('applied-discount', code);
      } finally {
        this.isLoading = false;
      }
    },

  },

};
</script>

<style scoped>
/* Add your styles here */
</style>
