<template>
  <div class="flex items-center align-middle bg-resla-ebony-90 rounded-lg border border-resla-ebony-70 relative">
    <!-- Location Input -->
    <div class="flex-1 border-r border-resla-ebony-70 h-full flex items-center">
      <Autocomplete class="w-full !static" rounded id="location" :is-nav="true"
        :picker-class="'rounded-lg overflow-hidden !top-[53px]'" :options="cityOptions" :value="filters.city"
        @input="setCity" value-key="name">
        <MapPinIcon slot="icon" />
      </Autocomplete>
    </div>
    <div class="flex-1 border-r border-resla-ebony-70 h-full flex items-center">
      <DatepickerModal
        id="nav-search-rangepicker"
        compact :date-value="{
          startDate: filters.pickupDate,
          endDate: filters.returnDate,
        }"
        :time-value="{
          startTime: filters.pickupTime,
          endTime: filters.returnTime,
        }"
        :max-date="maxPickupDate"
        @apply="setDateTime"
      />
    </div>
    <!-- Search Button -->
    <CtaButton variant="tertiary" trailing-icon class="p-2"
      :is-disabled="!filters.city || !filters.pickupDate || !filters.returnDate" @click="searchVehicles"></CtaButton>
  </div>
</template>

<script>
import MapPinIcon from "../core/icons/MapPinIcon.vue";
import { mapState, mapActions } from "vuex";
import Autocomplete from "../core/Autocomplete.vue";
import CtaButton from "../core/CtaButton.vue";
import DatepickerModal from "../core/DatepickerModal.vue";
export default {
  name: "NavSearch",
  components: {
    Autocomplete,
    MapPinIcon,
    DatepickerModal,
    CtaButton,
  },
  computed: {
    ...mapState("search", ["filters", "cities"]),
    ...mapState("app", ["userLocation"]),
    cityOptions() {
      return this.cities.map((city, index) => ({
        id: index,
        label: `${city.name}, ${city.state}`,
        value: city,
      }));
    },
    maxPickupDate() {
      // 90 days from now
      return new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
    },
  },
  methods: {
    ...mapActions("search", [
      "setDefaultCityFilter",
      "setDefaultDateFilters",
      "setCity",
      "setPickupDate",
      "setReturnDate",
    ]),
    searchVehicles() {
      this.$emit("search", this.search);
    },
    setCity(city) {
      this.$emit("update:city", city);
    },
    setDates({ startDate, endDate }) {
      this.$emit("update:pickupDate", startDate);
      this.$emit("update:returnDate", endDate);
    },
    setTimes({ startTime, endTime }) {
      this.$emit("update:pickupTime", startTime);
      this.$emit("update:returnTime", endTime);
    },
    setDateTime({ dateValue, timeValue }) {
      this.setDates(dateValue)
      this.setTimes(timeValue)
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
