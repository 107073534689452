<template>
  <div class="flex items-center md:justify-between py-3 px-6 gap-x-5 border-b border-primary-700  overflow-x-auto md:overflow-x-hidden custom-scroll">
    <div class="flex gap-5 items-center">
      <!-- Model Filters -->
      <div class="flex space-x-2">
        <Chip
          v-for="model in filterOptions.models"
          :key="model"
          :selected="isModelSelected(model)"
          @onSelect="selectFilter('model', model)"
          @onDeselect="selectFilter('model', model)"
          class="w-max"
        >
          {{ model }}
        </Chip>
      </div>
      <!-- Divider -->
      <div class="w-px h-8 bg-primary-800"></div>
      <!-- Variant Filters -->
      <Chip
        :selected="isLongRange()"
        :is-disabled="!isLongRangeEnabled"
        @onSelect="selectFilter('model', `${selectedFilters.model} Long Range`)"
        @onDeselect="clearLongRange()"
        class="w-max"
      >
        Long Range
      </Chip>
      <!-- Divider -->
      <div class="w-px h-8 bg-primary-800"></div>
      <!-- Color Filters -->
      <div class="flex space-x-2 items-center">
        <ColorChip
          v-for="colorName in filterOptions.colors"
          :key="colorName"
          :color="colorName.toLowerCase()"
          :selected="isColorSelected(colorName)"
          @onSelect="selectFilter('color', colorName)"
          @onDeselect="selectFilter('color', colorName)"
        />
      </div>
    </div>
    <div v-if="!searchResultsIsLoading" class="hidden md:flex text-sm text-primary-300 leading-5 font-medium">
      {{ numSearchResults }} Results
    </div>
  </div>
</template>

<script>
import Chip from '@/components/core/Chip.vue'
import ColorChip from '@/components/core/ColorChip.vue'

export default {
  name: 'FilterBar',
  components: {
    Chip,
    ColorChip
  },
  props: {
    selectedFilters: {
      type: Object,
      required: true
    },
    numSearchResults: {
      type: Number,
      required: true
    },
    searchResultsIsLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      filterOptions: {
        models: ['Model 3', 'Model Y', 'Model X', 'Model S', 'Model S Plaid', 'Cyber Truck'],
        colors: ['Red','Blue','White','Black','Gray']
      }
    };
  },
  computed: {
    isLongRangeEnabled() {
      return this.selectedFilters.model?.includes('Model Y') || this.selectedFilters.model?.includes('Model 3');
    }
  },
  methods: {
    selectFilter(filter, value) {
      if((value === 'Model 3' || value === 'Model Y') && this.selectedFilters.model?.includes('Long Range')){
        value = `${value} Long Range`
      }
      this.$emit('filter-change', { filter, value });
    },
    clearLongRange(){
      const value = this.selectedFilters.model?.replace(" Long Range", "");
        this.$emit('filter-change', { filter:'model', value });
    },
    isModelSelected(model) {
      if((model === 'Model 3' || model === 'Model Y') && this.selectedFilters.model?.includes('Long Range')){
        model = `${model} Long Range`
      }
      return this.selectedFilters.model === model;
    },
    isLongRange(){
      return this.selectedFilters.model?.includes('Long Range')
    },
    isColorSelected(color) {
      return this.selectedFilters.color === color;
    }
  }
};
</script>

<style scoped>
/* Hide scrollbar in WebKit browsers (Chrome, Safari) */
.custom-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar in Firefox */
.custom-scroll {
  scrollbar-width: none;
}

/* Keep overflow functionality */
.custom-scroll {
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
}
</style>