<template>
    <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M25.6663 14C25.6663 20.4434 20.443 25.6667 13.9997 25.6667C7.55635 25.6667 2.33301 20.4434 2.33301 14C2.33301 7.55672 7.55635 2.33337 13.9997 2.33337C20.443 2.33337 25.6663 7.55672 25.6663 14ZM15.5556 15.1666H12.4444C10.7262 15.1666 9.33333 16.5595 9.33333 18.2777C9.33333 19.1368 10.0298 19.8333 10.8889 19.8333H17.1111C17.9702 19.8333 18.6667 19.1368 18.6667 18.2777C18.6667 16.5595 17.2738 15.1666 15.5556 15.1666ZM13.9998 11.6667C15.2885 11.6667 16.3332 10.622 16.3332 9.33335C16.3332 8.04469 15.2885 7.00002 13.9998 7.00002C12.7112 7.00002 11.6665 8.04469 11.6665 9.33335C11.6665 10.622 12.7112 11.6667 13.9998 11.6667Z"
            fill="#E5E3E0" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.9997 2.95837C7.90153 2.95837 2.95801 7.9019 2.95801 14C2.95801 20.0982 7.90153 25.0417 13.9997 25.0417C20.0978 25.0417 25.0413 20.0982 25.0413 14C25.0413 7.9019 20.0978 2.95837 13.9997 2.95837ZM1.70801 14C1.70801 7.21154 7.21117 1.70837 13.9997 1.70837C20.7882 1.70837 26.2913 7.21154 26.2913 14C26.2913 20.7885 20.7882 26.2917 13.9997 26.2917C7.21117 26.2917 1.70801 20.7885 1.70801 14ZM12.4444 15.7916C11.0714 15.7916 9.95833 16.9047 9.95833 18.2777C9.95833 18.7917 10.375 19.2083 10.8889 19.2083H17.1111C17.625 19.2083 18.0417 18.7917 18.0417 18.2777C18.0417 16.9047 16.9286 15.7916 15.5556 15.7916H12.4444ZM8.70833 18.2777C8.70833 16.2143 10.381 14.5416 12.4444 14.5416H15.5556C17.619 14.5416 19.2917 16.2143 19.2917 18.2777C19.2917 19.482 18.3154 20.4583 17.1111 20.4583H10.8889C9.6846 20.4583 8.70833 19.482 8.70833 18.2777ZM12.2915 9.33335C12.2915 8.38987 13.0564 7.62502 13.9998 7.62502C14.9433 7.62502 15.7082 8.38987 15.7082 9.33335C15.7082 10.2768 14.9433 11.0417 13.9998 11.0417C13.0564 11.0417 12.2915 10.2768 12.2915 9.33335ZM13.9998 6.37502C12.366 6.37502 11.0415 7.69951 11.0415 9.33335C11.0415 10.9672 12.366 12.2917 13.9998 12.2917C15.6337 12.2917 16.9582 10.9672 16.9582 9.33335C16.9582 7.69951 15.6337 6.37502 13.9998 6.37502Z"
            fill="#54504C" />
    </svg>
</template>

<script>
export default {
    name: "UserCircleIcon",
};
</script>