<template>
  <div>
    <p class="text-2xl leading-9 tracking-negative-2 text-primary-100 font-medium">{{ title }}</p>
    <div class="mt-5 grid grid-cols-2 gap-x-6 gap-y-3">
      <div class="flex items-center align-middle space-x-2" v-for="feature in features" :key="feature">
        <GreenTickCircle class="size-5"></GreenTickCircle>
        <span class="font-urbanist font-normal text-base leading-6.5 text-primary-100">{{ feature }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import GreenTickCircle from '../core/icons/GreenTickCircle.vue';


export default {
  name: 'VehicleFeatures',
  components: {
    GreenTickCircle
  },
  props: {
    title: {
      type: String,
      default: 'Features'
    },
    vehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    seating_capacity() {
      return this.vehicle.model.seating_capacity + ' person capacity';
    },
    vehicle_age() {
      const age = new Date().getFullYear() - this.vehicle.year;
      return age + ' year' + (age > 1 ? 's' : '') + ' old';
    },
    rounded_odometer() {
      // round to nearest 1000
      if (this.vehicle.odometer < 1000 || !this.vehicle.odometer) {
        return 'Less than 1000 miles';
      }
      const odometer_miles = Math.round(this.vehicle.odometer / 1000) * 1000;
      return odometer_miles + ' miles';
    },
    features() {
      return [
        this.vehicle.model.drivetrain,
        this.seating_capacity,
        this.vehicle_age,
        this.vehicle.color,
        this.rounded_odometer,
        'Contactless Pickup',
      ]
    },
  },
};
</script>
