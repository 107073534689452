<template>
  <div class="flex items-center bg-primary-900 border border-primary-700 rounded-lg p-4 cursor-pointer h-24">
    <img :src="getMoneyshotImage(reservation.vehicle.images)" alt="Car Model" class="w-auto h-full rounded-md" />
      <div class="ml-4 flex-1">
        <h3 class="text-lg font-semibold">{{ reservation.vehicle.model.name}}</h3>
        <p>Total: ${{ reservation.payment_balance / 100 }}</p>
      </div>
      <div class="flex items-center mt-2">
        <div class="items-end">
          <span class="text-2xl">
            ${{ reservation.vehicle.rack_rate / 100 }}
          </span>
          <span class="text-gray-600">/day</span>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'VehiclePaymentCard',
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  methods:{
    getMoneyshotImage(vehicleImages = []) {
      if (vehicleImages.length > 0)
        return vehicleImages.find((image) => image.view === 'MONEYSHOT')?.image_url;
    }
  }
};
</script>
