<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8828 1.85508C18.1105 1.9902 18.25 2.2353 18.25 2.5V14.7727C18.25 15.0472 18.1001 15.2997 17.8591 15.4311L12.8591 18.1584C12.6353 18.2805 12.3647 18.2805 12.1409 18.1584L7.5 15.627L2.85914 18.1584C2.62676 18.2852 2.34477 18.28 2.11715 18.1449C1.88953 18.0098 1.75 17.7647 1.75 17.5V5.22727C1.75 4.95281 1.89992 4.70028 2.14086 4.56885L7.14086 1.84158C7.36472 1.71947 7.63528 1.71947 7.85914 1.84158L12.5 4.37296L17.1409 1.84158C17.3732 1.71483 17.6552 1.71995 17.8828 1.85508ZM11.75 5.6725L8.25 3.76341V14.3275L11.75 16.2366V5.6725ZM13.25 16.2366L16.75 14.3275V3.76341L13.25 5.6725V16.2366ZM6.75 14.3275V3.76341L3.25 5.6725V16.2366L6.75 14.3275Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: "MapViewIcon",
};
</script>